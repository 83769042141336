import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, Typography, useTheme } from '@mui/material'

import { RootState } from '@/state'
import { IconComponent, ProgressBarComponent } from '@/components/common'
import { useBreakpoints } from '@/hooks'

import { getFormatNumber } from '@/utils'
import { SidebarColors } from '@/constants'
import { IconTypeEnum } from '@/enums'

import { useProgressBarStyles } from './progress-bar.style'

type Props = {
  Div: HTMLElement
  sidebarClient: string
  openSidebar: boolean
}

export function SidebarProgressBarComponent({
  Div,
  sidebarClient,
  openSidebar,
}: Props) {
  const { t } = useTranslation()

  const theme = useTheme()

  const progressColor =
    Div.getAttribute('data-progress-color') ||
    SidebarColors(theme).progressColor

  const { gamificationPlayer, gamificationOpt, player } = useSelector(
    ({ player }: RootState) => player,
  )

  const { playerLocale } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const { isMobile } = useBreakpoints()

  const { classes } = useProgressBarStyles({
    openSidebar,
    sidebarClient,
    isMobile,
  })

  return (
    <Box className={classes.progressBarContent}>
      <Typography variant="body2">{player?.name || '_'}</Typography>
      <ProgressBarComponent
        progressValue={
          gamificationOpt?.enable ? gamificationPlayer.level.progress : 0
        }
        icon={<IconComponent type={IconTypeEnum.LEVEL} size={33} />}
        progressColor={progressColor}
      />
      {gamificationOpt?.enable &&
        !!gamificationPlayer?.pointsUntilNextLevel && (
          <Box px={1} mt={0.5} display="flex" justifyContent="space-between">
            <Typography variant="caption">
              <strong>
                {getFormatNumber(
                  gamificationPlayer?.pointsUntilNextLevel,
                  playerLocale,
                )}
              </strong>
              {` ${t('forNextLevel')}`}
            </Typography>
            <Typography variant="caption" fontWeight={700}>
              {gamificationPlayer?.nextLevel.levelNumber}
            </Typography>
          </Box>
        )}
    </Box>
  )
}
