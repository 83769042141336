import { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { RewardShopTypeEnum } from '@gamanza-engage/mf-enums'
import {
  Button,
  Box,
  Typography,
  IconButton,
  useTheme,
  darken,
} from '@mui/material'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'

import { UIDialogsEnum } from '@/enums'
import { useGlobalUi } from '@/hooks'
import { RootState } from '@/state'

import {
  DialogColumnsComponent,
  OrderResponseComponent,
  ProductDetailsComponent,
  ProductSummaryComponent,
  useProductForm,
  useRewardShopActions,
  useShopLayout,
} from '@/components'
import { useStyles } from '@/components/reward-shop/components/product/style'
import { useRewardShopStyles } from './style'

import { SidebarRewardShopCategoryComponent } from './reward-shop-category.component'

type Props = {
  handleChangeMenuItem?: (sidebarMenuItem: string) => void
}

export const SidebarRewardShopComponent = ({ handleChangeMenuItem }: Props) => {
  const { t } = useTranslation()

  const { sidebarProducts } = useSelector(
    ({ rewardShop }: RootState) => rewardShop,
  )

  const { dialog } = useGlobalUi()

  const theme = useTheme()

  const { classes } = useRewardShopStyles({})

  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const { classes: classesForm } = useStyles({ client })

  const menuItems = [
    { id: 'all', label: t('all') },
    {
      id: RewardShopTypeEnum.EXTERNAL,
      label: t('rewardCategoryexternal_product'),
    },
    { id: RewardShopTypeEnum.BOOSTER, label: t('rewardCategorybooster') },
    { id: RewardShopTypeEnum.BONUS, label: t('rewardCategorybonus') },
    { id: RewardShopTypeEnum.XP, label: t('rewardCategoryxp') },
  ]

  const { orderProduct } = useShopLayout()

  const product = dialog?.object

  const {
    productForm,
    editLocation,
    handleOnChange,
    handleOnChangeMultiple,
    handleEditLocation,
    getDisableMessage,
    getActionButtons,
    resetProductForm,
    getDefaultActionProps,
  } = useProductForm({ product })

  const { resetOrderProduct } = useRewardShopActions()

  const [selectedMenu, setSelectedMenu] = useState<RewardShopTypeEnum | 'all'>(
    'all',
  )

  const [formStep, setFormStep] = useState(1)

  const itemsPerSlide = 3

  const menuRefs = useRef<(HTMLButtonElement | null)[]>([])
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (selectedMenu && containerRef.current) {
      const selectedIndex = menuItems.findIndex(
        (item) => item.id === selectedMenu,
      )
      const selectedItem = menuRefs.current[selectedIndex]
      if (selectedItem) {
        const container = containerRef.current
        const itemLeft = selectedItem.offsetLeft
        const itemWidth = selectedItem.offsetWidth
        const containerWidth = container.offsetWidth
        const scrollLeft = itemLeft - (containerWidth - itemWidth) / 2
        container.scrollTo({ left: scrollLeft, behavior: 'smooth' })
      }
    }
  }, [selectedMenu]) // eslint-disable-line

  const actionProps = getDefaultActionProps(formStep, setFormStep)

  const actionButtons = getActionButtons({ ...actionProps, formStep })

  const handlePrevSlide = () => {
    const prevItemIndex =
      menuItems.findIndex((item) => item.id === selectedMenu) - 1
    if (prevItemIndex >= 0) {
      setSelectedMenu(menuItems[prevItemIndex].id as RewardShopTypeEnum)
    }
  }

  const handleNextSlide = () => {
    const nextItemIndex =
      menuItems.findIndex((item) => item.id === selectedMenu) + 1
    if (nextItemIndex < menuItems.length) {
      setSelectedMenu(menuItems[nextItemIndex].id as RewardShopTypeEnum)
    }
  }

  const getBGColor = (selectedMenu: boolean) => {
    if (selectedMenu) {
      return theme.palette.primary.main
    }

    return theme.palette.mode === 'dark'
      ? theme.palette.background.default
      : darken(theme.palette.background.paper, 0.05)
  }

  const renderButtons = () => {
    return menuItems.map((item, index) => (
      <Box key={item.id} mr={index !== menuItems.length - 1 ? 0.5 : 0}>
        <Button
          ref={(el) => (menuRefs.current[index] = el)}
          onClick={() => setSelectedMenu(item.id as RewardShopTypeEnum)}
          className={classes.menuItem}
          style={{
            backgroundColor: getBGColor(selectedMenu === item.id),
            color:
              selectedMenu === item.id
                ? theme.palette.common.white
                : theme.palette.text.primary,
          }}
        >
          {item.label}
        </Button>
      </Box>
    ))
  }

  const getDialogContent = () => {
    if (orderProduct)
      return <OrderResponseComponent handleClose={() => setFormStep(1)} />

    if (formStep === 2 && product?.reward?.type !== RewardShopTypeEnum.EXTERNAL)
      return

    return (
      <form className={classesForm.form}>
        <ProductDetailsComponent
          product={product}
          isProductSidebar
          formStep={formStep}
          productForm={productForm}
          editLocation={editLocation}
          actionButtons={actionButtons}
          handleEditLocation={handleEditLocation}
          handleOnChange={handleOnChange}
          handleOnChangeMultiple={handleOnChangeMultiple}
          getDisableMessage={getDisableMessage}
        />
      </form>
    )
  }

  const getDialogSummary = () => {
    if (orderProduct || formStep === 1) return

    return (
      formStep === 2 && (
        <form className={classesForm.form}>
          <ProductSummaryComponent
            product={dialog.object}
            productForm={productForm}
            actionButtons={actionButtons}
            handleOnChange={handleOnChange}
            getDisableMessage={getDisableMessage}
          />
        </form>
      )
    )
  }

  const getDialogTitle = () => {
    if (orderProduct) return

    return formStep === 1 ? t('productDetails') : t('shippingInformation')
  }

  const getModalResolution = () => {
    return (formStep === 2 &&
      product?.reward?.type !== RewardShopTypeEnum.EXTERNAL) ||
      orderProduct
      ? 500
      : 900
  }

  return (
    <Box height="100%">
      <Box p={2}>
        <Typography fontWeight={700} paragraph>
          {t('shopTitle').toUpperCase()}
        </Typography>
        <Box className={classes.menuContainer}>
          {(sidebarProducts?.length > 0 ||
            (selectedMenu !== 'all' && sidebarProducts?.length === 0)) && (
            <>
              {menuItems.length > itemsPerSlide && (
                <IconButton
                  onClick={handlePrevSlide}
                  disabled={selectedMenu === menuItems[0].id}
                  className={classes.navigationButtonLeft}
                >
                  <ArrowBackIos style={{ fontSize: 'medium' }} />
                </IconButton>
              )}
              <Box className={classes.menuItemsContainer}>
                <Box
                  ref={containerRef}
                  className={classes.menuItemsScrollContainer}
                >
                  {renderButtons()}
                </Box>
              </Box>
              {menuItems.length > itemsPerSlide && (
                <IconButton
                  onClick={handleNextSlide}
                  disabled={selectedMenu === menuItems[menuItems.length - 1].id}
                  className={classes.navigationButtonRight}
                >
                  <ArrowForwardIos style={{ fontSize: 'medium' }} />
                </IconButton>
              )}
            </>
          )}
        </Box>
      </Box>
      <Box className={classes.shopContent}>
        <SidebarRewardShopCategoryComponent
          category={selectedMenu}
          handleChangeMenuItem={handleChangeMenuItem}
        />
      </Box>

      {dialog.open && (
        <DialogColumnsComponent
          dialogId={UIDialogsEnum.SIDEBAR_SHOP_PRODUCT}
          title={getDialogTitle()}
          maxWidth={getModalResolution()}
          content={getDialogContent()}
          summary={getDialogSummary()}
          handleClose={() => {
            resetOrderProduct()
            resetProductForm()
            setFormStep(1)
          }}
        />
      )}
    </Box>
  )
}
