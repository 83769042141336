import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import { AccessTimeOutlined } from '@mui/icons-material'

import { MissionCommonModels } from '@/state'
import { useGlobalUi, useRemainingTime } from '@/hooks'
import { DialogTypeEnum, UIDialogsEnum } from '@/enums'

import { MissionItemDenseComponent } from '@/components/common'
import { useMissionStyles } from '../style'

type Props = {
  bundle: MissionCommonModels.MissionHistoryBundleDetailsType
}

export const ActiveBundleCustomComponent = ({ bundle }: Props) => {
  const { t } = useTranslation()

  const { handleOpenDialog } = useGlobalUi()

  const { classes } = useMissionStyles()

  const { timeRemaining, remaining } = useRemainingTime({
    endDate: bundle.endDate,
  })

  return (
    <>
      {remaining !== 0 && (
        <Box className={classes.bundleContent}>
          <Box className="bundleHeader">
            <Typography>
              {t(`timeframeType.${bundle.settings.timeFrame.timeFrameType}`)}
            </Typography>
            <Box className="remaining">
              <AccessTimeOutlined />
              {timeRemaining}
            </Box>
          </Box>
          <Box className={classes.listMissions}>
            {bundle.missionIds.map((mission) => {
              return (
                <Box
                  key={`mission_${mission._id}`}
                  onClick={() =>
                    handleOpenDialog({
                      id: UIDialogsEnum.MISSION_DETAILS,
                      object: {
                        missionId: mission._id,
                        title: t(
                          `timeframeTypeMissions.${bundle.settings.timeFrame.timeFrameType}`,
                        ),
                        type: DialogTypeEnum.MISSIONS,
                      },
                    })
                  }
                >
                  <MissionItemDenseComponent
                    expanded
                    status={mission.status}
                    endDate={mission.endDate}
                    state={mission.state}
                    timeType={mission.validityTime.timeType}
                  />
                </Box>
              )
            })}
          </Box>
        </Box>
      )}
    </>
  )
}
