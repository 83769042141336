import { useTranslation } from 'react-i18next'
import { DialogComponent } from '@/components/common'
import { MyBoostersDenseComponent } from '@/components/my-boosters'

import { UIDialogsEnum } from '@/enums'

export function DialogListBoostersComponent() {
  const { t } = useTranslation()

  return (
    <DialogComponent
      dialogId={UIDialogsEnum.LIST_MY_BOOSTERS}
      title={t('myBoosters')}
      content={<MyBoostersDenseComponent />}
      headerBorder={false}
      titleSxProps={{ textAlign: 'left' }}
      maxWidth={444}
      overflow="auto"
      disableScrollLock={false}
    />
  )
}
