import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { PlayerModels, PlayerThunks } from '@/state'

export const getRankDetailsExtraReducer = (
  builder: ActionReducerMapBuilder<PlayerModels.PlayerState>,
) => {
  builder.addCase(
    PlayerThunks.getRankDetailsThunk.fulfilled,
    (state, { payload }) => {
      state.gamificationPlayer.category = {
        id: payload?.category?._id,
        name: payload?.category?.name,
      }
      state.gamificationPlayer.level = {
        id: payload?.level?._id || '',
        levelNumber: payload?.level?.levelNumber || 0,
        progress: payload?.levelProgress || 0,
      }
      state.gamificationPlayer.nextLevel = {
        id: payload?.nextLevel?._id || '',
        levelNumber: payload?.nextLevel?.levelNumber || 0,
      }
      state.gamificationPlayer.rank = {
        id: payload?.rank?._id || '',
        name: payload?.rank?.name || '',
        imageUrl: payload?.rank?.imageUrl || '',
        translations: payload?.rank?.translations || [],
        progress: payload?.rankProgress || 0,
      }
      state.gamificationPlayer.nextRank = {
        id: payload?.nextRank?._id || '',
        name: payload?.nextRank?.name || '',
        translations: payload?.nextRank?.translations || [],
      }
      state.gamificationPlayer.pointsUntilNextLevel =
        payload.pointsUntilNextLevel > 0 ? payload.pointsUntilNextLevel : 0
      state.gamificationPlayer.pointsUntilNextRank = payload.pointsUntilNextRank
      state.gamificationPlayer.xpBalance = payload?.xpBalance || 0
      state.gamificationPlayer.previousData = {
        ...state.gamificationPlayer.previousData,
        previousXpBalance: payload?.xpBalance || 0,
        previousRankName: payload?.rank?.name || '',
        previousRankTranslations: payload?.rank?.translations || [],
        previousLevelNumber: payload?.level?.levelNumber || 0,
      }
    },
  )
}
