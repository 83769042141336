import { useSelector } from 'react-redux'
import { Add, Remove } from '@mui/icons-material'
import { Box, IconButton, TextField } from '@mui/material'

import { RootState } from '@/state'
import { useCounterStyles } from './style'

type Props = {
  fieldName: string
  value: number
  disableControls?: boolean
  label?: string
  handleOnChange: (field: string, newValue: number) => void
}

export const CounterInputComponent = ({
  fieldName,
  value,
  disableControls,
  label,
  handleOnChange,
}: Props) => {
  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const { classes } = useCounterStyles({ client })

  const maxValue = 9999

  const handleIncrease = () => {
    if (value < maxValue) {
      handleOnChange(fieldName, value + 1)
    }
  }

  const handleDecrease = () => {
    if (value > 1) {
      handleOnChange(fieldName, value - 1)
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value)

    if (newValue > maxValue) {
      handleOnChange(fieldName, maxValue)
      return
    }

    if (newValue < 1) {
      handleOnChange(fieldName, 1)
      return
    }

    handleOnChange(fieldName, newValue)
  }

  return (
    <Box className={classes.counterWrapper}>
      {!disableControls && (
        <IconButton onClick={handleDecrease} className={classes.iconButton}>
          <Remove color="primary" />
        </IconButton>
      )}
      <TextField
        label={label}
        variant="filled"
        margin="normal"
        type="number"
        value={value}
        onChange={handleInputChange}
        className={classes.input}
        inputProps={{ min: 1, max: maxValue }}
        InputLabelProps={{
          className: classes.inputLabel,
        }}
      />
      {!disableControls && (
        <IconButton onClick={handleIncrease} className={classes.iconButton}>
          <Add color="primary" />
        </IconButton>
      )}
    </Box>
  )
}
