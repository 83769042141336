import Lottie from 'lottie-react'
import { Box, Slide, Typography, useTheme } from '@mui/material'
import { useGlobalUi } from '@/hooks'

import { GeneralModels } from '@/state'
import { useNotificationInitial, useNotifications } from '../../hooks'
import { useStyles } from '../styles'

type Props = {
  initialAnimationData: GeneralModels.LottieAnimationData
}

export const ModalStatusInitialComponent = ({
  initialAnimationData,
}: Props) => {
  const { modal } = useGlobalUi()

  const { isVisible, containerRef } = useNotificationInitial()

  const { NotificationData } = useNotifications()

  const theme = useTheme()

  const { classes } = useStyles({ snackbarType: modal.type })

  return (
    <>
      <Box className={classes.initialContainerTop}>
        <Lottie animationData={initialAnimationData} loop={false} />
      </Box>
      <Box className={classes.initialContainerBottom}>
        <Slide
          direction="up"
          in={isVisible}
          container={containerRef.current}
          timeout={750}
        >
          <Box className={classes.initialBottom}>
            <Typography className={classes.initialCongratulationsTitle}>
              {NotificationData[modal.type].title}
            </Typography>
            <Typography
              sx={{ color: theme.palette.common.white }}
              textAlign="center"
            >
              {NotificationData[modal.type].description}
            </Typography>
          </Box>
        </Slide>
      </Box>
    </>
  )
}
