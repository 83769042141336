import { useSelector } from 'react-redux'
import { RewardStatusEnum } from '@gamanza-engage/mf-enums'
import { useGlobalUi } from '@/hooks'

import { DialogTypeEnum, MissionStatusEnum, UIDialogsEnum } from '@/enums'
import { RootState } from '@/state'
import { DialogComponent } from '@/components'

import {
  DialogMissionObjectivesComponent,
  DialogMissionProgressComponent,
  DialogMissionRewardsComponent,
} from './'

export const DialogMissionComponent = () => {
  const { dialog } = useGlobalUi()

  const {
    missionsActive,
    missionActiveBundles,
    missionsExpired,
    missionExpiredBundles,
  } = useSelector(({ missionHistory }: RootState) => missionHistory)

  const getSelectedMission = () => {
    if (dialog.object?.type === DialogTypeEnum.ACTIVE_MISSIONS) {
      return missionsActive?.find(
        (mission) => mission._id === dialog.object?.missionId,
      )
    }

    if (dialog.object?.type === DialogTypeEnum.MISSIONS) {
      if (dialog.object?.showEndedMissions) {
        return missionExpiredBundles
          ?.find((bundle) =>
            bundle?.missionIds?.find(
              (mission) => mission._id === dialog.object?.missionId,
            ),
          )
          ?.missionIds?.find(
            (mission) => mission._id === dialog.object?.missionId,
          )
      } else {
        return missionActiveBundles
          ?.find((bundle) =>
            bundle?.missionIds?.find(
              (mission) => mission._id === dialog.object?.missionId,
            ),
          )
          ?.missionIds?.find(
            (mission) => mission._id === dialog.object?.missionId,
          )
      }
    }

    if (dialog.object?.type === DialogTypeEnum.MISSION_HISTORY) {
      return missionsExpired?.find(
        (mission) => mission._id === dialog.object?.missionId,
      )
    }
  }

  const selectedMission = getSelectedMission()

  const rewards =
    selectedMission?.rewardCategories?.filter(
      (category) => category.status === RewardStatusEnum.CLAIMED,
    )[0]?.rewards || []

  return (
    <DialogComponent
      dialogId={UIDialogsEnum.MISSION_DETAILS}
      title={
        dialog.object?.title?.toUpperCase() ||
        dialog.object?.name?.toUpperCase()
      }
      titleSxProps={{ textAlign: 'center' }}
      content={
        <>
          <DialogMissionProgressComponent
            state={selectedMission?.state}
            status={selectedMission?.status as MissionStatusEnum}
            endDate={selectedMission?.endDate}
          />

          <DialogMissionRewardsComponent
            rewards={rewards}
            pendingClaim={
              selectedMission?.status === MissionStatusEnum.COMPLETED &&
              selectedMission?.rewardClaimStatus?.status ===
                RewardStatusEnum.UNCLAIMED
            }
            missionId={selectedMission?._id}
            bundleId={selectedMission?.missionBundleId}
          />

          <DialogMissionObjectivesComponent
            objectives={selectedMission?.objectives}
            status={selectedMission?.status as MissionStatusEnum}
          />
        </>
      }
    />
  )
}
