import { useTranslation } from 'react-i18next'
import { Box, CircularProgress, Typography } from '@mui/material'
import { AccessTimeOutlined } from '@mui/icons-material'

import { MissionItem, MissionItemSize } from '@/constants'
import {
  MissionStatusEnum,
  MissionTimeTypeEnum,
  OrientationComponentsEnum,
  VariantsComponentsEnum,
} from '@/enums'
import { MissionCommonModels } from '@/state'

import { useBreakpoints, useRemainingTime } from '@/hooks'

type Props = {
  status: MissionStatusEnum
  baseUrl: string
  endDate?: string
  timeType?: MissionTimeTypeEnum
  state?: MissionCommonModels.MissionProgressType
  expanded?: boolean
  orientation?: OrientationComponentsEnum
  variant?: VariantsComponentsEnum
  showArrow?: boolean
  showExtraIcon?: boolean
}

export const MissionItemInProgressComponent = ({
  endDate,
  baseUrl,
  timeType,
  state,
  expanded = true,
  status,
  variant,
  orientation,
  showArrow = false,
  showExtraIcon = false,
}: Props) => {
  const { t } = useTranslation()

  const { timeRemaining } = useRemainingTime({ endDate })

  const { isMobile } = useBreakpoints()

  const iconSize = () => {
    const variantSize = isMobile ? VariantsComponentsEnum.SMALL : variant
    return orientation === OrientationComponentsEnum.HORIZONTAL
      ? MissionItemSize[VariantsComponentsEnum.SMALL].circularSize
      : MissionItemSize[variantSize].circularSize
  }

  const size = expanded ? iconSize() : 44

  return (
    <>
      <Box className="missionProgress" height={size}>
        <CircularProgress
          variant="determinate"
          value={Number(state.progress)}
          size={size}
          thickness={4}
          className="missionCircularProgress"
        />
        <Box className="icon">{MissionItem[status].icon}</Box>
      </Box>
      <Box className="missionTexts">
        {expanded && (
          <Typography className="missionStatus">
            {t(`${MissionItem[status].label}`)}
          </Typography>
        )}
        {timeType !== MissionTimeTypeEnum.ANY_TIME &&
          status === MissionStatusEnum.ACTIVE && (
            <Typography className="missionTimeRemaining">
              {expanded && <AccessTimeOutlined />}
              {timeRemaining}
            </Typography>
          )}
      </Box>
      {showExtraIcon && status === MissionStatusEnum.ENDED && (
        <Box className="missionExtraIcon">
          <img
            src={`${baseUrl}${MissionItem[status].extraIcon}`}
            alt={`extra icon ${MissionItem[status].label}`}
          />
        </Box>
      )}
      {showArrow && <Box className="missionArrow" />}
    </>
  )
}
