import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, Typography, TypographyProps, useTheme } from '@mui/material'

import { RootState } from '@/state'
import { getFormatNumber } from '@/utils'
import { usePlayerData } from '@/hooks'

import {
  IconComponent,
  PointCardComponent,
  ProgressBarComponent,
} from '@/components/common'
import { IconTypeEnum } from '@/enums'

export function PointsComponent() {
  const { t } = useTranslation()

  const { gamificationPlayer, gamificationOpt } = useSelector(
    ({ player }: RootState) => player,
  )

  const { playerLocale, client } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const theme = useTheme()

  const { playerRankName, playerNextRankName } = usePlayerData()

  const typographyCaptionProps: TypographyProps = {
    variant: 'caption',
    fontWeight: 500,
    fontSize: 14,
    color: theme.palette.text.secondary,
    component: 'p',
  }

  const valueProps: TypographyProps = client === 'JP' && {
    fontWeight: 700,
    fontSize: 14,
    fontStyle: 'italic',
  }

  const level = gamificationPlayer.level.levelNumber ?? '-'

  const xpPointsProgress = (
    <ProgressBarComponent
      progressValue={
        gamificationOpt?.enable ? gamificationPlayer.rank.progress : 0
      }
      icon={<IconComponent type={IconTypeEnum.RANK} size={36} />}
      iconSxProps={{
        right: -15,
      }}
      balanceText={
        gamificationOpt?.enable
          ? getFormatNumber(gamificationPlayer.xpBalance, playerLocale)
          : '--'
      }
    />
  )

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        textAlign="center"
        justifyContent="space-between"
        gap={1}
      >
        <PointCardComponent
          icon={<IconComponent type={IconTypeEnum.RANK} size={36} />}
          name={t('rank')}
          value={playerRankName}
          tooltipText={t('infoRank')}
          valueProps={valueProps}
        />

        <PointCardComponent
          icon={<IconComponent type={IconTypeEnum.LEVEL} size={36} />}
          name={t('level')}
          value={level}
          tooltipText={t('infoLevel')}
          valueProps={valueProps}
        />

        <PointCardComponent
          icon={<IconComponent type={IconTypeEnum.TOKEN} size={36} />}
          name={t('tokens')}
          value={getFormatNumber(gamificationPlayer.tokens, playerLocale)}
          tooltipText={t('infoTokens')}
          valueProps={valueProps}
        />
      </Box>

      <Box
        paddingTop={0.5}
        paddingBottom={1}
        paddingX={2}
        borderRadius={2}
        mt={1}
        textAlign="center"
        bgcolor={theme.palette.background.default}
      >
        <PointCardComponent
          icon={<IconComponent type={IconTypeEnum.XP} size={36} />}
          name={t('xpPoints')}
          progress={xpPointsProgress}
          tooltipText={t('infoXPPoints')}
          titleProps={{ fontWeight: 700, fontSize: 14, pl: 1 }}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ml: -2.5,
            mb: 1,
          }}
        />

        {gamificationOpt?.enable &&
          !!gamificationPlayer?.pointsUntilNextRank && (
            <Box px={1} display="flex" justifyContent="space-between">
              <Typography {...typographyCaptionProps}>
                <strong>
                  {getFormatNumber(
                    gamificationPlayer.pointsUntilNextRank,
                    playerLocale,
                  )}
                </strong>
                {` ${t('forNextRank')}`}
              </Typography>
              <Typography {...typographyCaptionProps} fontWeight={700}>
                {playerNextRankName}
              </Typography>
            </Box>
          )}
      </Box>
    </Box>
  )
}
