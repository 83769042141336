import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RewardShopTypeEnum } from '@gamanza-engage/mf-enums'

import { RootState } from '@/state'
import { RewardShopViewTypeEnum } from '@/enums'
import { ALL_REWARDS_LIMIT, initialBoosterFilters } from '@/constants'

import { resetPIDWindowHash, setPIDWindowHash } from '@/utils'
import { useGlobalUi } from '@/hooks'
import { useRewardShopActions } from './use-reward-shop-actions.hook'

export const useRewardShop = () => {
  const {
    boosterProducts,
    externalProducts,
    bonusProducts,
    xpProducts,
    rewardType,
    pagination,
    orderProduct,
    rewardShopPage,
    isLoading,
  } = useSelector(({ rewardShop }: RootState) => rewardShop)

  const { gamificationPlayer, gamificationOpt } = useSelector(
    ({ player }: RootState) => player,
  )

  const { playerId } = useSelector(({ initConfig }: RootState) => initConfig)

  const { handleCloseDialog } = useGlobalUi()

  const {
    getAllProducts,
    getAllMyBoosters,
    getOneProduct,
    setPagination,
    setRewardType,
    setRewardShopPage,
    resetRewardShopPage,
    resetOrderProduct,
  } = useRewardShopActions()

  const handleOpenProductDetail = (productId: string) => {
    setRewardShopPage(RewardShopViewTypeEnum.PRODUCT_DETAIL)
    getOneProduct(productId)
    if (window.location.hash === '') {
      setPIDWindowHash(productId)
    }
  }

  const handleOpenCategory = (rewardShopType: RewardShopTypeEnum) => {
    setRewardType(rewardShopType)

    getAllProducts({
      limit: ALL_REWARDS_LIMIT,
      rewardType: rewardShopType,
    })
  }

  const handleChangePage = (
    page: number,
    specificRewardType?: RewardShopTypeEnum,
  ) => {
    setPagination({
      ...pagination,
      page,
    })

    getAllProducts({
      limit: pagination.limit,
      rewardType: specificRewardType || rewardType,
      page,
    })
  }

  const handleContinueShopping = () => {
    handleCloseDialog()
    resetPIDWindowHash()
    resetRewardShopPage()
    getAllMyBoosters({
      ...initialBoosterFilters,
      playerId: playerId,
    })
  }

  const handleTryAgain = () => resetOrderProduct()

  const getAllItems = (specificRewardType: RewardShopTypeEnum) => {
    setPagination({
      ...pagination,
      page: 1,
      limit: ALL_REWARDS_LIMIT,
    })

    getAllProducts({
      limit: ALL_REWARDS_LIMIT,
      rewardType: specificRewardType,
      page: 1,
    })
  }

  useEffect(() => {
    if (!gamificationOpt?.enable) {
      setRewardShopPage(RewardShopViewTypeEnum.PRODUCTS)
    }
  }, [gamificationOpt]) // eslint-disable-line

  useEffect(() => {
    if (
      rewardShopPage !== RewardShopViewTypeEnum.PRODUCTS &&
      rewardShopPage !== RewardShopViewTypeEnum.CATEGORY
    ) {
      setRewardShopPage(RewardShopViewTypeEnum.PRODUCTS)
    }
  }, []) // eslint-disable-line

  return {
    boosterProducts,
    externalProducts,
    bonusProducts,
    xpProducts,
    rewardType,
    pagination,
    orderProduct,
    gamificationPlayer,
    rewardShopPage,
    isLoading,
    handleOpenProductDetail,
    handleOpenCategory,
    handleChangePage,
    handleContinueShopping,
    handleTryAgain,
    getAllItems,
  }
}
