import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import {
  MissionHistoryThunks,
  MissionHistoryModels,
} from '@/state/mission-history'

export const getExpiredBundlesExtraReducer = (
  builder: ActionReducerMapBuilder<MissionHistoryModels.MissionHistoryState>,
) => {
  builder.addCase(
    MissionHistoryThunks.getExpiredBundlesThunk.pending,
    (state) => {
      state.isLoading = true
    },
  )
  builder.addCase(
    MissionHistoryThunks.getExpiredBundlesThunk.fulfilled,
    (state, { payload }) => {
      const { statusCode, ...bundles } = payload
      const expiredBundles = []

      Object.keys(bundles)?.forEach((key) => {
        bundles[key].forEach((bundleKey) => {
          if (bundleKey._id) {
            expiredBundles.push(bundleKey)
          }
        })
      })
      state.missionExpiredBundles = expiredBundles
      state.isLoading = false

      if (state.reloadBundles) {
        state.missionActiveBundles = state.reloadBundles
          ? []
          : state.missionActiveBundles
        state.reloadBundles = false
      }
    },
  )
  builder.addCase(
    MissionHistoryThunks.getExpiredBundlesThunk.rejected,
    (state) => {
      state.isLoading = false
    },
  )
}
