import { darken, Theme } from '@mui/material'

export const SidebarColors = (theme: Theme) => {
  const colorMain = theme.palette.primary.main

  return {
    progressColor: '#70B200',
    headerBackgroundColor: `linear-gradient(180deg, ${colorMain} 0%, ${darken(colorMain, 0.8)} 85%)`,
    contentBackgroundColor: theme.palette.background.paper,
    menuBackground: theme.palette.background.default,
    menuActiveBackground: colorMain,
    chestBackgroundColor: '#FE8E00',
  }
}
