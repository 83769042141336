import { Box } from '@mui/material'
import { SidebarMenuItemType } from '@/enums'
import { MissionCustomComponent, MissionStandardComponent } from '@/components'

import { SidebarHomeComponent, SidebarRewardShopComponent } from './'
import { useContentStyles } from './content.style'

type Props = {
  Div: HTMLElement
  sidebarClient: string
  openSidebar: boolean
  contentBackground: string
  sidebarMenuItem: string
  handleSetFullContent: (full: boolean) => void
  handleChangeMenuItem?: (sidebarMenuItem: string) => void
}

export const SidebarContentComponent = ({
  Div,
  sidebarClient,
  openSidebar,
  contentBackground,
  sidebarMenuItem,
  handleSetFullContent,
  handleChangeMenuItem,
}: Props) => {
  const { classes } = useContentStyles({
    openSidebar,
    contentBackground,
  })

  const content = () => {
    if (sidebarMenuItem === SidebarMenuItemType.HOME || !openSidebar) {
      return (
        <SidebarHomeComponent
          Div={Div}
          sidebarClient={sidebarClient}
          openSidebar={openSidebar}
          handleChangeMenuItem={handleChangeMenuItem}
        />
      )
    }

    if (sidebarMenuItem === SidebarMenuItemType.MISSIONS) {
      return (
        openSidebar && (
          <>
            {sidebarClient === 'JP' ? (
              <MissionCustomComponent
                handleChangeMenuItem={handleChangeMenuItem}
              />
            ) : (
              <MissionStandardComponent
                handleSetFullContent={handleSetFullContent}
                handleChangeMenuItem={handleChangeMenuItem}
              />
            )}
          </>
        )
      )
    }

    if (sidebarMenuItem === SidebarMenuItemType.REWARD_SHOP) {
      return (
        openSidebar && (
          <SidebarRewardShopComponent
            handleChangeMenuItem={handleChangeMenuItem}
          />
        )
      )
    }
  }

  return <Box className={classes.sidebarContent}>{content()}</Box>
}
