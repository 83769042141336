import { makeStyles } from 'tss-react/mui'
import { alpha, linearProgressClasses } from '@mui/material'
import { MissionColors } from '@/constants'

import { MissionStatusEnum, OrientationComponentsEnum } from '@/enums'

type Props = {
  orientation?: OrientationComponentsEnum
  amountItems?: number
  status?: MissionStatusEnum
}

export const useTimelineMissionsStyles = makeStyles<Props>()((
  theme,
  { orientation, amountItems, status },
) => {
  const progressBackground =
    theme.palette.mode === 'light' ? 'rgba(0,0,0,0.2)' : 'rgba(255,255,255,0.2)'

  const thicknessProgressBar =
    orientation === OrientationComponentsEnum.HORIZONTAL ? 22 : 15

  const progressColor =
    status === MissionStatusEnum.ACTIVE
      ? theme.palette.primary.main
      : MissionColors[MissionStatusEnum.PENDING]

  const heightWidthProgressBar = () => {
    if (orientation === OrientationComponentsEnum.HORIZONTAL) {
      return amountItems * 240 + 30
    }

    return amountItems * 88
  }

  return {
    timelineMissionsWrapper: {
      '& .timelineMissionContent': {
        display: 'flex',
        justifyContent: 'center',
        gap: orientation === OrientationComponentsEnum.HORIZONTAL ? 35 : 24,
      },

      '& .timelineProgressBar': {
        position: 'relative',

        [`& .${linearProgressClasses.root}`]: {
          borderRadius: thicknessProgressBar,
          backgroundColor: progressBackground,

          [`& .${linearProgressClasses.bar}`]: {
            borderRadius: thicknessProgressBar,
            backgroundColor: progressColor,

            '&::before': {
              width: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              margin: '0px auto',
              borderTop: `${thicknessProgressBar / 2}px solid ${alpha(theme.palette.common.white, 0.26)}`,
              borderTopLeftRadius: thicknessProgressBar,
              borderTopRightRadius: thicknessProgressBar,
              zIndex: 1,
              content: '""',
            },
          },
        },
      },

      '& .timelineListMissions': {
        '.step': {
          marginBottom: 12,
          position: 'relative',
          '& .stepNumber': {
            '&::before': {
              width: 31,
              height: 31,
              position: 'absolute',
              top: 8,
              left: 0,
              right: 0,
              margin: '0px auto',
              borderRadius: '50%',
              backgroundColor: theme.palette.primary.main,
              content: '""',
            },

            '& .number': {
              color: theme.palette.common.white,
              fontSize: 16,
              fontWeight: 700,
              position: 'absolute',
              left: 9,
              top: 12,
              width: 30,
              textAlign: 'center',
            },
          },
        },

        '& .step:first-of-type': {
          '& .stepStart': {
            fontSize: 16,
            fontWeight: 600,
          },
        },
      },
    },

    timelineVertical: {
      '& .timelineProgressBar': {
        width: 48,
        height: heightWidthProgressBar(),

        [`& .${linearProgressClasses.root}`]: {
          position: 'absolute',
          left: 25,
          top: 16,
          width: heightWidthProgressBar(),
          height: thicknessProgressBar,
          transform: 'rotate(90deg)',
          transformOrigin: 'left',
        },

        [`& .${linearProgressClasses.bar}`]: {
          left: 6,
        },
      },

      '& .timelineListMissions': {
        width: 223,

        '& .step': {
          '& .stepNumber': {
            position: 'absolute',
            left: -71,
            top: 11,
          },

          '& .missionChestIcon img': {
            width: 40,
            position: 'absolute',
            top: 15,
            right: 20,
          },
        },

        '& .step:first-of-type': {
          '.stepNumber': {
            top: -13,
          },
        },
      },
    },

    timelineHorizontal: {
      '& .timelineMissionContent': {
        flexDirection: 'column',
      },

      '& .timelineProgressBar': {
        width: heightWidthProgressBar(),
        height: 40,
        marginBottom: 90,
        order: 1,
        [`& .${linearProgressClasses.root}`]: {
          width: heightWidthProgressBar(),
          height: thicknessProgressBar,
        },
        [`& .${linearProgressClasses.bar}`]: {
          left: 30,
        },
      },

      '& .timelineListMissions': {
        display: 'flex',
        gap: 40,

        '& .step': {
          '& .stepNumber': {
            position: 'absolute',
            left: 0,
            bottom: -88,
            right: 0,
            margin: '0px auto',
            width: 48,
          },

          '& .missionChestIcon': {
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: -160,
            margin: '0px auto',
            width: 64,
            height: 64,
            backgroundColor: theme.palette.background.default,
            borderRadius: 4,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            '&::before': {
              display: 'block',
              top: 0,
              left: 0,
              right: 0,
              width: 15,
              height: 15,
              backgroundColor: theme.palette.background.default,
              transform: 'translateY(-50%) rotate(45deg)',
              position: 'absolute',
              margin: '0 auto',
              content: '""',
            },

            '& img': {
              width: 40,
              height: 40,
            },
          },
        },

        '& .step:first-of-type': {
          '.stepNumber': {
            left: -5,
          },
        },
      },
    },
  }
})
