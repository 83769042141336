import { Box } from '@mui/material'
import {
  MissionStatusEnum,
  MissionTimeTypeEnum,
  OrientationComponentsEnum,
  VariantsComponentsEnum,
} from '@/enums'
import { MissionCommonModels } from '@/state'

import { useMissionItemStyles } from './style'
import { MissionItemComponent } from '../common/mission-item.component'

type Props = {
  status: MissionStatusEnum
  endDate?: string
  timeType?: MissionTimeTypeEnum
  state?: MissionCommonModels.MissionProgressType
  orientation?: OrientationComponentsEnum
  variant?: VariantsComponentsEnum
  showArrow?: boolean
  handleClick?: () => void
}

export const MissionItemStandardComponent = ({
  status,
  endDate,
  timeType,
  state,
  orientation,
  variant,
  showArrow,
  handleClick,
}: Props) => {
  const { classes } = useMissionItemStyles({
    variant,
    orientation,
  })

  return (
    <Box
      className={classes.missionStandardWrapper}
      onClick={() => handleClick && handleClick()}
    >
      <MissionItemComponent
        status={status}
        endDate={endDate}
        timeType={timeType}
        state={state}
        handleClick={handleClick}
        orientation={orientation}
        variant={variant}
        showArrow={showArrow}
        showExtraIcon
        expanded
      />
    </Box>
  )
}
