import { makeStyles } from 'tss-react/mui'

export const useAnimations = makeStyles()(() => {
  return {
    animations: {
      overflow: 'initial',
      zIndex: 2,
      '& .opacityAnimation': {
        animation: 'opacityAnimation 1.5s infinite',

        '@keyframes opacityAnimation': {
          '0%': {
            opacity: 1,
          },
          '50%': {
            opacity: 0.5,
          },
          '100%': {
            opacity: 1,
          },
        },
      },
    },
  }
})
