import { createAsyncThunk } from '@reduxjs/toolkit'
import { Container } from 'typedi'
import { Rest } from '@/utils/api.utils'

import { LoginPayload, LoginResponse } from './models'
import { InitConfigActions } from './init-config.slice'

const apiService: Rest = Container.get(Rest)

export const loginThunk = createAsyncThunk(
  '@auth/LOGIN',
  async (payload: LoginPayload, thunkAPI) => {
    const { serviceUrl, ...body } = payload
    try {
      const response: LoginResponse = await apiService.sendData({
        method: 'POST',
        endpoint: '/api/oauth/v1/token',
        url: payload.serviceUrl,
        body,
        isLogin: true,
      })

      if (response.accessToken) {
        thunkAPI.dispatch(InitConfigActions.setIsErrorToken(false))
        localStorage.setItem(
          'gamanzaengage_token',
          JSON.stringify(response.accessToken),
        )
        localStorage.setItem(
          'gamanzaengage_serviceUrl',
          JSON.stringify(serviceUrl),
        )
      } else {
        thunkAPI.dispatch(InitConfigActions.setIsErrorToken(true))
        console.error(
          `Gamanza Engage Error Message \nThe widgets app is not implemented correctly. \nError: ${response.message} - URI: ${response.request.uri}. Please check the documentation at https://docs.gamanzaengage.com/widgets/widgets-configuration`,
        )
      }

      return response
    } catch (error) {
      throw error
    }
  },
)
