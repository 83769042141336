import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { MyRewardsThunks, MyRewardsModels } from '@/state'
import { RewardTriggerTypeEnum } from '@/enums'

export const getRewardsByTriggerExtraReducer = (
  builder: ActionReducerMapBuilder<MyRewardsModels.MyRewardsState>,
) => {
  builder.addCase(MyRewardsThunks.getRewardsByTriggerThunk.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(
    MyRewardsThunks.getRewardsByTriggerThunk.fulfilled,
    (state, { payload }) => {
      const { docs } = payload

      const rewardsTrigger: MyRewardsModels.RewardsByTriggerType = {}

      docs?.forEach((item) => {
        const { trigger, id, playerId, metadata } = item
        const groupId = item.group.groupId

        const newTrigger = trigger || RewardTriggerTypeEnum.EASTER_EGGS

        if (!rewardsTrigger[newTrigger]) {
          rewardsTrigger[newTrigger] = {}
        }

        if (!rewardsTrigger[newTrigger][groupId]) {
          rewardsTrigger[newTrigger][groupId] = {
            rewards: [],
            trigger: newTrigger,
            groupId: groupId,
            id: id,
            playerId: playerId,
            metadata: metadata,
          }
        }

        rewardsTrigger[newTrigger][groupId].rewards.push(item.earnedReward)

        return rewardsTrigger
      })

      state.myRewardsByTrigger = rewardsTrigger
      state.isLoading = false
    },
  )

  builder.addCase(
    MyRewardsThunks.getRewardsByTriggerThunk.rejected,
    (state) => {
      state.isLoading = false
    },
  )
}
