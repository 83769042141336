import { createAsyncThunk } from '@reduxjs/toolkit'
import Container from 'typedi'
import { Rest } from '@/utils/api.utils'

import { MyRewardsModels, WidgetEndpoints } from '@/state'
import { MyRewardsActionTypes } from '../constants'

const apiService: Rest = Container.get(Rest)

export const getRewardsByTriggerThunk = createAsyncThunk(
  MyRewardsActionTypes.GET_REWARDS_BY_TRIGGER,
  async (payload: MyRewardsModels.GetAllRewardsPayload, thunkAPI) => {
    try {
      const response: MyRewardsModels.GetAllRewardsResponse =
        await apiService.sendData({
          method: 'GET',
          endpoint: WidgetEndpoints.REWARD_PROCESSOR,
          queries: payload,
        })

      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
