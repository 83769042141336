import { makeStyles } from 'tss-react/mui'
import { darken, typographyClasses } from '@mui/material'

interface Props {
  expanded?: boolean
  mobileWidth?: number
  gap?: number
}

export const useSliderItemsStyles = makeStyles<Props>()((
  theme,
  { expanded, mobileWidth, gap },
) => {
  return {
    sliderItemsWrapper: {
      minWidth: '100%',
      position: 'relative',
      height: '100%',
      animation: 'flexAnimationOpacity 0.35s ease-in forwards',

      '@keyframes flexAnimationOpacity': {
        '0%': {
          opacity: 0,
        },

        '100%': {
          opacity: 1,
        },
      },

      [theme.breakpoints.down('sm')]: {
        minWidth: expanded ? '100%' : 'auto',
      },
    },

    sliderItemsHeader: {
      display: 'flex',
      justifyContent: expanded ? 'space-between' : 'center',
      alignItems: 'center',

      [theme.breakpoints.down('sm')]: {
        display: expanded ? 'flex' : 'none',
      },

      [`& .${typographyClasses.body1}`]: {
        fontSize: expanded ? 14 : 10,
        textTransform: 'uppercase',
        fontWeight: 700,
      },

      [`& .${typographyClasses.caption}`]: {
        fontSize: 12,
        fontWeight: 600,
      },
    },

    sliderItemsContent: {
      display: 'flex',
      justifyContent: expanded ? 'left' : 'center',
      flexDirection: expanded ? 'row' : 'column',
      gap: expanded ? gap || 12 : 0,
      alignItems: 'center',

      // carousel
      overflowX: 'auto',
      scrollSnapType: 'x mandatory',
      scrollBehavior: 'smooth',
      width: '100%',
      margin: '0px auto',
      padding: '0 0 15px',
      flexWrap: 'nowrap',
      boxSizing: 'border-box',

      // added to hide scrollbar
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },

      '& > *': {
        scrollSnapAlign: 'start',
      },

      [theme.breakpoints.down('sm')]: {
        gap: expanded ? 8 : 0,
        maxWidth: expanded ? mobileWidth || 298 : 'auto',
        flexDirection: 'row',
        overflowX: expanded ? 'auto' : 'inherit',
        justifyContent: 'left',
        padding: expanded ? '0 0 15px' : 0,
      },
    },

    sliderItemsBullets: {
      display: 'flex',
      justifyContent: 'center',
      gap: 16,
      paddingBottom: 20,

      '& .sliderItemsBullet': {
        cursor: 'pointer',
        width: 12,
        height: 12,
        backgroundColor: darken(theme.palette.background.default, 0.1),
        borderRadius: '50%',
        '&.active': {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
  }
})
