import { MutableRefObject, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Box, LinearProgress, Typography } from '@mui/material'
import { FlagTwoTone } from '@mui/icons-material'
import { MissionStatusEnum, OrientationComponentsEnum } from '@/enums'

import { MissionCommonModels, RootState } from '@/state'
import { TimelineMissionStepComponent } from './timeline-mission-step.component'
import { useTimelineMissionsStyles } from './style'

type Props = {
  missions: MissionCommonModels.MissionType[]
  handleClick: (id: string) => void
  timelineType?: OrientationComponentsEnum
  state?: MissionCommonModels.MissionProgressType
  status?: MissionStatusEnum
  componentRef?: MutableRefObject<any>
}

export const TimelineMissionsComponent = ({
  missions,
  handleClick,
  timelineType,
  state,
  status,
  componentRef,
}: Props) => {
  const { t } = useTranslation()

  const { classes, cx } = useTimelineMissionsStyles({
    orientation: timelineType,
    amountItems: missions.length,
    status,
  })

  const { baseUrl } = useSelector(({ initConfig }: RootState) => initConfig)

  useEffect(() => {
    if (componentRef?.current) {
      const lastMission = missions.filter(
        (mission) => mission.status === MissionStatusEnum.COMPLETED,
      )

      if (lastMission) {
        componentRef.current.scrollTop += lastMission.length * 88
      }
    }
  }, [componentRef]) // eslint-disable-line

  return (
    <Box
      className={`${classes.timelineMissionsWrapper} ${cx({
        [classes.timelineHorizontal]:
          timelineType === OrientationComponentsEnum.HORIZONTAL,
        [classes.timelineVertical]:
          timelineType === OrientationComponentsEnum.VERTICAL,
      })}`}
    >
      <Box className="timelineMissionContent">
        <Box className="timelineProgressBar">
          <LinearProgress variant="determinate" value={state?.progress || 0} />
        </Box>
        <Box className="timelineListMissions">
          <Box className="step">
            <Box className="stepNumber">
              <img
                src={`${baseUrl}assets/icons/common/icon-timeline-start.svg`}
                alt="Timeline Bullet"
              />
              <Box className="number">
                <FlagTwoTone />
              </Box>
            </Box>
            {timelineType === OrientationComponentsEnum.VERTICAL && (
              <Typography className="stepStart">{t('start')}</Typography>
            )}
          </Box>
          {missions.map((mission, index) => (
            <TimelineMissionStepComponent
              mission={mission}
              index={index}
              timelineType={timelineType}
              key={mission._id}
              handleClick={handleClick}
            />
          ))}
        </Box>
      </Box>
    </Box>
  )
}
