import { Box, CircularProgress, Typography } from '@mui/material'
import { BoosterColorsType } from '@/state/my-booster/models'
import { useStyles } from './style'

type Props = {
  percentageProgress: number
  image: React.ReactNode
  breakingAnimation: React.ReactNode
  boosterType: string
  boosterRate: number
  boosterColors: BoosterColorsType
  popoverItem?: boolean
}

export function CircularProgressComponent({
  percentageProgress,
  image,
  breakingAnimation,
  boosterType,
  boosterRate,
  boosterColors,
  popoverItem,
}: Props) {
  const { classes } = useStyles({ boosterType, boosterColors })

  return (
    <>
      <CircularProgress
        variant="determinate"
        value={100}
        size={40}
        thickness={4}
        className="completed"
        sx={{ position: 'absolute' }}
      />
      <CircularProgress
        variant="determinate"
        value={percentageProgress}
        size={40}
        thickness={4}
        className="circular"
      />

      <Box
        className={classes.rateContainer}
        sx={{
          height: popoverItem ? 32 : 33,
          width: popoverItem ? 32 : 33,
        }}
      >
        {breakingAnimation}
        {image}
        <Typography
          variant="caption"
          className="rate"
        >{`${boosterRate}x`}</Typography>
      </Box>
    </>
  )
}
