import { RewardTypeEnum } from '@gamanza-engage/mf-enums'
import { RewardProcessorStatusEnum } from '@/enums'
import { LIMIT } from '../pagination.constant'

export const initialBoosterFilters = {
  rewardType: [
    RewardTypeEnum.LEVEL_BOOSTER,
    RewardTypeEnum.TOKEN_BOOSTER,
    RewardTypeEnum.MISSION_BOOSTER,
  ],
  status: RewardProcessorStatusEnum.PENDING,
  limit: LIMIT,
  page: 1,
}
