import { useEffect, useRef, useState } from 'react'
import { RootState } from '@/state'
import { useSelector } from 'react-redux'

import { MissionTabsEnum } from '@/enums'
import { useMissionsActions } from './use-missions-actions.hook'

export const useMissionsCustom = () => {
  const contentRef = useRef(null)

  const { missionActiveBundles, missionsExpired, reloadBundles } = useSelector(
    ({ missionHistory }: RootState) => missionHistory,
  )

  const { getActiveBundles, getExpiredMissions } = useMissionsActions()

  const [missionTab, setMissionTab] = useState(MissionTabsEnum.MISSIONS)

  useEffect(() => {
    if (
      missionTab === MissionTabsEnum.MISSIONS &&
      (missionActiveBundles?.length === 0 || missionActiveBundles === null)
    ) {
      getActiveBundles()
    } else {
      if (
        reloadBundles ||
        missionsExpired?.length === 0 ||
        missionsExpired === null
      ) {
        getExpiredMissions()
      }
    }
  }, [missionTab]) // eslint-disable-line

  return {
    contentRef,
    missionsExpired,
    missionTab,
    setMissionTab,
  }
}
