import { makeStyles } from 'tss-react/mui'
import { buttonClasses, gridClasses, typographyClasses } from '@mui/material'

export const useStyles = makeStyles()((theme) => {
  return {
    content: {
      [`& .${gridClasses['grid-xs-12']}`]: {
        width: '100%',
      },

      [`& .${typographyClasses.body2}`]: {
        color: theme.palette.text.secondary,
      },
    },
    avatar: {
      width: 80,
      height: 80,
      margin: '0 auto',
      backgroundColor: 'transparent',

      svg: {
        fontSize: 80,
      },

      '&.search': {
        color: theme.palette.warning.main,
      },
      '&.success': {
        color: theme.palette.success.light,
      },
      '&.error': {
        color: theme.palette.error.main,
      },
    },
    noContent: {
      width: '100%',
      maxWidth: 235,
      margin: '0 auto',

      [`& .${gridClasses['grid-xs-12']}`]: {
        width: '100%',
      },

      '& .icon': {
        width: 48,
        margin: '0 auto',

        '& svg': {
          width: 48,
          height: 48,
        },
      },

      [`& .${typographyClasses.body2}`]: {
        color: theme.palette.text.secondary,
      },

      [`& .${buttonClasses.root}`]: {
        color: theme.palette.text.primary,
        borderColor: theme.palette.text.primary,
        width: 'max-content',
        padding: '5px 10px',
        textTransform: 'uppercase',
        borderRadius: 4,
        fontSize: 12,

        '&:hover': {
          backgroundColor: 'inherit',
        },
      },
    },
  }
})
