import { useEffect, useRef, useState } from 'react'
import { Box } from '@mui/material'
import { ModalComponent } from '@/components/common'

import { GeneralModels } from '@/state'
import { ModalStatusFinalComponent, ModalStatusInitialComponent } from '../'
import { useStyles } from '../styles'

type Props = {
  initialAnimationData: GeneralModels.LottieAnimationData
  finalAnimationData: GeneralModels.LottieAnimationData
}

export const ModalProgressContentComponent = ({
  initialAnimationData,
  finalAnimationData,
}: Props) => {
  const [modalStatus, setModalStatus] = useState<'initial' | 'final'>('initial')

  const containerRef = useRef(null)

  const { classes } = useStyles({})

  useEffect(() => {
    const timerFinal = setTimeout(() => {
      setModalStatus('final')
    }, 3500)

    return () => {
      clearTimeout(timerFinal)
    }
  }, [])

  return (
    <ModalComponent>
      <Box
        className={`${classes.modalContent} ${
          modalStatus === 'initial' && classes.modalContentInitial
        }`}
        ref={containerRef}
      >
        {modalStatus === 'initial' && (
          <ModalStatusInitialComponent
            initialAnimationData={initialAnimationData}
          />
        )}
        {modalStatus === 'final' && (
          <ModalStatusFinalComponent finalAnimationData={finalAnimationData} />
        )}
      </Box>
    </ModalComponent>
  )
}
