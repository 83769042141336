import { makeStyles } from 'tss-react/mui'
import { alpha, badgeClasses, typographyClasses } from '@mui/material'
import { getBorderColorByMode, SidebarColors } from '@/constants'

type Props = {
  closedVersion: boolean
}
export const useStyles = makeStyles<Props>()((theme, { closedVersion }) => {
  const amountChestColor = SidebarColors(theme).chestBackgroundColor

  const itemBackground = alpha(theme.palette.text.primary, 0.08)

  return {
    myRewards: {
      display: 'flex',
      justifyContent: closedVersion ? 'center' : 'left',
      flexDirection: closedVersion ? 'column-reverse' : 'row-reverse',
      minWidth: closedVersion ? 42 : 'auto',
      gap: closedVersion ? 0 : 8,
      backgroundColor: theme.palette.background.default,
      borderRadius: 8,
      marginTop: 8,
      border: !closedVersion
        ? `1px solid ${getBorderColorByMode(theme)}`
        : 'none',
      boxShadow: !closedVersion
        ? '0px 4px 20px 0px rgba(0, 0, 0, 0.05)'
        : 'none',
      padding: !closedVersion ? 8 : 0,

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'left',
        flexDirection: 'row-reverse',
        marginLeft: closedVersion ? 8 : 0,
      },

      [`& .${badgeClasses.standard}`]: {
        backgroundColor: amountChestColor,
        color: theme.palette.common.white,
        fontWeight: '600',
        fontSize: 10,
        borderRadius: 3,
        width: 16,
        height: 16,
        minWidth: 16,
        top: closedVersion ? 'initial' : 2,
        bottom: !closedVersion ? 'initial' : -10,
        right: 2,

        [theme.breakpoints.down('sm')]: {
          top: 2,
          bottom: 'initial',
        },
      },

      '& .myRewardItem': {
        padding: closedVersion ? 5 : '4px 2px 17px',
        borderRadius: 8,
        backgroundColor: closedVersion
          ? theme.palette.background.default
          : itemBackground,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
        minWidth: closedVersion ? 45 : 64,
        width: closedVersion ? 45 : 64,
        height: closedVersion ? 45 : 68,
        cursor: 'pointer',
        border: closedVersion
          ? `1px solid ${getBorderColorByMode(theme, 0.1)}`
          : 'none',

        [theme.breakpoints.up('sm')]: {
          marginTop: closedVersion ? -5 : 0,
        },

        [theme.breakpoints.down('sm')]: {
          marginLeft: closedVersion ? -5 : 0,
        },

        [`& .${typographyClasses.body2}`]: {
          textTransform: 'uppercase',
          fontWeight: '600',
          fontSize: 10,
          position: 'absolute',
          bottom: 2,
          display: closedVersion ? 'none' : 'block',
        },

        '& .chestIcon': {
          width: closedVersion ? 36 : 47,
          height: closedVersion ? 36 : 47,
        },
      },

      '& .addBooster': {
        width: closedVersion ? 33 : 45,
        height: closedVersion ? 33 : 45,
        minWidth: closedVersion ? 33 : 45,
        background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${theme.palette.common.black} 135%)`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: `1px solid ${theme.palette.primary.main}`,
        zIndex: '100',
        position: 'relative',

        '& .addIcon': {
          position: 'absolute',
          bottom: -3,
          right: closedVersion ? -5 : -11,
          backgroundColor: theme.palette.primary.main,
          borderRadius: '50%',
          color: theme.palette.common.white,
          border: `2px solid ${theme.palette.common.white}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: closedVersion ? 15 : 24,
          height: closedVersion ? 15 : 24,
        },

        '& .addIcon svg': {
          fontSize: closedVersion ? 10 : 18,
        },
      },
    },
  }
})
