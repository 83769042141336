import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RewardTypeEnum } from '@gamanza-engage/mf-enums'

import { Box, Typography } from '@mui/material'
import { AccessTimeOutlined } from '@mui/icons-material'
import { GeneralModels, RootState } from '@/state'

import { RewardTypeInformation } from '@/constants'
import { rewardBoosterTime, rewardDescription } from '@/utils'
import { useAnimationRewardsStyles } from './rewards-animation.style'

type Props = {
  rewards: GeneralModels.RewardCategoryType[]
  rewardOne: boolean
  mainClassName: string
  showScroll?: boolean
}

export const RewardOneAnimationComponent = ({
  rewards,
  rewardOne,
  mainClassName,
  showScroll = false,
}: Props) => {
  const { t } = useTranslation()

  const { playerLocale } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const rewardTypeInfo = RewardTypeInformation()

  const { classes, cx } = useAnimationRewardsStyles()

  const isRewardItemOne = mainClassName === 'rewardItemOne'

  const rewardItemIconClasses = isRewardItemOne
    ? 'rewardItemIcon rewardItemOneIcon'
    : 'rewardItemIcon'

  const rewardItemTextClasses = isRewardItemOne
    ? 'rewardItemDescription rewardItemOneText'
    : 'rewardItemDescription'

  return (
    <Box
      className={cx({
        [classes.rewardOneAnimation]: rewardOne,
        [classes.rewardListAnimation]: !rewardOne,
      })}
      overflow={showScroll ? 'auto' : 'hidden'}
    >
      {rewards?.map((reward) => {
        return (
          <Box
            className={mainClassName}
            key={`${mainClassName}-${reward?.rewardType}`}
            sx={{ opacity: isRewardItemOne ? 1 : 0 }}
          >
            <Box
              className={rewardItemIconClasses}
              sx={{ opacity: isRewardItemOne ? 0 : 1 }}
            >
              {rewardTypeInfo[reward?.rewardType].icon}
            </Box>
            <Box
              className={rewardItemTextClasses}
              sx={{ opacity: isRewardItemOne ? 0 : 1 }}
            >
              <Typography variant="body2">
                {`${rewardDescription(reward, playerLocale)} ${t(`${rewardTypeInfo[reward?.rewardType].label}`)}`}
              </Typography>
              {(reward?.rewardType === RewardTypeEnum.LEVEL_BOOSTER ||
                reward?.rewardType === RewardTypeEnum.MISSION_BOOSTER ||
                reward?.rewardType === RewardTypeEnum.TOKEN_BOOSTER) && (
                <Typography variant="caption">
                  <AccessTimeOutlined />
                  {rewardBoosterTime(reward.timeFrame)}
                </Typography>
              )}
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}
