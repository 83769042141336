import { useState } from 'react'
import {
  Box,
  SxProps,
  Typography,
  TypographyProps,
  colors,
  useTheme,
} from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'

import { HtmlTooltip } from '@/theme/tooltip.theme'

type Props = {
  title: string
  description: string
  icon?: React.ReactNode
  maxWidth?: number
  infoSxProps?: SxProps
}

export const TooltipComponent = ({
  title,
  description,
  icon,
  maxWidth,
  infoSxProps,
}: Props) => {
  const theme = useTheme()

  const [isOpenedTooltip, setIsOpenedTooltip] = useState(false)

  const typographyCaptionProps: TypographyProps = {
    variant: 'caption',
    fontWeight: 400,
    fontSize: 12,
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.common.white
        : theme.palette.grey[800],
  }

  const typographyh6Props: TypographyProps = {
    variant: 'h6',
    fontWeight: 700,
    fontSize: 14,
  }

  return (
    <HtmlTooltip
      open={isOpenedTooltip}
      onClose={() => setIsOpenedTooltip(false)}
      onClick={() => setIsOpenedTooltip(true)}
      onMouseEnter={() => setIsOpenedTooltip(true)}
      maxwidth={maxWidth}
      title={
        <Box display="flex" gap={2}>
          {!!icon && icon}
          <Box display="flex" flexDirection="column">
            <Typography {...typographyh6Props}>{title}</Typography>
            <Typography {...typographyCaptionProps}>{description}</Typography>
          </Box>
        </Box>
      }
      placement="top-end"
    >
      <InfoOutlined
        sx={{
          color: theme.palette.primary.main || colors.red[700],
          position: 'absolute',
          top: '50%',
          right: -20,
          transform: 'translate(0, -50%)',
          ...infoSxProps,
        }}
        fontSize="inherit"
      />
    </HtmlTooltip>
  )
}
