import {
  MissionEventTypeEnum,
  MissionNextLevelTypeEnum,
  MissionObjectiveConditionTypeEnum,
} from '@/enums'
import { TimeFrameEnum } from '@gamanza-engage/mf-enums'

export const translation = {
  activate: 'Activate',
  activateBooster: 'Activate Booster',
  sendToMyPersonalEmail: 'Send to my personal email',
  activatedSuccessfully: 'Activated successfully.',
  activeBooster: 'Active Booster',
  activeBoosterDescription:
    'If you activate this Booster, it will show when launching a game. Otherwise, it will show as Pending under My Boosters. You may re-position the booster by holding and dragging it.',
  addressOrder: 'Address and order details',
  all: 'All',
  back: 'Back',
  backToList: 'Back to list',
  backToProduct: 'Back to product',
  boosters: 'Boosters',
  bonusOffer: 'Bonus Offer',
  buyNow: 'Buy Now',
  by: 'by',
  cancel: 'Cancel',
  cancelled: 'Cancelled',
  capReached: `Your {{periodType}} Tokens Cap has been reached. For {{timeText}}, you will not accumulate further tokens.`,
  capReachedBoosterError: `You cannot activate this Tokens Booster {{timeText}}, because your {{periodType}} Tokens Cap was already reached. Please try again {{nextTimeText}}.`,
  casinoLocationCustom:
    'I would like to order the Product to a specific address:',
  casinoLocationJPOne:
    'I would like to order the Product to the Grand Casino Baden and pick it up',
  casinoLocationJPTwo: `(you'll get a notification once the Product arrives):`,
  casinoLocationJPThree: 'Grand Casino Baden, Haselstrasse 2, 5400 Baden',
  checkout: 'Check Out',
  channels: 'Channels',
  channelType: {
    email: 'Email',
    sms: 'SMS',
    phone: 'Phone',
    post: 'Post',
  },
  city: 'City',
  clickToOpen: 'Click to open',
  completed: 'Completed',
  conditionAmounts: 'Condition Amounts',
  confirmActivateBooster: 'Do you want to activate the booster?',
  congratulations: 'Congratulations!',
  congratulationsLevelUp: `You’ve reached level {{level}} now`,
  congratulationsMissionRewards: `You have earned the following rewards:`,
  congratulationsRankUp: `You’ve reached rank {{rank}} now`,
  congratulationsRewards: `Congratulations! You've leveled up to {{type}}! You earned the following rewards:`,
  congratulationsWithoutRewards: `Congratulations! You've leveled up to {{type}}!`,
  continueShopping: 'Continue Shopping',
  continuePlaying: 'Continue Playing',
  country: 'Country',
  day_one: 'Day',
  day_other: 'Days',
  description: 'Description',
  disabledStock: 'There is not enough stock for this product',
  disabledSubmit: "You don't have enough tokens to buy this product",
  duration: 'Duration',
  durationType: {
    Y: 'Years',
    W: 'Weeks',
    Mth: 'Months',
    D: 'Days',
    H: 'Hours',
    Min: 'Minutes',
    S: 'Seconds',
  },
  edit: 'Edit',
  email: 'Email',
  error: 'Error',
  errorLoading: 'Connection problems.',
  errorOccurred: 'An error has occurred. Please try again.',
  find: 'Find',
  forNextLevel: 'for the next level!!',
  forNextRank: 'for the next rank!!',
  gameCategories: 'Game Categories',
  gameProviders: 'Game Providers',
  games: 'Games',
  goBack: 'Go Back',
  godsonAccountStatus: 'Godson Account Status',
  godsonAccountStatusType: {
    INITIAL: 'Initial',
    PROVISIONAL: 'Provisional',
    ACTIVE: 'Active',
    FULLY_IDENTIFIED: 'Fully Identified',
  },
  goHome: 'Go home',
  goShopping: 'Go Shopping',
  history: 'History',
  hour_one: 'Hour',
  hour_other: 'Hours',
  id: 'Id',
  important: 'Important',
  infoBoosters:
    'Lorem ipsum dolor sit amet consectetur. Turpis non ut id a sagittis.',
  infoLevel:
    'Lorem ipsum dolor sit amet consectetur. Turpis non ut id a sagittis.',
  infoMyRewards:
    'Lorem ipsum dolor sit amet consectetur. Turpis non ut id a sagittis.',
  infoRank:
    'Lorem ipsum dolor sit amet consectetur. Turpis non ut id a sagittis.',
  infoTokens:
    'Lorem ipsum dolor sit amet consectetur. Turpis non ut id a sagittis.',
  infoXPPoints:
    'Lorem ipsum dolor sit amet consectetur. Turpis non ut id a sagittis.',
  inStock: 'In Stock',
  item: 'Item',
  lastNumberDays: 'Last {{number}} days',
  level: 'Level',
  levelBooster: 'Level Booster',
  levelReward: 'Level Reward',
  linkNoAvailable: 'Sorry the link is no longer available',
  loyaltyProgram: 'Loyalty Program',
  lowStock: 'Only {{remainingItems}} left in stock now',
  markets: 'Markets',
  mechanicsCTAOptIn: 'Opt-In',
  mechanicsCTAOptOut: 'Opt-Out',
  mechanicsDescriptionOptOut:
    'You can deactivate all loyalty features by choosing to opt out. You will no longer accumulate XP Points, Tokens or progress through levels. You will not be able to access the Reward Shop or buy any products, and any purchased item(s) pending activation will be lost with no return.',
  mechanicsDescriptionOptIn:
    'If you want to voluntarily reactivate the gamification functions, you can request it here',
  mechanicsDisableState:
    'Not available because you opted out of the Loyalty Program. Please contact customer support for more information.',
  mechanicsModalCTAOptIn: 'Reactivate',
  mechanicsModalCTAOptOut: 'Deactivate',
  mechanicsModalConfirmOptIn:
    'I confirm I want to reactivate loyalty features and start receiving benefits again.',
  mechanicsModalConfirmOptOut:
    'I confirm that I wish to opt out of all loyalty features, including access to the rewards store. I will not accumulate XP Points or Tokens.',
  mechanicsModalTitleOptIn: 'Reactivate Loyalty Features',
  mechanicsModalTitleOptOut: 'Deactivate Loyalty Features',
  mechanicsModalSubtitleOptIn:
    'Please confirm that you want to reactivate all loyalty features.',
  mechanicsModalSubtitleOptOut:
    'Please confirm that you want to deactivate all loyalty features.',
  mechanicsSuccessOptIn:
    'The process of reactivating the gamification functions was carried out successfully.',
  mechanicsSuccessOptOut:
    'The process of excluding the gamification functions was carried out successfully.',
  mechanicsSuccessOptInExternal:
    'You have been opted in to all gamification features.',
  mechanicsSuccessOptOutExternal:
    'You have been opted out of all gamification features.',
  mechanicsWarning:
    'Upon deactivation, all accumulated XP Points and Tokens, purchased items, as well as your current level, will be automatically lost and cannot be recovered.',
  messageMissionCompleted: 'You just completed a Mission!',
  messageMissionBundleCompleted:
    'The mission bundle was completed successfully',
  messageMissionEarnedRewards: `Great job, you've earned your rewards!`,
  minimumAmounts: 'Minimum Amounts',
  minute_one: 'Minute',
  minute_other: 'Minutes',
  missionBooster: 'Mission Booster',
  missionCompleted: 'Mission Completed',
  missionConditionType: {
    [MissionObjectiveConditionTypeEnum.QUANTITY]: 'Quantity',
    [MissionObjectiveConditionTypeEnum.TOTAL_BET_AMOUNT]: 'Total Bet Amount',
    [MissionObjectiveConditionTypeEnum.SUCCESSIVE_QUANTITY]:
      'Successive Quantity',
    [MissionObjectiveConditionTypeEnum.TOTAL_WIN_AMOUNT]: 'Total Win Amount',
  },
  missionEventType: {
    [MissionEventTypeEnum.COMPLETED_DEPOSIT]: 'Completed Deposit',
    [MissionEventTypeEnum.COMPLETED_WITHDRAWAL]: 'Completed Withdrawal',
    [MissionEventTypeEnum.WAGER]: 'Casino Wager',
    [MissionEventTypeEnum.WIN]: 'Casino Win',
    [MissionEventTypeEnum.REFER_A_FRIEND]: 'Refer a friend',
    [MissionEventTypeEnum.LOGIN]: 'Login',
    [MissionEventTypeEnum.ACTIVATE_ACCOUNT]: 'Activate Account (KYC)',
    [MissionEventTypeEnum.ACCOUNT_VERIFICATION]: 'Account Verification',
    [MissionEventTypeEnum.SELF_ASSESSMENT_TEST]: 'Self Assessment Test',
    [MissionEventTypeEnum.OPT_IN]: 'Marketing Preferences',
    [MissionEventTypeEnum.LEVEL_UP]: 'Level Up',
    [MissionEventTypeEnum.SPORT_WAGER]: 'Sport Wager',
    [MissionEventTypeEnum.SPORT_WIN]: 'Sport Win',
  },
  missionHistory: 'Mission History',
  missionIncompleted: 'Mission Incompleted',
  missionLevelType: {
    [MissionNextLevelTypeEnum.ANY]: 'Any',
    [MissionNextLevelTypeEnum.SPECIFIC_LEVEL]: 'Specific Level',
  },
  missionLocked: 'Mission Locked',
  missionProgress: 'Mission Progress',
  missionReward: 'Mission Reward',
  missionRewards: 'Mission Rewards',
  missions: 'Missions',
  missionsHistory: 'Missions History',
  missionStatusType: {
    completed: 'Completed',
    locked: 'Locked',
    claim: 'Claim',
    going: 'Going',
    incomplete: 'Incomplete',
  },
  month_one: 'Month',
  month_other: 'Months',
  myBoosters: 'My Boosters',
  myProduct: 'My Product',
  myRewards: 'My Rewards',
  name: 'Name',
  next: 'Next',
  nextTimeText: {
    [TimeFrameEnum.DAILY]: 'tomorrow',
    [TimeFrameEnum.WEEKLY]: 'next week',
    [TimeFrameEnum.MONTHLY]: 'next month',
  },
  noActiveBoosters: 'There are no active boosters',
  noBoostersAvailable: 'No boosters available',
  noBoostersYet: 'No Boosters Yet',
  noMissions: 'No Missions',
  noMissionsMessage:
    'There are no missions for now, but the excitement never stops. Keep playing and come back soon!',
  noMissionsHistory: 'No Missions History',
  noMissionsHistoryMessage: `It looks like you haven't joined any tournaments yet. Once you do, you'll see all your results and achievements here.`,
  noProducts: 'No Products',
  noProductsMessage:
    'Our shelves are empty for now, but the excitement never stops. Keep playing and return soon!',
  noProductsHaveBeenBought: 'You haven’t bought any products yet.',
  noPurchasesYet: 'No Purchases Yet',
  noRewardsYet: 'No Rewards Yet',
  note: 'Note',
  objectives: 'Objectives',
  objectivesCompletedMessage: 'You completed the following objectives:',
  open: 'Open',
  orderNow: 'Order Now',
  orderBindingly: 'Order Bindingly',
  other: 'Other',
  out_of_stock: 'Out of Stock',
  pageError: 'Page Error',
  pageErrorMessage: 'Sorry there was a connection problem',
  paymentError: 'Payment Error',
  paymentSuccess: 'Payment Success',
  paymentMethods: 'Payment Methods',
  paymentMessageSuccess: 'The purchase was made successfully',
  pending: 'Pending',
  postalCode: 'Postal Code',
  prev: 'Prev',
  price: 'Price',
  productDetails: 'Product Details',
  purchased: 'Purchased',
  purchasedDate: 'Purchased Date',
  qty: 'QTY',
  quantity: 'Quantity',
  rank: 'Rank',
  rankReward: 'Rank Reward',
  reactivate: 'Reactivate',
  readMore: 'Read More',
  readLess: 'Read Less',
  receives: 'Who receives the item?',
  remainingTokens: 'Remaining Tokens:',
  remainingTokensAfter: 'Remaining Tokens after the order:',
  requiredField: 'This field is required.',
  requiredRankMessage: `You don't have the necessary Rank to buy this product.`,
  requiredFieldMessage: 'All fields are required.',
  rewardCategorybonus: 'Bonus',
  rewardCategorybooster: 'Booster',
  rewardCategoryexternal_product: 'Products',
  rewardCategoryxp: 'XP Points',
  rewards: 'Rewards',
  rewardShop: 'Reward Shop',
  rewardShopDescription:
    'Here you can redeem your tokens for attractive rewards, view your order history and activate boosters to collect tokens faster.',
  seeAll: 'See All',
  seeDetails: 'See Details',
  seeMore: 'See more',
  seeProgress: 'See Progress',
  selectDelivery: 'Select delivery location',
  shipped: 'Shipped',
  shippingInformation: 'Shipping Information',
  shopHistory: 'Shop History',
  shopTitle: 'Shop',
  skip: 'Skip',
  sports: 'Sports',
  start: 'Start',
  status: 'Status',
  street: 'Street',
  streetNumber: 'Street Number',
  success: 'Success',
  summary: 'Summary',
  teams: 'Teams',
  third_party: '3rd Party Ordered',
  timeframeType: {
    [TimeFrameEnum.DAILY]: 'Daily',
    [TimeFrameEnum.WEEKLY]: 'Weekly',
    [TimeFrameEnum.LIFETIME]: 'Lifetime',
    [TimeFrameEnum.RANGE]: 'Range',
    [TimeFrameEnum.MONTHLY]: 'Monthly',
  },
  timeframeTypeMissions: {
    [TimeFrameEnum.DAILY]: 'Daily Missions',
    [TimeFrameEnum.WEEKLY]: 'Weekly Missions',
    [TimeFrameEnum.LIFETIME]: 'Lifetime Missions',
    [TimeFrameEnum.RANGE]: 'Range Missions',
    [TimeFrameEnum.MONTHLY]: 'Monthly Missions',
  },
  timeText: {
    [TimeFrameEnum.DAILY]: 'Today',
    [TimeFrameEnum.WEEKLY]: 'This Week',
    [TimeFrameEnum.MONTHLY]: 'This Month',
  },
  tokenBooster: 'Token Booster',
  tokens: 'Tokens',
  total: 'Total',
  tournaments: 'Tournaments',
  tryAgain: 'Try Again',
  value: 'Value',
  week_one: 'Week',
  week_other: 'Weeks',
  wonChest: "You've won a chest! Open it to reveal amazing prizes.",
  writeReason: 'Write a reason here',
  xpPoints: 'XP Points',
  yesAction: `Yes, {{action}}`,
  year_one: 'Year',
  year_other: 'Years',
}
