import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { MissionEventTypeEnum, MissionNextLevelTypeEnum } from '@/enums'
import {
  CatalogModels,
  CatalogThunks,
  MissionCommonModels,
  RootState,
  useAppDispatch,
} from '@/state'

type Props = {
  objectives: MissionCommonModels.ObjectiveMissionTypes[]
}

export const useMissionObjectives = ({ objectives }: Props) => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const { playerLocale } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const { categories, games, providers } = useSelector(
    ({ catalog }: RootState) => catalog,
  )

  const getPaymentMethods = (paymentMethods: string[]) => {
    return paymentMethods.length > 0
      ? ` - ${t('paymentMethods')}: ${paymentMethods.join(' ')}`
      : ''
  }

  const getAmounts = (
    amounts: { minimumAmount?: number; amount?: number; currency: string }[],
    text: string,
  ) => {
    return amounts.length > 0
      ? `- ${t(text)}: ${amounts
          .map(
            (item) => `${item?.minimumAmount || item?.amount} ${item.currency}`,
          )
          .join(', ')}`
      : ''
  }

  const getIteratorMap = (
    title: string,
    array: string[],
    id?: string,
    list?: CatalogModels.CatalogNames[],
  ) => {
    if (array?.length > 0) {
      if (list) {
        const iteratorText = array.map((item) => {
          return list?.find((listItem) => listItem[id] === item)?.name || ''
        })

        return `- ${t(title)}: ${iteratorText.join(', ')}`
      } else {
        return ` - ${t(title)}: ${array?.join(', ')}`
      }
    } else {
      return ''
    }
  }

  const getCasinoData = (
    objective: MissionCommonModels.ObjectiveMissionWagerAndWinType,
  ) => {
    return `${getIteratorMap('gameCategories', objective.gameCategories, 'categoryId', categories)} ${getIteratorMap('gameProviders', objective.gameProviders, 'providerId', providers)} ${getIteratorMap('games', objective.games, 'gameId', games)}`
  }

  const getSportsData = (
    objective: MissionCommonModels.ObjectiveMissionWagerAndWinType,
  ) => {
    return `${getIteratorMap('sports', objective.sport)} ${getIteratorMap('tournaments', objective.tournament)} ${getIteratorMap('teams', objective.team)} ${getIteratorMap('markets', objective.market)}`
  }

  const getObjectiveTypeMessage = (
    objective: MissionCommonModels.ObjectiveMissionTypes,
  ) => {
    let objectiveType
    let objectiveMessage = `${t(`missionEventType.${objective.missionEventType}`)} `

    switch (objective.missionEventType) {
      case MissionEventTypeEnum.ACCOUNT_VERIFICATION:
        objectiveType =
          objective as MissionCommonModels.ObjectiveMissionOptInAndAccountVerificationType
        objectiveMessage += `${t('channels')} - ${t('channelType.email')}: ${
          objectiveType.channelEmail
        } -  ${t('channelType.sms')}: ${objectiveType.channelSms}`
        break

      case MissionEventTypeEnum.COMPLETED_DEPOSIT:
      case MissionEventTypeEnum.COMPLETED_WITHDRAWAL:
        objectiveType =
          objective as MissionCommonModels.ObjectiveMissionCompletedTransactionType

        objectiveMessage += `${t(`missionConditionType.${objectiveType.conditionType}`)}: ${
          objectiveType.conditionValue
        } ${getPaymentMethods(objectiveType.paymentMethods)} ${getAmounts(
          objectiveType.minimumAmount,
          'minimumAmounts',
        )}`
        break

      case MissionEventTypeEnum.LEVEL_UP:
        objectiveType =
          objective as MissionCommonModels.ObjectiveMissionLevelUpType
        objectiveMessage += `- ${t(`missionLevelType.${objectiveType.nextLevelType}`)} ${
          objectiveType.nextLevelType !== MissionNextLevelTypeEnum.ANY
            ? `- ${t('value')}: ${objectiveType.nextLevelValue}`
            : ''
        }`
        break

      case MissionEventTypeEnum.OPT_IN:
        objectiveType =
          objective as MissionCommonModels.ObjectiveMissionOptInAndAccountVerificationType
        objectiveMessage += `${t('channels')} - ${t('channelType.email')}: ${
          objectiveType.channelEmail
        } - ${t('channelType.sms')}: ${objectiveType.channelSms} - ${t('channelType.phone')}: ${
          objectiveType.channelPhone
        } - ${t('channelType.post')}: ${objectiveType.channelPost}`
        break

      case MissionEventTypeEnum.REFER_A_FRIEND:
        objectiveType =
          objective as MissionCommonModels.ObjectiveMissionReferAFriendType
        objectiveMessage += `- ${t(`missionConditionType.${objectiveType.conditionType}`)}: ${
          objectiveType.conditionValue
        } - ${t('godsonAccountStatus')}: ${t(
          `godsonAccountStatusType.${objectiveType.godsonAccountStatusType.toUpperCase()}`,
        )}`
        break

      case MissionEventTypeEnum.WAGER:
      case MissionEventTypeEnum.WIN:
        objectiveType =
          objective as MissionCommonModels.ObjectiveMissionWagerAndWinType
        objectiveMessage += `- ${t(`missionConditionType.${objectiveType.conditionType}`)}: ${
          objectiveType.conditionValue
        } ${getAmounts(
          objectiveType.conditionAmount,
          'conditionAmounts',
        )} ${getAmounts(objectiveType.minimumAmount, 'minimumAmounts')} ${getCasinoData(objective as MissionCommonModels.ObjectiveMissionWagerAndWinType)}`
        break

      case MissionEventTypeEnum.SPORT_WAGER:
      case MissionEventTypeEnum.SPORT_WIN:
        objectiveType =
          objective as MissionCommonModels.ObjectiveMissionWagerAndWinType
        objectiveMessage += `- ${t(`missionConditionType.${objectiveType.conditionType}`)}: ${
          objectiveType.conditionValue
        } ${getAmounts(
          objectiveType.conditionAmount,
          'conditionAmounts',
        )} ${getAmounts(objectiveType.minimumAmount, 'minimumAmounts')} ${getSportsData(objective as MissionCommonModels.ObjectiveMissionWagerAndWinType)}`
        break

      default:
        // eslint-disable-next-line
        objectiveMessage
        break
    }

    return objectiveMessage
  }

  const getObjective = (
    objective: MissionCommonModels.ObjectiveMissionTypes,
  ) => {
    return (
      objective.translations?.find(
        (item) => item.language.toUpperCase() === playerLocale.toUpperCase(),
      )?.description || getObjectiveTypeMessage(objective)
    )
  }

  useEffect(() => {
    if (
      objectives.find(
        (objective) =>
          objective.missionEventType === MissionEventTypeEnum.WAGER ||
          objective.missionEventType === MissionEventTypeEnum.WIN,
      )
    ) {
      if (providers.length === 0) {
        dispatch(CatalogThunks.getProvidersNamesThunk())
      }
      if (categories.length === 0) {
        dispatch(CatalogThunks.getCategoriesNamesThunk())
      }

      const idOrName = []
      objectives.forEach((objective) => {
        const objectiveType =
          objective as MissionCommonModels.ObjectiveMissionWagerAndWinType
        objectiveType.games?.forEach((game) => {
          if (!idOrName.find((item) => item === game)) {
            idOrName.push(game)
          }
        })
      })
      dispatch(
        CatalogThunks.getGamesNamesThunk({
          page: 1,
          limit: 99999,
          order: 'asc',
          idOrName: idOrName.join(','),
        }),
      )
    }
  }, []) // eslint-disable-line

  return {
    getObjective,
  }
}
