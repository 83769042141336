import { useEffect, useRef, useState } from 'react'
import { gsap, Power1 } from 'gsap'
import { LottieRefCurrentProps } from 'lottie-react'

import { NotificationTypeEnum } from '@/enums'
import { useGlobalUi } from '@/hooks'
import { AssetsModels, PlayerModels } from '@/state'

type Props = {
  dataSize: number
  gamificationOpt: PlayerModels.GamificationOptType
  gamificationPlayer: PlayerModels.GamificationPlayerType
  images: AssetsModels.AssetsType
}

export const useAnimationAvatarActions = ({
  dataSize,
  gamificationOpt,
  gamificationPlayer,
  images,
}: Props) => {
  const [hiddenProgressBar, setHiddenProgressBar] = useState(false)
  const [showLevelUp, setShowLevelUp] = useState(false)
  const [showRankUp, setShowRankUp] = useState(false)
  const [avatarRankImage, setAvatarRankImage] = useState(
    gamificationPlayer?.rank?.imageUrl,
  )
  const { notificationSnackbar, modal } = useGlobalUi()

  const levelUpAvatarAnimationContainerRef = useRef<LottieRefCurrentProps>()

  const rankUpAnimationContainerRef = useRef<LottieRefCurrentProps>()

  const avatarRef = useRef<HTMLDivElement>(null)

  const getDataSize = () => {
    switch (true) {
      case dataSize < 110:
        return {
          size: 50,
          avatarLevelSize: {
            size: 96,
            top: -17,
            left: -22,
          },
          avatarRankSize: { size: 105, top: -26, left: -23 },
        }
      case dataSize >= 140:
        return {
          size: 140,
          avatarLevelSize: {
            size: 270,
            top: -48,
            left: -62,
          },
          avatarRankSize: { size: 293, top: -72, left: -65.5 },
        }
      default:
        return {
          size: 110,
          avatarLevelSize: {
            size: 211,
            top: -37,
            left: -48,
          },
          avatarRankSize: { size: 237, top: -59.5, left: -55 },
        }
    }
  }

  const rankUpAnimation = () => {
    setShowRankUp(true)
  }

  const levelUpAvatarAnimation = () => {
    setShowLevelUp(true)
  }

  const playAnimation = () => {
    if (modal.type === NotificationTypeEnum.LEVEL_UP) {
      levelUpAvatarAnimation()
    }

    if (modal.type === NotificationTypeEnum.RANK_UP) {
      rankUpAnimation()
    }
  }

  useEffect(() => {
    if (levelUpAvatarAnimationContainerRef.current && showLevelUp) {
      levelUpAvatarAnimationContainerRef.current.play()

      setTimeout(() => {
        setShowLevelUp(false)
      }, 2000)
      return
    }
  }, [showLevelUp])

  useEffect(() => {
    if (rankUpAnimationContainerRef.current && showRankUp) {
      rankUpAnimationContainerRef.current.play()

      setHiddenProgressBar(true)

      const avatar = avatarRef.current
      const tl = gsap.timeline()
      tl.to(avatar, { y: '7.25rem', duration: 0.5, ease: Power1.easeInOut })
        .call(
          () =>
            gamificationPlayer?.rank?.imageUrl !== avatarRankImage &&
            setAvatarRankImage(gamificationPlayer?.rank?.imageUrl),
        )
        .to(avatar, { duration: 0.5, delay: 1.25 })
        .to(avatar, { y: '0 ', duration: 0.5, ease: Power1.easeInOut })

      setTimeout(() => {
        setHiddenProgressBar(false)
        setShowRankUp(false)
      }, 2500)
    }
  }, [showRankUp]) // eslint-disable-line

  useEffect(() => {
    if (notificationSnackbar.open) {
      playAnimation()
    }
  }, [notificationSnackbar, modal.type]) // eslint-disable-line

  useEffect(() => {
    const avatarImage =
      gamificationPlayer.rank.imageUrl &&
      gamificationPlayer.rank.imageUrl !== '' &&
      gamificationOpt?.enable
        ? gamificationPlayer.rank.imageUrl
        : images?.avatar_image

    setAvatarRankImage(avatarImage)
  }, [gamificationPlayer]) // eslint-disable-line

  return {
    levelUpAvatarAnimationContainerRef,
    rankUpAnimationContainerRef,
    avatarRef,
    hiddenProgressBar,
    avatarRankImage,
    getDataSize,
    showLevelUp,
    showRankUp,
  }
}
