import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { InitConfigModels } from './'

const initialState: InitConfigModels.InitConfigState = {
  clientId: '',
  playerIdentityToken: '',
  playerLocale: 'en',
  serviceUrl: '',
  playerId: '',
  client: '',
  baseUrl: '',
  isErrorToken: false,
}

export const initConfigSlice = createSlice({
  name: 'initConfig',
  initialState,
  reducers: {
    setInitConfig(
      state,
      action: PayloadAction<InitConfigModels.InitConfigType>,
    ) {
      state.clientId = action.payload.clientId
      state.playerIdentityToken = action.payload.playerIdentityToken
      state.playerLocale = action.payload.playerLocale || state.playerLocale
      state.serviceUrl = action.payload.serviceUrl.replace(/\/+$/, '')
      state.client = action.payload.client || ''
    },
    setPlayerId(state, action: PayloadAction<string>) {
      state.playerId = action.payload
    },
    setPlayerLocale(state, action: PayloadAction<string>) {
      state.playerLocale = action.payload || state.playerLocale
    },
    setBaseUrl(state, action: PayloadAction<string>) {
      state.baseUrl = action.payload
    },
    setIsErrorToken(state, action: PayloadAction<boolean>) {
      state.isErrorToken = action.payload
    },
  },
})

export const InitConfigActions = initConfigSlice.actions
