import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { RootState } from '@/state'

type Props = {
  currentBreakAnimation: number
  imageClass: string
}

export function BreakingBoosterAnimationComponent({
  currentBreakAnimation,
  imageClass,
}: Props) {
  const { baseUrl } = useSelector(({ initConfig }: RootState) => initConfig)

  const spritePositions = {
    0: '1px 1px',
    1: '-30px 1px',
    2: '-61px 1px',
    3: '-92px 1px',
    4: '1px -30px',
    5: '-30px -30px',
    6: '-61px -30px',
    7: '1px -61px',
    8: '-30px -61px',
    9: '-61px -61px',
  }

  const spriteUrl = `${baseUrl}assets/animations/booster/booster-breaking-sprite.png`
  const position = spritePositions[currentBreakAnimation]

  return (
    <Box
      sx={{
        width: 32,
        height: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          width: 32,
          height: 32,
          background: `url(${spriteUrl}) ${position} no-repeat`,
          backgroundSize: '124px 93px',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        className={imageClass}
      />
    </Box>
  )
}
