import { RewardTypeEnum } from '@gamanza-engage/mf-enums'
import { ReactComponent as IconTokenBooster } from '@/assets/icons/gamification/icon-token-booster.svg'
import { ReactComponent as IconLevelBooster } from '@/assets/icons/gamification/icon-level-booster.svg'

import { ReactComponent as IconMissionBooster } from '@/assets/icons/missions/icon-mission-booster.svg'
import { ReactComponent as IconBonus } from '@/assets/icons/common/icon-bonus.svg'
import { IconComponent } from '@/components'

import { IconTypeEnum } from '@/enums'

export const RewardTypeInformation = () => ({
  [RewardTypeEnum.TOKENS]: {
    id: RewardTypeEnum.TOKENS,
    label: 'coins',
    icon: <IconComponent type={IconTypeEnum.TOKEN} />,
  },
  [RewardTypeEnum.BONUS_OFFER]: {
    id: RewardTypeEnum.BONUS_OFFER,
    label: 'bonusOffer',
    icon: <IconBonus className="paintIcon" />,
  },
  [RewardTypeEnum.LEVEL_BOOSTER]: {
    id: RewardTypeEnum.LEVEL_BOOSTER,
    label: 'levelBooster',
    icon: <IconLevelBooster />,
  },
  [RewardTypeEnum.MISSION_BOOSTER]: {
    id: RewardTypeEnum.MISSION_BOOSTER,
    label: 'missionBooster',
    icon: <IconMissionBooster />,
  },
  [RewardTypeEnum.TOKEN_BOOSTER]: {
    id: RewardTypeEnum.TOKEN_BOOSTER,
    label: 'tokenBooster',
    icon: <IconTokenBooster />,
  },
  [RewardTypeEnum.XP]: {
    id: RewardTypeEnum.XP,
    label: 'xpPoints',
    icon: <IconComponent type={IconTypeEnum.XP} />,
  },
})
