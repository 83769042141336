import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Lottie from 'lottie-react'

import { Box, Avatar, CircularProgress, SxProps, Theme } from '@mui/material'
import { AccountCircle } from '@mui/icons-material'
import { RootState } from '@/state'

import { getAssetsUrl, getLottieAnimationData } from '@/utils'
import { useAnimationAvatarActions } from '@/components/profile/hooks'
import { useStyles } from './style'

import { BadgeLevelComponent } from '../badge-level'

type Props = {
  Div: HTMLElement
  sx?: SxProps<Theme>
  removeAnimation?: boolean
  removeProgressBar?: boolean
  progressColor?: string
}

export function AvatarComponent({
  Div,
  sx,
  removeAnimation = false,
  removeProgressBar = false,
  progressColor,
}: Props) {
  const { images } = useSelector(({ assets }: RootState) => assets)

  const { baseUrl } = useSelector(({ initConfig }: RootState) => initConfig)

  const { gamificationPlayer, gamificationOpt } = useSelector(
    ({ player }: RootState) => player,
  )

  const [animationDataLevelUp, setAnimationDataLevelUp] = useState(null)

  const [animationDataRankUp, setAnimationDataRankUp] = useState(null)

  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const src = client === 'JP' ? 'jackpots' : 'standard'

  const avatarLevelUpUrl = getAssetsUrl(
    baseUrl,
    `assets/animations/avatar/${src}/avatar-level-up.lottie`,
  )
  const avatarRankUpUrl = getAssetsUrl(
    baseUrl,
    `assets/animations/avatar/${src}/avatar-rank-up.lottie`,
  )

  // Get Data Attributes
  const dataSize =
    Div.getAttribute('data-size') !== undefined
      ? Number(Div.getAttribute('data-size'))
      : 110
  const progressType = Div.getAttribute('data-progress-type') || 'rank'
  const showLevel = Div.getAttribute('data-show-level') || 'false'

  const {
    getDataSize,
    levelUpAvatarAnimationContainerRef,
    rankUpAnimationContainerRef,
    avatarRef,
    avatarRankImage,
    hiddenProgressBar,
    showLevelUp,
    showRankUp,
  } = useAnimationAvatarActions({
    dataSize,
    gamificationOpt,
    gamificationPlayer,
    images,
  })

  const dimensionsData = getDataSize()
  const transform = (dimensionsData.size || 1) / 120

  const { classes } = useStyles({
    transform,
    progressColor,
  })

  useEffect(() => {
    getLottieAnimationData(avatarLevelUpUrl, setAnimationDataLevelUp)
    getLottieAnimationData(avatarRankUpUrl, setAnimationDataRankUp)
  }, []) // eslint-disable-line

  return (
    <Box
      width={dimensionsData.size}
      height={dimensionsData.size}
      sx={{ ...sx, position: 'relative' }}
      className={classes.avatar}
    >
      <Box className={classes.avatarContent}>
        <Avatar
          ref={avatarRef}
          className={`avatar ${!gamificationOpt?.enable && 'disableAvatar'}`}
          alt="Avatar"
          src={avatarRankImage}
        >
          <AccountCircle className={classes.accountCircle} />
        </Avatar>
        <CircularProgress
          className="circularTransparent"
          variant="determinate"
          value={100}
          size={120}
          sx={{
            position: gamificationOpt?.enable ? 'absolute' : 'relative',
            visibility: removeProgressBar ? 'hidden' : 'visible',
            opacity: removeProgressBar ? 0 : 1,
          }}
        />
        {gamificationOpt?.enable && (
          <CircularProgress
            id="progressBar"
            className="circular"
            variant="determinate"
            value={
              progressType === 'rank'
                ? gamificationPlayer.rank.progress
                : gamificationPlayer.level.progress
            }
            size={120}
            sx={{
              overflow: 'hidden',
              visibility:
                hiddenProgressBar || removeProgressBar ? 'hidden' : 'visible',
              opacity: hiddenProgressBar || removeProgressBar ? 0 : 1,
              transition: 'visibility 0s, opacity 0.3s ease',
            }}
          />
        )}
      </Box>

      {!removeAnimation && (
        <>
          {showLevelUp && (
            <Lottie
              animationData={animationDataLevelUp}
              loop={false}
              lottieRef={levelUpAvatarAnimationContainerRef}
              style={{
                left: dimensionsData.avatarLevelSize.left,
                top: dimensionsData.avatarLevelSize.top,
                position: 'absolute',
                width: dimensionsData.avatarLevelSize.size,
                height: dimensionsData.avatarLevelSize.size,
              }}
            />
          )}

          {showRankUp && (
            <Lottie
              animationData={animationDataRankUp}
              loop={false}
              lottieRef={rankUpAnimationContainerRef}
              style={{
                left: dimensionsData.avatarRankSize.left,
                top: dimensionsData.avatarRankSize.top,
                position: 'absolute',
                width: dimensionsData.avatarRankSize.size,
                height: dimensionsData.avatarRankSize.size,
              }}
              rendererSettings={{
                progressiveLoad: false,
                preserveAspectRatio: 'xMidYMid slice',
              }}
            />
          )}
        </>
      )}
      {showLevel === 'true' && gamificationOpt?.enable && (
        <Box
          position="absolute"
          bottom={-14}
          left="50%"
          sx={{ transform: 'translateX(-50%)' }}
        >
          <BadgeLevelComponent
            Div={Div}
            removeAnimation={removeAnimation}
            dataSize={dataSize}
          />
        </Box>
      )}
    </Box>
  )
}
