import { Box } from '@mui/material'
import { CarouselDirectionArrowsComponent } from '@/components/common'
import { useSliderItemsStyles } from './style'

import { useSliderItems } from '../hooks'

type Props = {
  content: React.ReactNode
  amountItems: number
  itemsByBlock: number
  expanded?: boolean
  mobileWidth?: number
  gap?: number
}

export const SliderItemsComponent = ({
  content,
  amountItems,
  itemsByBlock,
  expanded,
  mobileWidth,
  gap,
}: Props) => {
  const { classes } = useSliderItemsStyles({
    expanded,
    mobileWidth,
    gap,
  })

  const {
    sliderRef,
    showArrowLeft,
    showArrowRight,
    numberBullets,
    currentBullet,
    handleScrollArrows,
    handleScrollBullet,
  } = useSliderItems(amountItems, itemsByBlock)

  const bullets = Array.from(new Array(numberBullets), (_, index) => (
    <Box
      className={`sliderItemsBullet ${index === currentBullet ? 'active' : ''}`}
      key={`bullet_${index}`}
      onClick={() => {
        handleScrollBullet(index)
      }}
    />
  ))

  return (
    <>
      <Box className={classes.sliderItemsWrapper}>
        <Box className={classes.sliderItemsContent} ref={sliderRef}>
          {content}
          {expanded && (
            <CarouselDirectionArrowsComponent
              showArrowLeft={showArrowLeft}
              showArrowRight={showArrowRight}
              handleNext={() => handleScrollArrows(false)}
              handleBack={() => handleScrollArrows(true)}
              arrowType="arrow"
              sxProps={{
                right: {
                  '&.right': {
                    right: -12,
                  },
                },
                left: {
                  '&.left': {
                    left: -12,
                  },
                },
              }}
            />
          )}
        </Box>
        {expanded && amountItems > itemsByBlock && (
          <Box className={classes.sliderItemsBullets}>{bullets}</Box>
        )}
      </Box>
    </>
  )
}
