import { Grid } from '@mui/material'
import { MissionCommonModels } from '@/state'
import { MissionBundleItemComponent } from '@/components/common'

import { VariantsComponentsEnum } from '@/enums'
import { useBreakpoints } from '@/hooks'

type Props = {
  missionBundles: MissionCommonModels.MissionHistoryBundleDetailsType[]
  showEndedMissions: boolean
  isMissionWidget?: boolean
  handleSetFullContent?: (full: boolean) => void
  handleSetSelectedBundle: (
    bundle: MissionCommonModels.MissionHistoryBundleDetailsType,
  ) => void
}

export const MissionBundlesStandardComponent = ({
  missionBundles,
  showEndedMissions,
  isMissionWidget,
  handleSetFullContent,
  handleSetSelectedBundle,
}: Props) => {
  const { isMobile } = useBreakpoints()

  return (
    <Grid container spacing={2}>
      {missionBundles?.map((bundle) => {
        return (
          <Grid
            item
            xs={12}
            sm={isMissionWidget ? 6 : 12}
            md={isMissionWidget ? 4 : 12}
            mb={1}
            key={bundle._id}
            width="100%"
          >
            <MissionBundleItemComponent
              missionBundle={bundle}
              variant={
                isMissionWidget && !isMobile
                  ? VariantsComponentsEnum.LARGE
                  : VariantsComponentsEnum.SMALL
              }
              showProgressBar
              openMissions={() => {
                !!handleSetFullContent && handleSetFullContent(true)
                handleSetSelectedBundle(bundle)
              }}
              showEndedMissions={showEndedMissions}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}
