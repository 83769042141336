import { RewardTriggerTypeEnum } from '@/enums'

export const ChestByTrigger = {
  [RewardTriggerTypeEnum.EASTER_EGGS]: {
    icon: 'assets/icons/gamification/icon-easter-egg-clover.png',
    label: 'find',
  },
  [RewardTriggerTypeEnum.LEVEL]: {
    icon: 'assets/icons/gamification/icon-level-chest-closed.png',
    label: 'open',
  },
  [RewardTriggerTypeEnum.RANK]: {
    icon: 'assets/icons/gamification/icon-rank-chest-closed.png',
    label: 'open',
  },
  [RewardTriggerTypeEnum.MISSION]: {
    icon: 'assets/icons/missions/icon-mission-chest-closed.png',
    label: 'open',
  },
}
