import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  useTheme,
  Checkbox,
  FormControlLabel,
  IconButton,
  CircularProgress,
  Box,
} from '@mui/material'

import { Close } from '@mui/icons-material'
import { useGlobalUi } from '@/hooks'
import { UIDialogsEnum } from '@/enums'

import { useGamificationMechanicsModal } from '../hooks'
import { MechanicsWarningComponent, MechanicsOptOutFormComponent } from './'
import { useStyles } from './style'

type Props = {
  Div: HTMLElement
}

export const MechanicsModalComponent = ({ Div }: Props) => {
  const { t } = useTranslation()

  const theme = useTheme()

  // Get Data Attributes
  const showWarning = Div.getAttribute('data-show-warning') || 'false' // Optional: Prop to show th warning message in Opt Out case

  const [confirm, setConfirm] = useState(false)

  const { dialog, handleCloseDialog } = useGlobalUi()

  const {
    formValues,
    isLoading,
    gamificationOpt,
    gamificationReasons,
    handleOnChange,
    handleSubmit,
  } = useGamificationMechanicsModal()

  const { classes } = useStyles()

  const dialogContainerRef = useRef(null)

  return (
    <Box ref={dialogContainerRef}>
      <Dialog
        fullWidth
        open={dialog.open && dialog.id === UIDialogsEnum.MECHANICS_FORM}
        maxWidth="sm"
        className={classes.dialog}
        container={dialogContainerRef.current}
      >
        <DialogTitle className={classes.title}>
          <Typography variant="h6" fontWeight={700} component="span">
            {gamificationOpt?.enable
              ? t('mechanicsModalTitleOptOut')
              : t('mechanicsModalTitleOptIn')}
          </Typography>
          <IconButton
            size="small"
            aria-label="close"
            onClick={handleCloseDialog}
          >
            <Close fontSize="inherit" />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Typography gutterBottom className="subtitle">
            {gamificationOpt?.enable
              ? t('mechanicsModalSubtitleOptOut')
              : t('mechanicsModalSubtitleOptIn')}
          </Typography>

          {gamificationOpt?.enable && (
            <MechanicsOptOutFormComponent
              formValues={formValues}
              handleOnChange={handleOnChange}
              reasons={gamificationReasons}
            />
          )}

          {showWarning === 'true' && gamificationOpt?.enable && (
            <MechanicsWarningComponent />
          )}

          <FormControlLabel
            label={
              gamificationOpt?.enable
                ? t('mechanicsModalConfirmOptOut')
                : t('mechanicsModalConfirmOptIn')
            }
            checked={confirm}
            onChange={() => setConfirm(!confirm)}
            control={<Checkbox />}
            className={classes.confirm}
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            onClick={() => {
              handleCloseDialog()
            }}
            variant="text"
            sx={{ color: theme.palette.text.primary, mr: 4 }}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            disabled={
              !confirm ||
              (gamificationOpt?.enable &&
                ((formValues.reason === 'other' && formValues.comment === '') ||
                  formValues.reason === ''))
            }
            onClick={() => {
              handleSubmit()
              setConfirm(false)
            }}
            type="submit"
            endIcon={isLoading && <CircularProgress />}
          >
            {gamificationOpt?.enable
              ? t('mechanicsModalCTAOptOut')
              : t('mechanicsModalCTAOptIn')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
