import { makeStyles } from 'tss-react/mui'
import { alertClasses } from '@mui/material'

export const useStyles = makeStyles()((theme) => {
  return {
    alertGroup: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',

      [`& .${alertClasses.action}`]: {
        padding: '7px 0 0 16px',
      },
    },
    alertContainer: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      fontWeight: 700,
      width: '100%',
      display: 'flex',
      '& .GamanzaAlert-message': {
        width: '100%',
        padding: 0,
      },
    },

    alertIconWrapper: {
      position: 'absolute',
      display: 'flex',
      zIndex: 100,
      left: 0,
      width: 65,
      height: 65,
      [theme.breakpoints.up('md')]: {
        left: -50,
        width: 100,
        height: 100,
      },

      '& svg g path': {
        fill: theme.palette.primary.main,
      },
    },
    alertIcon: {
      width: 65,
      height: 65,
      [theme.breakpoints.up('md')]: {
        width: 100,
        height: 100,
      },
    },
  }
})
