import { RewardTypeEnum } from '@gamanza-engage/mf-enums'
import { ActiveBoosterType } from '@/state/my-booster/models'
import { ActiveBoostersColors } from '@/constants'

type Props = {
  Div: HTMLElement
  activeBoosters: ActiveBoosterType[]
}

export const useHideBoostersItem = ({ Div, activeBoosters }: Props) => {
  const boosterColors = {
    [RewardTypeEnum.LEVEL_BOOSTER]:
      Div.getAttribute('data-level-base') ||
      ActiveBoostersColors[RewardTypeEnum.LEVEL_BOOSTER].baseColor,
    [RewardTypeEnum.TOKEN_BOOSTER]:
      Div.getAttribute('data-token-base') ||
      ActiveBoostersColors[RewardTypeEnum.TOKEN_BOOSTER].baseColor,
    [RewardTypeEnum.MISSION_BOOSTER]:
      Div.getAttribute('data-mission-base') ||
      ActiveBoostersColors[RewardTypeEnum.MISSION_BOOSTER].baseColor,
  }

  const countByType = activeBoosters.reduce((count, item) => {
    if (!count[item.boosterType]) {
      count[item.boosterType] = 1
    } else {
      count[item.boosterType]++
    }
    return count
  }, {})

  const percentagesByBoosterType = Object.keys(countByType).map(
    (boosterType) => ({
      color: boosterColors[boosterType],
      percentage: (countByType[boosterType] / activeBoosters.length) * 100,
    }),
  )

  const DEFAULT_POSITION = 90

  return {
    DEFAULT_POSITION,
    percentagesByBoosterType,
  }
}
