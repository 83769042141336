import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RewardShopTypeEnum } from '@gamanza-engage/mf-enums'
import { MenuItemType, RewardShopViewTypeEnum } from '@/enums'

import { PaginationType } from '@/state'
import {
  getAllProductsExtraReducer,
  getOneProductExtraReducer,
  orderProductExtraReducer,
} from './'
import { RewardShopModels } from '../'

const initialState: RewardShopModels.RewardShopState = {
  externalProducts: null,
  boosterProducts: null,
  bonusProducts: null,
  xpProducts: null,
  sidebarProducts: null,
  currentProduct: null,
  rewardType: '',
  pagination: null,
  paginationSidebarProducts: null,
  rewardShopPage: RewardShopViewTypeEnum.PRODUCTS,
  menuSelected: MenuItemType.REWARD_SHOP,
  isLoadingOrder: false,
  orderProduct: null,
  isLoading: false,
  isLoadingReward: false,
  isLoadingSidebarProducts: false,
  isError: false,
}

export const rewardShopSlice = createSlice({
  name: 'rewardShop',
  initialState,
  reducers: {
    setMenuSelected(state, action: PayloadAction<string>) {
      state.menuSelected = action.payload
    },
    setRewardType(state, action: PayloadAction<RewardShopTypeEnum>) {
      state.rewardShopPage = RewardShopViewTypeEnum.CATEGORY
      state.rewardType = action.payload
    },
    setRewardShopPage(state, action: PayloadAction<RewardShopViewTypeEnum>) {
      state.rewardShopPage = action.payload
    },
    setPagination(state, action: PayloadAction<PaginationType>) {
      state.pagination = action.payload
    },
    resetRewardShopPage(state) {
      state.rewardShopPage = initialState.rewardShopPage
      state.rewardType = initialState.rewardType
      state.currentProduct = initialState.currentProduct
      state.orderProduct = initialState.orderProduct
    },
    resetOrderProduct(state) {
      state.orderProduct = initialState.orderProduct
    },
  },
  extraReducers: (builder) => {
    getAllProductsExtraReducer(builder)
    getOneProductExtraReducer(builder)
    orderProductExtraReducer(builder)
  },
})

export const RewardShopActions = rewardShopSlice.actions
