import { t } from 'i18next'
import { RewardTypeEnum } from '@gamanza-engage/mf-enums'
import { GeneralModels } from '@/state'

import { boostersInfo } from '@/constants'
import { convertTimeFrame } from './date.util'

export const rewardDescription = (
  reward: GeneralModels.RewardCategoryType,
  playerLocale: string,
) => {
  const translation =
    reward?.translations?.find(
      (item) => item.language.toUpperCase() === playerLocale.toUpperCase(),
    )?.description || ''

  switch (reward.rewardType) {
    case RewardTypeEnum.BONUS_OFFER:
      return `${
        translation !== '' ? translation : `${t('id')}: ${reward.bonusId}`
      }`

    case RewardTypeEnum.TOKENS:
      return reward.tokens || ''

    case RewardTypeEnum.XP:
      return reward.amount

    default:
      const { boosterRate } = reward as GeneralModels.BoosterCategoryType

      return `${boosterRate}X`
  }
}

export const rewardBoosterTime = (timeFrame: string, unit?: boolean) => {
  const timeFrameItem = convertTimeFrame(timeFrame)

  const getTimeUnitText = (timeFrameItemUnit: string) => {
    if (timeFrameItemUnit === 'M') {
      return 'Mth'
    }
    if (timeFrameItemUnit === 'm') {
      return unit ? 'M' : 'Min'
    }
    return timeFrameItemUnit
  }

  const timeFrameUnitText = getTimeUnitText(timeFrameItem.unit)

  return `${timeFrameItem.time}${unit ? timeFrameUnitText : ` ${t(`durationType.${timeFrameUnitText}`)}`}`
}

export const rewardBoosterRate = (
  rewardType: string,
  boosterRate: number,
  timeFrame: string,
) => {
  if (
    rewardType === RewardTypeEnum.LEVEL_BOOSTER ||
    rewardType === RewardTypeEnum.MISSION_BOOSTER ||
    rewardType === RewardTypeEnum.TOKEN_BOOSTER
  ) {
    const title = t(boostersInfo[rewardType].title).toLowerCase()
    const time = rewardBoosterTime(timeFrame).toLowerCase()

    return `${boosterRate}X ${title} ${t('by')} ${time}`
  }
}
