import { useTranslation } from 'react-i18next'
import { useGlobalUi } from '@/hooks'
import { ChestTypeEnum, UIDialogsEnum } from '@/enums'

import { DialogComponent } from '@/components'
import { RewardsAnimationComponent } from './rewards'

export const DialogChestAnimationComponent = () => {
  const { t } = useTranslation()

  const { dialog } = useGlobalUi()

  const getTitle = () => {
    if (dialog.object?.chestType === ChestTypeEnum.MISSION) {
      return t('missionReward')
    }
    if (dialog.object?.chestType === ChestTypeEnum.LEVEL) {
      return t('levelReward')
    }
    return t('rankReward')
  }

  return (
    <DialogComponent
      dialogId={UIDialogsEnum.CHEST_REWARDS}
      title={getTitle().toUpperCase()}
      content={<RewardsAnimationComponent />}
      titleSxProps={{
        textAlign: 'center',
      }}
      contentSxProps={{ overflow: 'hidden' }}
    />
  )
}
