import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RewardStatusEnum, TimeFrameEnum } from '@gamanza-engage/mf-enums'

import { Box, Button, Chip, Typography, useTheme } from '@mui/material'
import { AccessTime, ArrowForwardIos, Today } from '@mui/icons-material'
import {
  ProgressBarComponent,
  ClaimChestItemComponent,
} from '@/components/common'

import { MissionCommonModels, RootState } from '@/state'
import { MissionColors } from '@/constants'
import { ChestTypeEnum, VariantsComponentsEnum } from '@/enums'

import { useRemainingTime } from '@/hooks'
import { formatDateInUTC } from '@/utils'
import { useMissionBundleItemStyles } from './style'

type Props = {
  missionBundle: MissionCommonModels.MissionHistoryBundleDetailsType
  variant?: VariantsComponentsEnum
  showProgressBar?: boolean
  showEndedMissions?: boolean
  noBorderBottom?: boolean
  borderRadius?: number
  openMissions?: () => void
}

export const MissionBundleItemComponent = ({
  missionBundle,
  variant = VariantsComponentsEnum.LARGE,
  showProgressBar = false,
  showEndedMissions = false,
  noBorderBottom = false,
  borderRadius,
  openMissions,
}: Props) => {
  const { t } = useTranslation()

  const { classes, cx } = useMissionBundleItemStyles({ variant, borderRadius })

  const { baseUrl, playerLocale } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const { timeRemaining } = useRemainingTime({
    endDate: missionBundle.endDate,
  })

  const theme = useTheme()

  const listMissionsUnclaimed = missionBundle.missionIds.filter(
    (mission) =>
      mission?.rewardClaimStatus?.status === RewardStatusEnum.UNCLAIMED &&
      mission?.rewardCategories?.filter(
        (category) => category.status === RewardStatusEnum.CLAIMED,
      ).length > 0,
  )

  const showClaimChest = listMissionsUnclaimed.length > 0

  const getStarDateByTimeframe = () => {
    const timeFrameType = missionBundle.settings.timeFrame.timeFrameType
    if (
      (timeFrameType === TimeFrameEnum.DAILY ||
        timeFrameType === TimeFrameEnum.MONTHLY) &&
      missionBundle.settings.timeFrame.date
    ) {
      return formatDateInUTC(
        missionBundle.settings.timeFrame.date,
        timeFrameType === TimeFrameEnum.DAILY ? 'dd MMM' : 'MMMM',
      )
    }

    if (
      (timeFrameType === TimeFrameEnum.WEEKLY ||
        timeFrameType === TimeFrameEnum.RANGE) &&
      missionBundle.settings.timeFrame.startDate &&
      missionBundle.settings.timeFrame.endDate
    ) {
      const startDate = formatDateInUTC(
        missionBundle.settings.timeFrame.startDate,
        'dd MMM',
      )

      const endDate = formatDateInUTC(
        missionBundle.settings.timeFrame.endDate,
        'dd MMM',
      )

      return `${startDate} - ${endDate}`
    }
  }

  const missionBundleName =
    missionBundle.externalDetails?.find(
      (item) => item.language.toUpperCase() === playerLocale.toUpperCase(),
    )?.name || missionBundle.internalDetails.name

  const missionBundleThumbnail =
    missionBundle.smallImage || `${baseUrl}assets/images/image.svg`

  const showTimeRemaining =
    !showEndedMissions ||
    (showEndedMissions &&
      missionBundle.settings.timeFrame.timeFrameType !== TimeFrameEnum.LIFETIME)

  return (
    <Box
      className={`${classes.missionBundleItemWrapper} ${cx({
        noBorderBottom: noBorderBottom,
      })}`}
    >
      <img
        className="bundleImage"
        src={missionBundleThumbnail}
        alt="Mission Bundle Preview"
      />
      <Box className="bundleChip bundleTimeframeType">
        <Chip
          label={t(
            `timeframeType.${missionBundle.settings.timeFrame.timeFrameType}`,
          )}
          variant="filled"
          size="small"
        />
      </Box>
      {showTimeRemaining && (
        <Box className="bundleChip bundleTimeRemaining">
          <Chip
            icon={showEndedMissions ? <Today /> : <AccessTime />}
            label={showEndedMissions ? getStarDateByTimeframe() : timeRemaining}
            variant="filled"
            size="small"
          />
        </Box>
      )}
      {(showClaimChest || showProgressBar) && (
        <Box className="bundleItemFooter">
          {showClaimChest && (
            <Box className="bundleClaimChest">
              <ClaimChestItemComponent
                bundleId={listMissionsUnclaimed[0].missionBundleId}
                missionId={listMissionsUnclaimed[0]._id}
                rewards={
                  listMissionsUnclaimed[0].rewardCategories?.filter(
                    (category) => category.status === RewardStatusEnum.CLAIMED,
                  )[0]?.rewards || []
                }
                amountChest={listMissionsUnclaimed.length}
                variant={variant}
                chestType={ChestTypeEnum.MISSION}
              />
            </Box>
          )}
          {showProgressBar && (
            <Box className="bundleProgressAndMissions">
              <Box className="progress">
                <Typography variant="body2">{missionBundleName}</Typography>
                <ProgressBarComponent
                  progressColor={
                    showEndedMissions
                      ? MissionColors.STATUS.ENDED.content
                      : theme.palette.primary.main
                  }
                  progressValue={missionBundle.state.progress}
                  amountLinearBars={1}
                  balanceText={
                    missionBundle.state.progress === 100
                      ? t('completed').toUpperCase()
                      : `${missionBundle.state.completed}/${missionBundle.state.total}`
                  }
                  balanceIcon={
                    missionBundle.state.progress === 100 && (
                      <img
                        src={`${baseUrl}assets/icons/common/icon-check-white.svg`}
                        alt="Icon Check"
                        width={16}
                      />
                    )
                  }
                  heightProgress={
                    variant === VariantsComponentsEnum.SMALL ? 16 : 20
                  }
                />
              </Box>
              <Button
                className="seeMissions"
                variant="outlined"
                onClick={() => {
                  openMissions && openMissions()
                }}
              >
                <Typography variant="caption">{t('seeMore')}</Typography>
                <ArrowForwardIos />
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}
