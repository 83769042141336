import { useTranslation } from 'react-i18next'
import { Box, Button, buttonClasses, Typography, useTheme } from '@mui/material'
import { DialogComponent } from '@/components/common'

import { useMyBoosters } from '@/components/my-boosters'
import { useBreakpoints, useGlobalUi } from '@/hooks'
import { UIDialogsEnum } from '@/enums'

import { RewardTypeInformation } from '@/constants'
import { rewardBoosterRate } from '@/utils'

type Props = {
  dense: boolean
}

export function DialogActivateBoosterComponent({ dense }: Props) {
  const { t } = useTranslation()

  const { handleActivateBooster, activatingBooster, isActiveAnimation } =
    useMyBoosters({ dense })

  const { dialog, handleWaitCloseDialog } = useGlobalUi()

  const theme = useTheme()

  const { isXsMobile } = useBreakpoints()

  const boosterTypeInfo = RewardTypeInformation()

  const booster = dialog.object?.booster

  const { type, boosterRate, timeFrame } = booster?.earnedReward

  const dialogContent = (
    <Box
      display="flex"
      justifyContent="center"
      textAlign="center"
      flexDirection="column"
      pt={3}
      pb={1}
      px={1}
    >
      <Box
        sx={{
          '& svg': {
            width: 80,
            height: 80,
          },
        }}
      >
        {boosterTypeInfo[type].icon}
      </Box>
      <Box py={2}>
        <Typography fontSize={20} fontWeight={700}>
          {t(`${boosterTypeInfo[type].label}`)}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2" component="p">
          {`${rewardBoosterRate(type, boosterRate, timeFrame)}`}
        </Typography>
        <Typography variant="body2" component="p">
          {t('confirmActivateBooster')}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        mt={3}
        width="100%"
        gap={2}
        sx={{
          [`& .${buttonClasses.root}`]: {
            width: isXsMobile ? 'auto' : '50%',
            maxWidth: 190,
            fontSize: 15,
            textTransform: 'uppercase',
            textWrap: 'nowrap',
          },
        }}
      >
        <Button
          variant="text"
          onClick={handleWaitCloseDialog}
          sx={{ color: theme.palette.error.main }}
        >
          {t('cancel')}
        </Button>
        <Button
          disabled={!!activatingBooster || !!isActiveAnimation}
          onClick={() => handleActivateBooster(booster?.id)}
          variant="contained"
        >
          {t('yesAction', { action: t('activate') })}
        </Button>
      </Box>
    </Box>
  )

  return (
    <>
      {booster && (
        <DialogComponent
          maxWidth={443}
          dialogId={UIDialogsEnum.ACTIVATE_BOOSTER}
          headerBorder={false}
          content={dialogContent}
        />
      )}
    </>
  )
}
