import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, useTheme } from '@mui/material'

import { RootState } from '@/state'
import { getFormatNumber } from '@/utils'
import { useBreakpoints, usePlayerData } from '@/hooks'

import { IconComponent, PointCardComponent } from '@/components/common'
import { TokensProfileAnimationComponent } from '@/components/profile'
import { IconTypeEnum } from '@/enums'

import { usePointsStyles } from './points.style'

type Props = {
  openSidebar: boolean
  sidebarClient: string
}

export function SidebarPointsComponent({ openSidebar, sidebarClient }: Props) {
  const { t } = useTranslation()
  const { gamificationPlayer } = useSelector(({ player }: RootState) => player)

  const { playerLocale } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const theme = useTheme()

  const { playerRankName } = usePlayerData()

  const { isMobile } = useBreakpoints()

  const iconSize = openSidebar ? 31 : 24

  const tokenJPIconSize = openSidebar ? 25 : 22

  const { classes } = usePointsStyles({
    imageIconSize: iconSize,
    openSidebar,
    isMobile,
  })

  const valueProps = {
    fontSize: openSidebar ? 14 : 10,
    lineHeight: openSidebar ? 1 : 1.3,
    fontWeight: 700,
    paddingTop: openSidebar ? 0 : '3px',
    ...(sidebarClient === 'JP'
      ? {
          color: theme.palette.primary.main,
          fontStyle: 'italic',
        }
      : {}),
  }

  const Div: HTMLElement = document.createElement('div')

  Div.setAttribute('data-justify-content', 'flex-start')
  Div.setAttribute('data-text-color', theme.palette.text.primary)

  const smallWinSxProps = () => {
    const sxProps = openSidebar
      ? {
          width: 52,
          top: -14,
        }
      : {
          width: 44,
          top: -11,
        }

    return { ...sxProps, position: 'absolute' }
  }

  return (
    <Box className={classes.pointsCardsContent}>
      {((isMobile && openSidebar) || !isMobile) && (
        <>
          <PointCardComponent
            icon={<IconComponent type={IconTypeEnum.RANK} size={iconSize} />}
            name={openSidebar && t('rank')}
            value={playerRankName}
            valueProps={valueProps}
          />

          <PointCardComponent
            icon={<IconComponent type={IconTypeEnum.XP} size={iconSize} />}
            name={openSidebar && t('xpPoints')}
            value={getFormatNumber(gamificationPlayer.xpBalance, playerLocale)}
            valueProps={valueProps}
          />
        </>
      )}

      <PointCardComponent
        pointContent={
          <TokensProfileAnimationComponent
            Div={Div}
            name={openSidebar && t('tokens')}
            flexDirection="column"
            iconSize={sidebarClient === 'JP' ? tokenJPIconSize : iconSize}
            smallWinSxProps={smallWinSxProps()}
            valueSxProps={valueProps}
          />
        }
      />
    </Box>
  )
}
