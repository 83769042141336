import { useCallback } from 'react'
import {
  Box,
  Pagination,
  PaginationItem,
  PaginationRenderItemParams,
  useTheme,
} from '@mui/material'
import { PaginationType } from '@/state'

type Props = {
  pagination: PaginationType
  handleChangePage: (newPage: number) => void
}

export function PaginationDenseComponent({
  pagination,
  handleChangePage,
}: Props) {
  const { page, pages } = pagination

  const theme = useTheme()

  const handleOnChangePage = useCallback(
    (_event: React.ChangeEvent<unknown>, newPage: number) => {
      handleChangePage(newPage)
    },
    [handleChangePage],
  )

  const renderPaginationItems = useCallback(
    (item: PaginationRenderItemParams) => {
      return (
        <PaginationItem
          {...item}
          color="primary"
          sx={{
            minWidth: 28,
            padding: '6px 8px',
          }}
        />
      )
    },
    [],
  )

  return (
    <Box mt={2} display="flex" justifyContent="center">
      <Pagination
        count={pages}
        page={page || 1}
        onChange={handleOnChangePage}
        shape="rounded"
        boundaryCount={0}
        siblingCount={1}
        renderItem={renderPaginationItems}
        sx={{
          '& .GamanzaButtonBase-root.Mui-selected': {
            color: theme.palette.common.white,
          },
        }}
      />
    </Box>
  )
}
