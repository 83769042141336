import { Box, Skeleton, useTheme } from '@mui/material'
import { getBorderColorByMode } from '@/constants'

export const SidebarRewardShopProductSkeletonComponent = () => {
  const theme = useTheme()

  return (
    <Box
      borderRadius={1}
      border={`1px solid ${getBorderColorByMode(theme, 0.08)}`}
      bgcolor={theme.palette.background.default}
    >
      <Skeleton
        variant="rectangular"
        width="100%"
        height={100}
        style={{
          marginBottom: 8,
        }}
      />
      <Box padding={0.75}>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={30}
          style={{
            marginBottom: 2,
          }}
        />
        <Skeleton
          variant="rectangular"
          width="100%"
          height={26}
          style={{
            marginBottom: 8,
          }}
        />
        <Skeleton
          variant="rectangular"
          width={50}
          height={20}
          style={{
            marginBottom: 8,
          }}
        />
      </Box>
    </Box>
  )
}
