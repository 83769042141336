import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { RewardShopTypeEnum } from '@gamanza-engage/mf-enums'
import { RewardShopModels, RewardShopThunks } from '@/state'

import { ProductListTypeEnum } from '@/enums'

export const getAllProductsExtraReducer = (
  builder: ActionReducerMapBuilder<RewardShopModels.RewardShopState>,
) => {
  builder.addCase(
    RewardShopThunks.getAllProductsThunk.pending,
    (state, { meta }) => {
      const { listType } = meta.arg
      if (listType) {
        state.isLoadingSidebarProducts = true
      } else {
        state.isLoading = true
      }
    },
  )

  builder.addCase(
    RewardShopThunks.getAllProductsThunk.fulfilled,
    (state, { payload }) => {
      const { docs, totalDocs, totalPages, limit, page } = payload.response

      if (docs) {
        if (payload?.listType === ProductListTypeEnum.SIDEBAR_SHOP) {
          state.sidebarProducts = docs || []
          state.paginationSidebarProducts = {
            total: totalDocs || 0,
            limit: limit || 8,
            page: page || 1,
            pages: totalPages || 0,
          }
          state.isLoadingSidebarProducts = false
          return
        }

        if (payload.rewardType === RewardShopTypeEnum.EXTERNAL) {
          state.externalProducts = docs || []
        }

        if (payload.rewardType === RewardShopTypeEnum.BOOSTER) {
          state.boosterProducts = docs || []
        }

        if (payload.rewardType === RewardShopTypeEnum.BONUS) {
          state.bonusProducts = docs || []
        }

        if (payload.rewardType === RewardShopTypeEnum.XP) {
          state.xpProducts = docs || []
        }
        state.pagination = {
          total: totalDocs || 0,
          limit: limit || 8,
          page: page || 1,
          pages: totalPages || 0,
        }

        state.isLoading = false
      }

      state.isError =
        payload.response.statusCode &&
        (payload.response.statusCode === 400 ||
          payload.response.statusCode === 404 ||
          payload.response.statusCode === 500)
          ? true
          : state.isError
    },
  )

  builder.addCase(
    RewardShopThunks.getAllProductsThunk.rejected,
    (state, { meta }) => {
      const { listType } = meta.arg
      if (listType) {
        state.isLoadingSidebarProducts = false
      } else {
        state.isLoading = false
      }
    },
  )
}
