import { useTranslation } from 'react-i18next'
import { Box, Button, Chip } from '@mui/material'
import { AccessTime, History } from '@mui/icons-material'

import { MissionTabsEnum, SidebarMenuItemType } from '@/enums'
import { HeaderNavigationComponent } from '@/components/common'
import { useBreakpoints } from '@/hooks'

import { useMissionsCustom } from '../hooks'
import { MissionBundlesCustomComponent, MissionHistoryComponent } from './'
import { useMissionStyles } from '../style'

type Props = {
  isMissionWidget?: boolean
  handleChangeMenuItem?: (sidebarMenuItem: string) => void
}

export const MissionCustomComponent = ({
  isMissionWidget,
  handleChangeMenuItem,
}: Props) => {
  const { t } = useTranslation()

  const { classes } = useMissionStyles()

  const { isMobile } = useBreakpoints()

  const { missionsExpired, missionTab, setMissionTab } = useMissionsCustom()

  return (
    <>
      <Box className={classes.missionWrapper}>
        <Box px={!isMissionWidget && 2} pt={!isMissionWidget && 2}>
          <HeaderNavigationComponent
            title={
              missionTab === MissionTabsEnum.MISSIONS
                ? t('missions')
                : t('missionsHistory')
            }
            titleSxProps={{ textTransform: 'uppercase', fontStyle: 'italic' }}
            goBack={
              (!isMissionWidget || missionTab === MissionTabsEnum.HISTORY) &&
              (() => {
                missionTab === MissionTabsEnum.MISSIONS
                  ? handleChangeMenuItem(SidebarMenuItemType.HOME)
                  : setMissionTab(MissionTabsEnum.MISSIONS)
              })
            }
            interaction={
              <>
                {missionTab === MissionTabsEnum.MISSIONS && (
                  <Button
                    className={classes.historyButton}
                    startIcon={<History />}
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      setMissionTab(MissionTabsEnum.HISTORY)
                    }}
                  >
                    {isMissionWidget && !isMobile
                      ? t('missionHistory')
                      : t('history')}
                  </Button>
                )}
                {missionTab === MissionTabsEnum.HISTORY &&
                  missionsExpired?.length > 0 && (
                    <Chip
                      label={t('lastNumberDays', { number: 30 })}
                      icon={<AccessTime sx={{ fontSize: 16 }} />}
                      sx={{
                        background: 'none',
                        fontWeight: 600,
                        '& .GamanzaChip-label': {
                          paddingRight: 0,
                        },
                      }}
                    />
                  )}
              </>
            }
          />
        </Box>
        <Box className={classes.missionContent} padding={!isMissionWidget && 2}>
          {missionTab === MissionTabsEnum.MISSIONS ? (
            <MissionBundlesCustomComponent
              handleChangeMenuItem={handleChangeMenuItem}
            />
          ) : (
            <MissionHistoryComponent
              handleChangeMenuItem={handleChangeMenuItem}
            />
          )}
        </Box>
      </Box>
    </>
  )
}
