import { makeStyles } from 'tss-react/mui'
import { typographyClasses } from '@mui/material'
import { getBorderColorByMode } from '@/constants'

export const useRewardsStyles = makeStyles()((theme) => {
  return {
    rewardsWrapper: {
      marginTop: 15,

      [`& .${typographyClasses.body1}`]: {
        fontWeight: 900,
        fontSize: 14,
        color: theme.palette.text.primary,
        textTransform: 'uppercase',
        marginBottom: 13,
      },

      '& .rewardItemsList': {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 8,
      },

      '& .rewardItem': {
        backgroundColor: theme.palette.background.default,
        borderRadius: 8,
        border: `1px solid ${getBorderColorByMode(theme)}`,
        display: 'flex',
        justifyContent: 'left',
        gap: 5,
        alignItems: 'center',
        padding: '7px 4px',
        width: 'calc(50% - 14px)',
      },

      '& .rewardItemIcon': {
        padding: '3px 3px 0',

        '& svg': {
          width: 25,
          height: 25,
        },
      },

      '& .rewardItemDescription': {
        display: 'flex',
        flexDirection: 'column',

        [`& .${typographyClasses.body2}`]: {
          fontSize: 12,
          color: theme.palette.text.secondary,
          textTransform: 'capitalize',
          fontWeight: 600,
        },

        '& .remainingTime': {
          display: 'flex',
          alignItems: 'center',
          gap: 3,

          [`& .${typographyClasses.caption}`]: {
            fontSize: 10,
            fontWeight: 500,
            color: theme.palette.text.secondary,
            textTransform: 'lowercase',
          },

          '& svg': {
            fontSize: 11,
            color: theme.palette.text.secondary,
          },
        },
      },
    },
  }
})
