export const MissionColors = {
  STATUS: {
    ENDED: {
      content: '#a4a4a4',
    },
  },
  DIALOG_MISSION: {
    progressValue: '#70b200',
    progressValueInactive: '#717d96',
  },
}
