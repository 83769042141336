import { useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'
import { RootState } from '@/state'

import { getSidebarMenuOptions } from '@/constants'
import { useSidebarMenuStyles } from './menu.style'

type Props = {
  Div: HTMLElement
  sidebarMenuItem: string
  handleChangeMenuItem?: (sidebarMenuItem: string) => void
}

export const SidebarMenuComponent = ({
  Div,
  sidebarMenuItem,
  handleChangeMenuItem,
}: Props) => {
  const menuBackground = Div.getAttribute('data-menu-background') || ''

  const menuActiveBackground =
    Div.getAttribute('data-menu-active-background') || ''

  const { classes } = useSidebarMenuStyles({
    menuBackground,
    menuActiveBackground,
  })

  const { missionsEnable } = useSelector(
    ({ globalSettings }: RootState) => globalSettings,
  )

  const filterMenuOptions = getSidebarMenuOptions(missionsEnable).filter(
    ({ isVisible }) => isVisible,
  )

  return (
    <Box className={classes.sidebarMenu}>
      {filterMenuOptions.map((item) => {
        return (
          <Box
            className={`${classes.sidebarMenuItem} ${
              item.id === sidebarMenuItem ? 'active' : ''
            }`}
            onClick={() =>
              handleChangeMenuItem && handleChangeMenuItem(item.id)
            }
            key={item.id}
          >
            <Box className="menuIcon">{item.icon}</Box>
            <Typography>{item.label}</Typography>
          </Box>
        )
      })}
    </Box>
  )
}
