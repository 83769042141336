export enum UIDialogsEnum {
  MECHANICS_FORM = 'MECHANICS_FORM',
  MISSION_DETAILS = 'MISSION_DETAILS',
  ON_SITE_NOTIFICATION = 'ON_SITE_NOTIFICATION',
  CHEST_REWARDS = 'CHEST_REWARDS',
  MISSION_BUNDLE_INFO = 'MISSION_BUNDLE_INFO',
  REWARD_SHOP_PRODUCT = 'REWARD_SHOP_PRODUCT',
  SIDEBAR_SHOP_PRODUCT = 'SIDEBAR_SHOP_PRODUCT',
  LIST_MY_BOOSTERS = 'LIST_MY_BOOSTERS',
  ACTIVATE_BOOSTER = 'ACTIVATE_BOOSTER',
}
