import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { PlayerActions, RootState, useAppDispatch } from '@/state'

import { usePlayerData } from '@/hooks'

export const useNotificationFinal = () => {
  const dispatch = useAppDispatch()

  const { gamificationPlayer } = useSelector(({ player }: RootState) => player)

  const { playerLocale } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const [isVisibleRewards, setIsVisibleRewards] = useState(false)

  const containerRef = useRef(null)

  const [level, setLevel] = useState(
    gamificationPlayer.previousData.previousLevelNumber,
  )

  const [tokens, setTokens] = useState(
    gamificationPlayer.previousData.previousTokens,
  )

  const [xpBalance, setXPBalance] = useState(
    gamificationPlayer.previousData.previousXpBalance,
  )

  const [rankName, setRankName] = useState(
    gamificationPlayer.previousData.previousRankTranslations?.find(
      (translation) => translation.language === playerLocale.toUpperCase(),
    )?.name || gamificationPlayer.previousData.previousRankName,
  )

  const { playerRankName } = usePlayerData()

  const setCounter = (
    start: number,
    end: number,
    incrementByInterval: number,
    setStateFN: (value: number) => void,
  ) => {
    if (start === end) return

    const incrementTime = (1 / end) * 1000

    const timer = setInterval(() => {
      start += incrementByInterval
      setStateFN(start)
      if (start >= end) clearInterval(timer)
    }, incrementTime)
  }

  const updateCounters = () => {
    setRankName(playerRankName)

    setCounter(
      gamificationPlayer.previousData.previousLevelNumber,
      gamificationPlayer.level.levelNumber,
      1,
      setLevel,
    )

    setCounter(
      gamificationPlayer.previousData.previousTokens,
      gamificationPlayer.tokens,
      gamificationPlayer.tokens -
        gamificationPlayer.previousData.previousTokens >
        2000
        ? 10
        : 1,
      setTokens,
    )

    setCounter(
      gamificationPlayer.previousData.previousXpBalance,
      gamificationPlayer.xpBalance,
      gamificationPlayer.xpBalance -
        gamificationPlayer.previousData.previousXpBalance >
        2000
        ? 10
        : 1,
      setXPBalance,
    )
  }

  const updatePreviousData = () => {
    dispatch(PlayerActions.updateGamificationPreviousData())
  }

  useEffect(() => {
    const timerInRewards = setTimeout(() => {
      updateCounters()
      setIsVisibleRewards(true)
    }, 2000)

    return () => {
      clearTimeout(timerInRewards)
      updatePreviousData()
    }
  }, []) // eslint-disable-line

  return {
    isVisibleRewards,
    containerRef,
    level,
    tokens,
    rankName,
    xpBalance,
  }
}
