import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import {
  MissionHistoryThunks,
  MissionHistoryModels,
} from '@/state/mission-history'

export const getExpiredMissionsExtraReducer = (
  builder: ActionReducerMapBuilder<MissionHistoryModels.MissionHistoryState>,
) => {
  builder.addCase(
    MissionHistoryThunks.getExpiredMissionsThunk.pending,
    (state) => {
      state.isLoading = true
    },
  )
  builder.addCase(
    MissionHistoryThunks.getExpiredMissionsThunk.fulfilled,
    (state, { payload }) => {
      state.missionsExpired = payload.docs || []
      state.isLoading = false

      if (state.reloadBundles) {
        state.missionActiveBundles = state.reloadBundles
          ? []
          : state.missionActiveBundles
        state.reloadBundles = false
      }
    },
  )
  builder.addCase(
    MissionHistoryThunks.getExpiredMissionsThunk.rejected,
    (state) => {
      state.isLoading = false
    },
  )
}
