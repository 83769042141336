import { createAsyncThunk } from '@reduxjs/toolkit'
import Container from 'typedi'
import { Rest } from '@/utils/api.utils'

import { CatalogModels, WidgetEndpoints } from '@/state'
import { CatalogActionTypes } from '../constants'

const apiService: Rest = Container.get(Rest)

export const getProvidersNamesThunk = createAsyncThunk(
  CatalogActionTypes.GET_PROVIDERS_NAMES,
  async () => {
    try {
      const response: CatalogModels.GetProviderNamesResponse =
        await apiService.sendData({
          method: 'GET',
          endpoint: WidgetEndpoints.GET_CATALOG_PROVIDERS_NAMES,
        })

      return response
    } catch (error) {
      throw error
    }
  },
)
