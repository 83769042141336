import { makeStyles } from 'tss-react/mui'
import {
  buttonBaseClasses,
  iconButtonClasses,
  typographyClasses,
} from '@mui/material'
import { getBorderColorByMode, SidebarColors } from '@/constants'

import { VariantsComponentsEnum } from '@/enums'

type Props = {
  variant: VariantsComponentsEnum
  buttonColor?: string
}
export const useStyles = makeStyles<Props>()((
  theme,
  { variant, buttonColor },
) => {
  const claimChestColor =
    buttonColor || SidebarColors(theme).chestBackgroundColor

  return {
    claimChestItem: {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: 8,
      padding: 8,
      backgroundColor: theme.palette.background.default,
      border: `1px solid ${getBorderColorByMode(theme)}`,
      gap: 12,

      '& .claimChestIcon': {
        position: 'relative',
        height: variant === VariantsComponentsEnum.LARGE ? 48 : 35,

        '& .image': {
          width: variant === VariantsComponentsEnum.LARGE ? 48 : 35,
        },

        '& .amountRewards': {
          width: 16,
          height: 16,
          padding: 2,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          position: 'absolute',
          top: VariantsComponentsEnum.LARGE ? 0 : -3,
          right: VariantsComponentsEnum.LARGE ? 0 : -3,
          backgroundColor: claimChestColor,
          borderRadius: 2,
        },

        [`& .${typographyClasses.caption}`]: {
          color: theme.palette.common.white,
          fontSize: 10,
          fontWeight: 600,
          textTransform: 'uppercase',
        },

        [theme.breakpoints.down('xs')]: {
          height: 35,

          '& .image': {
            width: 35,
          },

          '& .amountRewards': {
            top: -3,
            right: -3,
          },
        },
      },

      [`& .${typographyClasses.body1}`]: {
        color: theme.palette.primary.main,
        fontSize: variant === VariantsComponentsEnum.LARGE ? 14 : 12,
        fontWeight: 700,

        [theme.breakpoints.down('xs')]: {
          fontSize: 12,
        },
      },

      [`& .${typographyClasses.body2}`]: {
        color: theme.palette.text.secondary,
        fontSize: variant === VariantsComponentsEnum.LARGE ? 12 : 10,
        fontWeight: 400,

        [theme.breakpoints.down('xs')]: {
          fontSize: 10,
        },
      },

      [`& .${iconButtonClasses.root}`]: {
        width: 30,
        height: 30,
        padding: '4px 6px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        backgroundColor: claimChestColor,
        borderRadius: 4,
        color: theme.palette.common.white,

        '&:hover': {
          backgroundColor: claimChestColor,
        },
      },

      [`& .${buttonBaseClasses.root}`]: {
        padding:
          variant === VariantsComponentsEnum.LARGE ? '6px 16px' : '4px 10px',
        borderRadius: variant === VariantsComponentsEnum.LARGE ? 6 : 4,
        fontSize: variant === VariantsComponentsEnum.LARGE ? 14 : 12,
        maxWidth: variant === VariantsComponentsEnum.LARGE ? 76 : 58,
        minWidth: 'auto',
        color: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: claimChestColor,
        fontWeight: 600,
        textTransform: 'uppercase',

        '&:hover': {
          backgroundColor: claimChestColor,
        },

        [theme.breakpoints.down('xs')]: {
          padding: '4px 10px',
          borderRadius: 4,
          fontSize: 12,
          maxWidth: 58,
        },
      },
    },
    claimChestContainer: {
      display: 'flex',
      gap: 6,
      alignItems: 'center',
    },
  }
})
