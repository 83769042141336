import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { RewardShopTypeEnum } from '@gamanza-engage/mf-enums'
import { Box, Button, Grid, Typography } from '@mui/material'
import { KeyboardArrowRight } from '@mui/icons-material'

import { ProductCardComponent, useRewardShop } from '@/components/reward-shop'
import { useBreakpoints } from '@/hooks'
import { RewardShopModels, RootState } from '@/state'

import { SkeletonLoader } from '@/components/common'
import { ReactComponent as RightArrow } from '@/assets/rightArrow.svg'
import { useStyles } from './style'

type Props = {
  productList: RewardShopModels.ProductType[]
  rewardType: RewardShopTypeEnum
}

export function ProductListComponent({ productList, rewardType }: Props) {
  const { t } = useTranslation()

  const [showArrowRight, setShowArrowRight] = useState(false)
  const [showArrowLeft, setShowArrowLeft] = useState(false)

  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const { gamificationOpt } = useSelector(({ player }: RootState) => player)

  const { isLoading } = useSelector(({ rewardShop }: RootState) => rewardShop)

  const gridRef = useRef(null)

  const { classes } = useStyles({ client })

  const { isMobile, isDesktop } = useBreakpoints()

  const { handleOpenProductDetail, handleOpenCategory } = useRewardShop()

  const handleClickScroll = (left: boolean = false) => {
    left
      ? (gridRef.current.scrollLeft -= 200)
      : (gridRef.current.scrollLeft += 200)
  }

  const handleEventScroll = () => {
    setShowArrowLeft(gridRef.current.scrollLeft > 0)
  }

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.addEventListener('scroll', handleEventScroll)

      setShowArrowRight(
        gridRef.current.scrollWidth > gridRef.current.clientWidth,
      )
    }
  }, [gridRef])

  return (
    <>
      {!isLoading ? (
        <>
          {productList.length > 0 && (
            <>
              <Box className="categoryContent">
                <Typography variant="h6">
                  {t(`rewardCategory${rewardType}`)}
                </Typography>
                {(productList.length > 3 || !isDesktop) && (
                  <Button
                    onClick={() => handleOpenCategory(rewardType)}
                    disabled={!gamificationOpt?.enable || false}
                    sx={{
                      letterSpacing: '1px',
                    }}
                  >
                    {t('seeAll')}
                    {client !== 'JP' && <KeyboardArrowRight />}
                  </Button>
                )}
              </Box>
              <Grid
                container
                spacing={2}
                className={classes.carouselSnap}
                flexWrap="nowrap"
                ref={gridRef}
                sx={{
                  overflowX: gamificationOpt?.enable
                    ? 'auto'
                    : 'hidden !important',
                }}
              >
                {client === 'JP' &&
                  !isMobile &&
                  showArrowLeft &&
                  gamificationOpt?.enable && (
                    <Button
                      className={`${classes.cardArrow} left`}
                      onClick={() => handleClickScroll(true)}
                    >
                      <RightArrow />
                    </Button>
                  )}
                {productList.map((product) => {
                  return (
                    <ProductCardComponent
                      key={product._id}
                      handleOpenProductDetail={handleOpenProductDetail}
                      product={product}
                      fullList
                    />
                  )
                })}

                {client === 'JP' &&
                  !isMobile &&
                  showArrowRight &&
                  gamificationOpt?.enable && (
                    <Button
                      className={`${classes.cardArrow} right`}
                      onClick={() => handleClickScroll()}
                    >
                      <RightArrow />
                    </Button>
                  )}
              </Grid>
            </>
          )}
        </>
      ) : (
        <SkeletonLoader
          element="product"
          carouselClass={classes.carouselSnap}
        />
      )}
    </>
  )
}
