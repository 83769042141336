import { typographyClasses } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

interface Props {
  openSidebar?: boolean
  sidebarClient?: string
  isMobile?: boolean
}

export const useProgressBarStyles = makeStyles<Props>()((
  theme,
  { openSidebar, sidebarClient, isMobile },
) => {
  return {
    progressBarContent: {
      // position: 'absolute',
      // right: 15,
      // top: 15,
      // width: 'calc(100% - 90px)',
      width: '100%',
      opacity: openSidebar && !isMobile ? 0 : 1,
      animation:
        openSidebar && !isMobile
          ? 'flexAnimationOpacity 0.35s ease-in forwards 0.3s'
          : 'none',

      '@keyframes flexAnimationOpacity': {
        '0%': {
          opacity: 0,
        },

        '100%': {
          opacity: 1,
        },
      },

      [`& .${typographyClasses.body2}`]: {
        color: theme.palette.common.white,
        fontSize: 16,
        fontStyle: sidebarClient === 'JP' ? 'italic' : ' normal',
        fontWeight: 700,
        paddingLeft: 5,
      },

      [`& .${typographyClasses.caption}`]: {
        color: theme.palette.common.white,
        fontSize: 10,
      },
    },
  }
})
