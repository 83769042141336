import { createAsyncThunk } from '@reduxjs/toolkit'
import Container from 'typedi'
import { t } from 'i18next'

import { HttpStatusCode } from '@gamanza-engage/mf-enums'
import { Rest } from '@/utils/api.utils'
import {
  MyBoosterActions,
  MyBoosterModels,
  MyBoosterThunks,
  WidgetEndpoints,
  globalUiActions,
  store,
} from '@/state'

import { RewardProcessorStatusEnum, SnackbarSeverityEnum } from '@/enums'
import i18n from '@/i18n'
import { initialBoosterFilters } from '@/constants'

import { MyBoosterActionTypes } from '../constants'

const apiService: Rest = Container.get(Rest)

export const activateBoosterThunk = createAsyncThunk(
  MyBoosterActionTypes.ACTIVATE_BOOSTER,
  async (payload: MyBoosterModels.ActivateBoosterPaylaod, thunkAPI) => {
    try {
      const { id, ...bodyPayload } = payload
      const response: MyBoosterModels.ActivateBoosterResponse =
        await apiService.sendData({
          method: 'PATCH',
          endpoint: `${WidgetEndpoints.REWARD_PROCESSOR}/${id}`,
          body: bodyPayload,
        })

      const success = response.statusCode === HttpStatusCode.OK

      if (
        success &&
        response.status === RewardProcessorStatusEnum.IN_PROGRESS
      ) {
        const state = store.getState()

        thunkAPI.dispatch(
          globalUiActions.setSnackbar({
            open: true,
            severity: SnackbarSeverityEnum.SUCCESS,
            message: t('activatedSuccessfully'),
          }),
        )

        thunkAPI.dispatch(
          globalUiActions.setDialog({
            open: false,
          }),
        )

        thunkAPI.dispatch(
          MyBoosterThunks.getAllMyBoostersThunk({
            ...initialBoosterFilters,
            playerId: state.initConfig.playerId,
          }),
        )
        thunkAPI.dispatch(MyBoosterActions.setLoadingFalse())
      }

      if (
        response.details.faultCode === HttpStatusCode.BAD_REQUEST &&
        response.message.includes('cap')
      ) {
        let period = 'daily'

        if (response.message.includes('weekly')) {
          period = 'weekly'
        }

        if (response.message.includes('monthly')) {
          period = 'monthly'
        }

        thunkAPI.dispatch(
          globalUiActions.setSnackbar({
            message: i18n.t('capReachedBoosterError', {
              periodType: period,
              timeText: i18n.t(`timeText.${period}`),
              nextTimeText: i18n.t(`nextTimeText.${period}`),
            }),
            open: true,
            severity: SnackbarSeverityEnum.ERROR,
          }),
        )
      }

      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
