import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { uniqBy } from 'lodash'
import { RewardTypeEnum } from '@gamanza-engage/mf-enums'

import { MyBoosterThunks, MyBoosterModels } from '@/state'

export const getLevelActiveBoostersExtraReducer = (
  builder: ActionReducerMapBuilder<MyBoosterModels.MyBoosterState>,
) => {
  builder.addCase(
    MyBoosterThunks.getLevelActiveBoostersThunk.fulfilled,
    (state, { payload }) => {
      const levelDocs =
        payload?.docs?.map((token) => {
          return { ...token, boosterType: RewardTypeEnum.LEVEL_BOOSTER }
        }) || []
      const activeBoosters = [...state.activeBoosters, ...levelDocs]

      state.activeBoosters = uniqBy(activeBoosters, '_id')
      state.hideActiveBoosters = activeBoosters.length === 0
      state.isLoadingLevelBoosters = false
    },
  )
}
