import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RewardTypeEnum } from '@gamanza-engage/mf-enums'

import { Box, Typography } from '@mui/material'
import { AccessTimeOutlined } from '@mui/icons-material'
import { GeneralModels, RootState } from '@/state'

import { rewardBoosterTime, rewardDescription } from '@/utils'
import { RewardTypeInformation } from '@/constants'
import { useStyles } from '../styles'

type Props = {
  reward: GeneralModels.RewardCategoryType
}

export const ModalRewardComponent = ({ reward }: Props) => {
  const { classes } = useStyles({})

  const { t } = useTranslation()

  const { playerLocale } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const rewardTypeInfo = RewardTypeInformation()

  return (
    <Box className={classes.rewardContainer}>
      <Box className="rewardItemIcon">
        {rewardTypeInfo[reward?.rewardType].icon}
      </Box>
      <Box className="rewardItemDescription">
        <Typography variant="body2">
          {`${rewardDescription(reward, playerLocale)} ${t(`${rewardTypeInfo[reward?.rewardType].label}`)}`}
        </Typography>
        {(reward?.rewardType === RewardTypeEnum.LEVEL_BOOSTER ||
          reward?.rewardType === RewardTypeEnum.MISSION_BOOSTER ||
          reward?.rewardType === RewardTypeEnum.TOKEN_BOOSTER) && (
          <Typography variant="caption">
            <AccessTimeOutlined />
            {rewardBoosterTime(reward.timeFrame)}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
