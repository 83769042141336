import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'

import { AccessTimeOutlined } from '@mui/icons-material'
import { ProgressBarComponent } from '@/components/common/progress-bar'
import { MissionColors } from '@/constants'

import { MissionCommonModels, RootState } from '@/state'
import { MissionStatusEnum } from '@/enums'
import { useRemainingTime } from '@/hooks'

import { useDialogProgressStyles } from './progress.style'

type Props = {
  state: MissionCommonModels.MissionProgressType // TODO: This type will change in the future
  status: MissionStatusEnum
  endDate: string
}

export const DialogMissionProgressComponent = ({
  state,
  status,
  endDate,
}: Props) => {
  const { t } = useTranslation()

  const { baseUrl } = useSelector(({ initConfig }: RootState) => initConfig)

  const { classes } = useDialogProgressStyles()

  const { timeRemaining } = useRemainingTime({ endDate })

  const getMissionStatusText = () => {
    switch (status) {
      case MissionStatusEnum.ACTIVE:
        return t('missionProgress')

      case MissionStatusEnum.COMPLETED:
        return t('missionCompleted')

      case MissionStatusEnum.ENDED:
        return t('missionIncompleted')

      case MissionStatusEnum.INACTIVE:
      case MissionStatusEnum.PENDING:
        return t('missionLocked')
    }
  }

  return (
    <Box className={classes.dialogMissionProgress}>
      <Box className="missionProgressTitle">
        <Typography variant="body2">{getMissionStatusText()}</Typography>
        {status && status === MissionStatusEnum.ACTIVE && (
          <Box className="remainingTime">
            <AccessTimeOutlined />
            <Typography variant="body2">{timeRemaining}</Typography>
          </Box>
        )}
      </Box>

      <ProgressBarComponent
        progressValue={Number(state?.progress) || 0}
        progressColor={MissionColors.DIALOG_MISSION.progressValue}
        balanceText={`${state?.completed || 0}/${state?.total || 0}`}
        icon={
          status === MissionStatusEnum.COMPLETED ? (
            <img
              src={`${baseUrl}assets/icons/missions/icon-mission-chest-opened.png`}
              alt="Chest Opened"
            />
          ) : (
            <img
              src={`${baseUrl}assets/icons/missions/icon-mission-chest-closed.png`}
              alt="Chest Closed"
            />
          )
        }
        iconSxProps={{ '& img': { width: 50, height: 50 } }}
        heightProgress={25}
      />
    </Box>
  )
}
