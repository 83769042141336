import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { MyBoosterModels, RootState } from '@/state'
import { rewardBoosterTime } from '@/utils'
import { boostersInfo, initialBoosterFilters } from '@/constants'

import { useGlobalUi } from '@/hooks'
import { UIDialogsEnum } from '@/enums'
import { useMyBoostersActions } from './'

type Props = {
  dense: boolean
}

export const useMyBoosters = ({ dense }: Props) => {
  const { t } = useTranslation()

  const { handleWaitCloseDialog, handleOpenDialog } = useGlobalUi()

  const {
    myBoosters,
    pagination,
    isLoading,
    activatingBooster,
    activeBoosters,
  } = useSelector(({ myBooster }: RootState) => myBooster)

  const { playerLocale, playerId, client } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const { gamificationOpt } = useSelector(({ player }: RootState) => player)

  const {
    getAllMyBoosters,
    handlePrevPage,
    handleNextPage,
    handleActivateBooster,
    handleChangePage,
  } = useMyBoostersActions()

  const handleOpenConfirmBooster = (orderId: string) => {
    const filteredBooster = myBoosters.find((booster) => booster.id === orderId)

    handleWaitCloseDialog()

    setTimeout(() => {
      handleOpenDialog({
        id: UIDialogsEnum.ACTIVATE_BOOSTER,
        object: {
          booster: filteredBooster,
        },
      })
    }, 500)
  }

  const isActiveAnimation = activeBoosters.find((booster) => booster.isNew)

  const myBoosterFiltered = myBoosters.map((myBooster) => {
    const translation =
      myBooster.earnedReward.translations?.find(
        (item) => item.language.toUpperCase() === playerLocale.toUpperCase(),
      ) ||
      myBooster.earnedReward.translations?.[0] ||
      myBooster.metadata

    return {
      id: myBooster.id,
      title: dense
        ? translation?.name ||
          `${
            myBooster.earnedReward.boosterRate
          }X ${t(boostersInfo[myBooster.earnedReward.type].title)}`
        : translation?.name ||
          `${
            myBooster.earnedReward.boosterRate
          }X ${t(boostersInfo[myBooster.earnedReward.type].title)} - ${rewardBoosterTime(myBooster.earnedReward.timeFrame)}`,
      description: dense
        ? rewardBoosterTime(myBooster.earnedReward.timeFrame, true)
        : translation?.description,
      image: dense
        ? myBooster.earnedReward.mobileImage ||
          boostersInfo[myBooster.earnedReward.type].mobileImage
        : myBooster.earnedReward.mobileImage,
      type: myBooster.earnedReward.type,
    }
  })

  const filtersBoosters: MyBoosterModels.BoosterFiltersType = {
    ...initialBoosterFilters,
    playerId: playerId,
  }

  useEffect(() => {
    if (!myBoosters.length) {
      getAllMyBoosters(filtersBoosters)
    }
  }, []) // eslint-disable-line

  return {
    myBoosterFiltered,
    pagination,
    isLoading,
    client,
    gamificationOpt,
    activatingBooster,
    isActiveAnimation,
    handlePrevPage,
    handleNextPage,
    handleActivateBooster,
    handleChangePage,
    handleOpenConfirmBooster,
  }
}
