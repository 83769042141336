import { makeStyles } from 'tss-react/mui'

interface Props {
  transform: number
  progressColor?: string
}

export const useStyles = makeStyles<Props>()((
  theme,
  { transform, progressColor },
) => {
  return {
    avatar: {
      '& .primaryColor path': {
        fill: theme.palette.primary.main,
        stroke: theme.palette.primary.main,
      },
    },
    avatarContent: {
      position: 'relative',
      display: 'inline-flex',
      backgroundColor: theme.palette.background.default,
      borderRadius: '50%',
      transform: `scale(${transform})`,
      transformOrigin: '0 0',
      overflow: 'hidden',
      '& .avatar': {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 110,
        height: 110,
        backgroundColor: 'transparent',
        border:
          theme.palette.mode === 'dark'
            ? `10px solid ${theme.palette.common.black}`
            : 'none',

        '& svg': {
          backgroundColor: theme.palette.primary.main,
        },
      },

      '& .disableAvatar': {
        '& svg': {
          backgroundColor: theme.palette.grey[200],
        },
      },

      '& .circular': {
        circle: {
          strokeLinecap: 'round',
          strokeWidth: '3.5px',
          color: progressColor || theme.palette.primary.main,
        },
      },

      '& .circularTransparent': {
        circle: {
          strokeLinecap: 'round',
          strokeWidth: '3.5px',
          color:
            theme.palette.mode === 'dark'
              ? theme.palette.background.default
              : theme.palette.grey[300],
        },
      },
    },
    accountCircle: {
      width: '130%',
      height: '130%',
    },
  }
})
