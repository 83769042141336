import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { MenuItemType } from '@/enums'

import {
  OrderThunks,
  RewardShopActions,
  RootState,
  useAppDispatch,
} from '@/state'

import { LIMIT } from '@/constants'
import { resetPIDWindowHash } from '@/utils'

export const useMenu = () => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const { menuSelected, currentProduct, rewardType } = useSelector(
    ({ rewardShop }: RootState) => rewardShop,
  )

  const { playerId } = useSelector(({ initConfig }: RootState) => initConfig)

  const filtersOrders = {
    playerId: playerId,
    limit: LIMIT,
  }

  const handleChangeMenuItem = (newValue: string) => {
    dispatch(RewardShopActions.setMenuSelected(newValue))

    resetPIDWindowHash()

    if (newValue === MenuItemType.HISTORY) {
      dispatch(OrderThunks.getAllOrdersThunk(filtersOrders))
    }

    if (newValue === MenuItemType.REWARD_SHOP) {
      dispatch(RewardShopActions.resetRewardShopPage())
    }
  }

  const getLastBreadcrumbs = () => {
    if (menuSelected === MenuItemType.HISTORY) {
      return t('shopHistory')
    }
    if (currentProduct) {
      return t('productDetails')
    }

    return t(`rewardCategory${rewardType}`)
  }

  return {
    handleChangeMenuItem,
    getLastBreadcrumbs,
  }
}
