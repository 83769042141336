import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { NotificationTypeEnum } from '@/enums'

import { useGlobalUi, usePlayerData } from '@/hooks'
import { RootState } from '@/state'

export const useNotifications = () => {
  const { t } = useTranslation()

  const { playerRankName } = usePlayerData()

  const { gamificationPlayer } = useSelector(({ player }: RootState) => player)

  const { modal } = useGlobalUi()

  const NotificationData = {
    [NotificationTypeEnum.RANK_UP]: {
      title: t('congratulations'),
      description: t('congratulationsRankUp', {
        rank: playerRankName,
      }),
      button: t('seeProgress').toUpperCase(),
    },
    [NotificationTypeEnum.LEVEL_UP]: {
      title: t('congratulations'),
      description: t('congratulationsLevelUp', {
        level: gamificationPlayer.level.levelNumber,
      }),
      button: t('seeProgress').toUpperCase(),
    },
    [NotificationTypeEnum.MISSION]: {
      title: modal.open ? t('congratulations') : t('missionCompleted'),
      description: modal.open
        ? t('messageMissionCompleted')
        : t('messageMissionEarnedRewards'),
      button: t('seeDetails').toUpperCase(),
    },
  }

  return {
    NotificationData,
  }
}
