import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { NotificationTypeEnum } from '@/enums'

import { useGlobalUi } from '@/hooks'
import { GeneralModels, RootState } from '@/state'
import { getAssetsUrl, getLottieAnimationData } from '@/utils'

export const useNotificationAnimations = () => {
  const [initialAnimationData, setInitialAnimationData] =
    useState<GeneralModels.LottieAnimationData | null>(null)

  const [finalAnimationData, setFinalAnimationData] =
    useState<GeneralModels.LottieAnimationData | null>(null)

  const { modal, notificationSnackbar } = useGlobalUi()

  const { baseUrl, client } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const getInitialAnimationUrl = () => {
    if (modal.type === NotificationTypeEnum.RANK_UP) {
      return getAssetsUrl(
        baseUrl,
        'assets/animations/notifications/rank.lottie',
      )
    }

    if (modal.type === NotificationTypeEnum.LEVEL_UP) {
      if (client === 'JP') {
        return getAssetsUrl(
          baseUrl,
          'assets/animations/notifications/level-jp.lottie',
        )
      }

      return getAssetsUrl(
        baseUrl,
        'assets/animations/notifications/level.lottie',
      )
    }

    return getAssetsUrl(
      baseUrl,
      'assets/animations/notifications/mission.lottie',
    )
  }

  const getFinalAnimationUrl = () => {
    if (modal.type === NotificationTypeEnum.RANK_UP) {
      return getAssetsUrl(
        baseUrl,
        'assets/animations/notifications/rank-loop.lottie',
      )
    }

    if (modal.type === NotificationTypeEnum.LEVEL_UP) {
      if (client === 'JP') {
        return getAssetsUrl(
          baseUrl,
          'assets/animations/notifications/level-loop-jp.lottie',
        )
      }

      return getAssetsUrl(
        baseUrl,
        'assets/animations/notifications/level-loop.lottie',
      )
    }

    return getAssetsUrl(
      baseUrl,
      'assets/animations/notifications/mission-loop.lottie',
    )
  }

  useEffect(() => {
    if (modal.type && notificationSnackbar.open) {
      getLottieAnimationData(getInitialAnimationUrl(), setInitialAnimationData)
    }

    if (modal.type && notificationSnackbar.open) {
      getLottieAnimationData(getFinalAnimationUrl(), setFinalAnimationData)
    }
  }, [notificationSnackbar, modal]) // eslint-disable-line

  return {
    initialAnimationData,
    finalAnimationData,
  }
}
