import { Theme, alpha } from '@mui/material'

export const getBorderColorByMode = (
  theme: Theme,
  light?: number,
  dark?: number,
) => {
  return theme.palette.mode === 'dark'
    ? alpha(theme.palette.common.white, dark ?? 0.08)
    : alpha(theme.palette.common.black, light ?? 0.05)
}
