import { useTranslation } from 'react-i18next'
import { Box, Button, Grid } from '@mui/material'
import {
  CardItemComponent,
  TooltipComponent,
  ContentIconComponent,
  PaginationComponent,
  SkeletonLoader,
} from '@/components/common'

import { useMyBoosters } from '@/components/my-boosters'
import { JPDefaultBoosterImage } from '@/constants'
import i18n from '@/i18n'

import { ReactComponent as BoosterIcon } from '@/assets/boosterFloatingAction.svg'
import { useBreakpoints } from '@/hooks'

type Props = {
  isStandalone?: boolean
}

export function MyBoostersComponent({ isStandalone }: Props) {
  const { t } = useTranslation()

  const {
    myBoosterFiltered,
    pagination,
    isLoading,
    client,
    gamificationOpt,
    handlePrevPage,
    handleNextPage,
    handleActivateBooster,
  } = useMyBoosters({ dense: false })

  const { isXsMobile } = useBreakpoints()

  return (
    <Box py="20px" sx={{ opacity: gamificationOpt?.enable ? 1 : 0.5 }}>
      {!isLoading ? (
        <>
          {myBoosterFiltered.length > 0 ? (
            <Grid
              spacing={2}
              container
              sx={{ justifyContent: isXsMobile ? 'center' : 'initial' }}
            >
              {myBoosterFiltered.map((item) => (
                <Grid
                  item
                  xs={isXsMobile ? 12 : 6}
                  sm={4}
                  md={3}
                  lg={3}
                  xl={2}
                  sx={{
                    width: '100%',
                    minWidth: isStandalone ? 180 : 0,
                  }}
                  key={item.id}
                >
                  <CardItemComponent
                    key={item.id}
                    item={
                      client === 'JP'
                        ? {
                            ...item,
                            image: item.image || JPDefaultBoosterImage,
                          }
                        : item
                    }
                    contentHeight={70}
                    cardActionsComponent={
                      <Button
                        color="primary"
                        variant="outlined"
                        fullWidth
                        disabled={!gamificationOpt?.enable || false}
                        onClick={() => handleActivateBooster(item.id)}
                      >
                        {t('activate')}
                      </Button>
                    }
                    tooltip={
                      <TooltipComponent
                        title={t('activateBooster')}
                        description={t('activeBoosterDescription')}
                        icon={
                          <Box display="flex" justifyContent="center">
                            <BoosterIcon width={36} height={36} />
                          </Box>
                        }
                        maxWidth={340}
                      />
                    }
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <ContentIconComponent
              iconType="search"
              title={
                i18n.exists('noBoostersYet')
                  ? t('noBoostersYet')
                  : t('noPurchasesYet')
              }
              hideButtonContent
            />
          )}
        </>
      ) : (
        <Grid item xs={12} mt={4}>
          <SkeletonLoader element="product-category" />
          <SkeletonLoader element="product-category" />
        </Grid>
      )}
      {pagination.pages > 1 && (
        <Grid item xs={12} mt={4}>
          <PaginationComponent
            pagination={pagination}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
          />
        </Grid>
      )}
    </Box>
  )
}
