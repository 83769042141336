import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RewardTypeEnum } from '@gamanza-engage/mf-enums'

import { Box, Typography } from '@mui/material'
import { AccessTimeOutlined } from '@mui/icons-material'
import { RewardTypeInformation } from '@/constants'

import { ChestTypeEnum, VariantsComponentsEnum } from '@/enums'
import { ClaimChestItemComponent } from '@/components'
import { GeneralModels, RootState } from '@/state'

import { rewardDescription, rewardBoosterTime } from '@/utils'
import { useBreakpoints } from '@/hooks'
import { useRewardsStyles } from './rewards.style'

type Props = {
  rewards: GeneralModels.RewardCategoryType[]
  pendingClaim: boolean
  missionId?: string
  bundleId?: string
}

export const DialogMissionRewardsComponent = ({
  rewards,
  pendingClaim,
  missionId,
  bundleId,
}: Props) => {
  const { t } = useTranslation()

  const { classes } = useRewardsStyles()

  const { isXsMobile } = useBreakpoints()

  const { playerLocale, client } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const rewardTypeInfo = RewardTypeInformation()

  return (
    <>
      {rewards?.length > 0 && (
        <Box className={classes.rewardsWrapper}>
          <Typography fontStyle={client === 'JP' ? 'italic' : 'normal'}>
            {t('missionRewards')}
          </Typography>
          {pendingClaim ? (
            <ClaimChestItemComponent
              rewards={rewards}
              missionId={missionId}
              bundleId={bundleId}
              variant={
                isXsMobile
                  ? VariantsComponentsEnum.SMALL
                  : VariantsComponentsEnum.LARGE
              }
              chestType={ChestTypeEnum.MISSION}
            />
          ) : (
            <Box className="rewardItemsList">
              {rewards?.map((reward) => {
                return (
                  <Box
                    className="rewardItem"
                    key={`tooltip-reward-${reward.id}`}
                  >
                    <Box className="rewardItemIcon">
                      {rewardTypeInfo[reward.rewardType].icon}
                    </Box>
                    <Box className="rewardItemDescription">
                      <Typography variant="body2">
                        {reward.rewardType === RewardTypeEnum.BONUS_OFFER
                          ? rewardDescription(reward, playerLocale)
                          : `${rewardDescription(reward, playerLocale)} ${t(`${rewardTypeInfo[reward.rewardType].label}`)}`}
                      </Typography>
                      {reward.rewardType !== RewardTypeEnum.TOKENS &&
                        reward.rewardType !== RewardTypeEnum.BONUS_OFFER &&
                        reward.timeFrame && (
                          <Box className="remainingTime">
                            <AccessTimeOutlined />
                            <Typography variant="caption">
                              {rewardBoosterTime(reward.timeFrame, true)}
                            </Typography>
                          </Box>
                        )}
                    </Box>
                  </Box>
                )
              })}
            </Box>
          )}
        </Box>
      )}
    </>
  )
}
