import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AssetsModels } from './'

const initialState: AssetsModels.AssetsState = {
  images: {
    avatar_image: '',
    menu_booster_image: '',
    menu_rewards_image: '',
    history_image: '',
  },
}

export const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    setImages(state, action: PayloadAction<AssetsModels.AssetsType>) {
      state.images = action.payload
    },
  },
})

export const AssetsActions = assetsSlice.actions
