import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getData, Country, getName } from 'country-list'

import {
  Autocomplete,
  Grid,
  TextField,
  TextFieldProps,
  useTheme,
} from '@mui/material'
import { RootState, RewardShopModels } from '@/state'

type Props = {
  productForm: RewardShopModels.ProductFormType
  handleOnChange: (field: string, newValue: string | number) => void
}

export function LocationFormComponent({ productForm, handleOnChange }: Props) {
  const { t } = useTranslation()

  const { sendToEmail } = productForm

  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const { countries } = useSelector(({ player }: RootState) => player)

  const theme = useTheme()

  const gridProps = {
    [theme.breakpoints.down(400)]: {
      width: '100%',
    },
  }

  const allCountries =
    client === 'JP'
      ? [
          {
            code: 'CH',
            name: getName('CH'),
          },
        ]
      : getData()

  const listCountries: Country[] =
    countries.length > 0
      ? getData().filter(
          (country) =>
            countries.findIndex(
              (index) => index.toUpperCase() === country.code,
            ) !== -1,
        )
      : allCountries

  const textFieldsProps: TextFieldProps = {
    variant: 'filled',
    fullWidth: true,
    margin: 'normal',
  }

  const inputs = [
    { name: 'city', columnWidth: 6, isDisabled: sendToEmail },
    { name: 'street', columnWidth: 6, isDisabled: sendToEmail },
    { name: 'streetNumber', columnWidth: 6, isDisabled: sendToEmail },
    { name: 'postalCode', columnWidth: 6, isDisabled: sendToEmail },
    {
      name: 'receives',
      label: sendToEmail ? 'email' : 'receives',
      columnWidth: 12,
      required: true,
    },
  ]

  return (
    <Grid container columnSpacing={2}>
      {!sendToEmail && (
        <Grid item xs={6} sx={gridProps}>
          <Autocomplete
            disableClearable
            options={listCountries.map((item) => item.code)}
            getOptionLabel={(option: string) =>
              listCountries.find((item) => item.code === option)?.name || ''
            }
            value={productForm.country}
            onChange={(_, value) => handleOnChange('country', value)}
            renderInput={(params) => (
              <TextField
                {...params}
                {...textFieldsProps}
                label={t('country')}
                error={!sendToEmail && !productForm.country}
                helperText={
                  !sendToEmail && !productForm.country && t('requiredField')
                }
              />
            )}
            fullWidth
          />
        </Grid>
      )}
      {inputs
        .filter(({ isDisabled }) => !isDisabled)
        .map(({ name, label, columnWidth, required }) => (
          <Grid item xs={columnWidth} sx={gridProps}>
            <TextField
              {...textFieldsProps}
              label={t(label || name)}
              value={productForm[name]}
              onChange={(e) => handleOnChange(name, e.target.value)}
              error={
                (required || !sendToEmail) && productForm[name].trim() === ''
              }
              helperText={
                (required || !sendToEmail) &&
                productForm[name].trim() === '' &&
                t('requiredField')
              }
            />
          </Grid>
        ))}
    </Grid>
  )
}
