import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, CircularProgress } from '@mui/material'

import { RootState } from '@/state'
import {
  MissionItemHistoryComponent,
  NoContentIconComponent,
} from '@/components/common'
import { DialogTypeEnum, SidebarMenuItemType, UIDialogsEnum } from '@/enums'

import { useGlobalUi } from '@/hooks'
import { useMissionStyles } from '../style'

type Props = {
  handleChangeMenuItem: (sidebarMenuItem: string) => void
}

export const MissionHistoryComponent = ({ handleChangeMenuItem }: Props) => {
  const { t } = useTranslation()

  const { isLoading, missionsExpired } = useSelector(
    ({ missionHistory }: RootState) => missionHistory,
  )

  const { handleOpenDialog } = useGlobalUi()

  const { classes } = useMissionStyles()

  return (
    <>
      {missionsExpired?.length > 0 && !isLoading ? (
        <Box className={classes.listMissionsHistory}>
          {missionsExpired.map((mission) => {
            return (
              <MissionItemHistoryComponent
                key={mission._id}
                mission={mission}
                bundleSettings={mission.missionBundleSettings}
                handleClick={() => {
                  handleOpenDialog({
                    id: UIDialogsEnum.MISSION_DETAILS,
                    object: {
                      missionId: mission._id,
                      title: t(
                        `timeframeTypeMissions.${mission.missionBundleSettings.timeFrame.timeFrameType}`,
                      ),
                      type: DialogTypeEnum.MISSION_HISTORY,
                    },
                  })
                }}
              />
            )
          })}
        </Box>
      ) : (
        <>
          {isLoading || missionsExpired === null ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                minHeight: 300,
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box minHeight={400} display="flex" alignItems="center">
              <NoContentIconComponent
                title={t('noMissionsHistory')}
                message={t('noMissionsHistoryMessage')}
                buttonAction={() => {
                  handleChangeMenuItem(SidebarMenuItemType.HOME)
                }}
                buttonText={t('goHome')}
              />
            </Box>
          )}
        </>
      )}
    </>
  )
}
