import pako from 'pako'
import memoize from 'lodash.memoize'

const fetchData = memoize(async (url: string) => {
  const response = await fetch(url)
  if (!response.ok) {
    throw new Error('Network response error')
  }

  return response.json()
})

const fetchLottieData = memoize(async (url: string) => {
  const response = await fetch(url)
  if (!response.ok) {
    throw new Error('Network response error')
  }

  const buffer = await response.arrayBuffer()
  const inflated = pako.inflate(new Uint8Array(buffer), { to: 'string' })
  return JSON.parse(inflated)
})

export const getLottieAnimationData = async (
  url: string,
  setAnimation: Function,
) => {
  try {
    const data = url.includes('.json')
      ? await fetchData(url)
      : await fetchLottieData(url)

    setAnimation(data)
  } catch (error) {
    throw error
  }
}

export const getAssetsUrl = (host: string, path: string) => {
  return `${host}${path}`
}
