import { useEffect, useRef, useState } from 'react'

export const useSliderItems = (amountItems: number, itemsByBlock: number) => {
  const [currentBullet, setCurrentBullet] = useState(0)
  const [numberBullets, setNumberBullets] = useState(0)
  const [showArrowRight, setShowArrowRight] = useState(false)
  const [showArrowLeft, setShowArrowLeft] = useState(false)

  const sliderRef = useRef(null)

  const handleScrollBullet = (bulletId: number) => {
    if (bulletId === 0) {
      sliderRef.current.scrollLeft = 0
      return ''
    }
    if (bulletId === numberBullets - 1) {
      sliderRef.current.scrollLeft = sliderRef.current.scrollWidth
      return ''
    }
    const clientWidthDifference = sliderRef.current.clientWidth * bulletId
    sliderRef.current.scrollLeft = clientWidthDifference
  }

  const handleScrollArrows = (left: boolean = false) => {
    if (currentBullet === numberBullets - 1 && left) {
      const clientWidthDifference =
        sliderRef.current.clientWidth * (numberBullets - 2)
      sliderRef.current.scrollLeft = clientWidthDifference
    } else {
      left
        ? (sliderRef.current.scrollLeft -= sliderRef.current.clientWidth)
        : (sliderRef.current.scrollLeft += sliderRef.current.clientWidth)
    }
  }

  const handleEventScroll = () => {
    setShowArrowLeft(sliderRef.current.scrollLeft > 0)
    setShowArrowRight(
      sliderRef.current.scrollLeft + sliderRef.current.clientWidth <
        sliderRef.current.scrollWidth,
    )

    if (
      sliderRef.current.scrollLeft + sliderRef.current.clientWidth <
      sliderRef.current.scrollWidth
    ) {
      if (sliderRef.current.scrollLeft > sliderRef.current.clientWidth) {
        setCurrentBullet(
          Math.round(
            sliderRef.current.scrollLeft / sliderRef.current.clientWidth,
          ),
        )
      } else {
        setCurrentBullet(
          Math.ceil(
            sliderRef.current.scrollLeft / sliderRef.current.clientWidth,
          ),
        )
      }
    } else if (sliderRef.current.scrollLeft > sliderRef.current.clientWidth) {
      setCurrentBullet(
        Math.ceil(sliderRef.current.scrollLeft / sliderRef.current.clientWidth),
      )
    } else if (
      sliderRef.current.scrollLeft + sliderRef.current.clientWidth ===
      sliderRef.current.scrollWidth
    ) {
      setCurrentBullet(
        Math.ceil(sliderRef.current.scrollLeft / sliderRef.current.clientWidth),
      )
    } else {
      setCurrentBullet(0)
    }
  }

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.addEventListener('scroll', handleEventScroll)
    }
  }, [sliderRef]) // eslint-disable-line

  useEffect(() => {
    setShowArrowRight(amountItems > itemsByBlock)
    setNumberBullets(
      amountItems > itemsByBlock ? Math.ceil(amountItems / itemsByBlock) : 1,
    )
  }, [amountItems]) // eslint-disable-line

  return {
    sliderRef,
    showArrowLeft,
    showArrowRight,
    numberBullets,
    currentBullet,
    handleScrollArrows,
    handleScrollBullet,
  }
}
