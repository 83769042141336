import { makeStyles } from 'tss-react/mui'
import { chipClasses, typographyClasses } from '@mui/material'
import { getBorderColorByMode } from '@/constants'

interface Props {
  status: string
}

export const useMissionItemStyles = makeStyles<Props>()((theme, { status }) => {
  return {
    missionItemHistory: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      background: theme.palette.background.default,
      border: `1px solid ${getBorderColorByMode(theme, 0.1)}`,
      boxShadow: `0px 4px 20px 0px rgba(0, 0, 0, 0.05)`,
      height: 58,
      borderRadius: 8,
      gap: 6,
      cursor: 'pointer',

      '& .missionContentIcon': {
        '& .icon img': {
          width: 40,
          height: 40,
        },
      },

      '& .missionItemFullDense': {
        [theme.breakpoints.down('sm')]: {
          paddingTop: 0,
          marginRight: 0,
        },

        '& .missionContentVertical': {
          '& .missionCircularProgress': {
            width: '37px !important',
            height: '37px !important',
            top: '4px !important',
          },

          '& .missionProgress .icon': {
            left: 1.5,
            top: 2,

            '& svg': {
              width: 42,
            },
          },
        },
      },

      '& .missionItemHistoryText': {
        [`& .${typographyClasses.body2}`]: {
          fontSize: 12,
          lineHeight: '18px',
        },
      },

      '& .missionChip': {
        position: 'absolute',
        top: 4,
        right: 4,

        [`& .${chipClasses.root}`]: {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
          fontWeight: 600,
          fontSize: 10,
        },
      },
    },
  }
})
