import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, CircularProgress, Divider } from '@mui/material'

import { RootState } from '@/state'
import { NoContentIconComponent } from '@/components/common'
import { SidebarMenuItemType } from '@/enums'

import { ActiveBundleCustomComponent } from './active-bundle.component'

type Props = {
  handleChangeMenuItem: (sidebarMenuItem: string) => void
}

export const MissionBundlesCustomComponent = ({
  handleChangeMenuItem,
}: Props) => {
  const { t } = useTranslation()

  const { missionActiveBundles, isLoading } = useSelector(
    ({ missionHistory }: RootState) => missionHistory,
  )

  const filteredMissionBundles = missionActiveBundles?.filter(
    (bundle) => bundle.endDate >= new Date().toISOString(),
  )

  return (
    <>
      {filteredMissionBundles?.length > 0 && !isLoading ? (
        <Box>
          {filteredMissionBundles.map((bundle, index) => {
            return (
              <Box mb={2} key={bundle._id}>
                <ActiveBundleCustomComponent bundle={bundle} />
                {index < missionActiveBundles.length - 1 && (
                  <Divider sx={{ marginTop: 3, opacity: 0.6 }} />
                )}
              </Box>
            )
          })}
        </Box>
      ) : (
        <>
          {isLoading || missionActiveBundles === null ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                minHeight: 300,
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box minHeight={400} display="flex" alignItems="center">
              <NoContentIconComponent
                title={t('noMissions')}
                message={t('noMissionsMessage')}
                buttonAction={() => {
                  handleChangeMenuItem(SidebarMenuItemType.HOME)
                }}
                buttonText={t('goHome')}
              />
            </Box>
          )}
        </>
      )}
    </>
  )
}
