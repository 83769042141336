import { RewardTypeEnum } from '@gamanza-engage/mf-enums'
import { RewardProcessorBoosterTypeEnum } from '@/enums'
import { ReactComponent as TokenBooster } from '@/assets/icons/gamification/icon-token-booster.svg'

import { ReactComponent as LevelBooster } from '@/assets/icons/gamification/icon-level-booster.svg'
import { ReactComponent as MissionBooster } from '@/assets/icons/missions/icon-mission-booster.svg'
import LevelBoosterMobile from '@/assets/images/rewards/level-booster-mobile.png'

import TokenBoosterMobile from '@/assets/images/rewards/token-booster-mobile.png'
import MissionBoosterMobile from '@/assets/images/rewards/mission-booster-mobile.png'

export const boostersInfo = {
  [RewardTypeEnum.LEVEL_BOOSTER]: {
    title: 'levelBooster',
    rewardProcessorType: RewardProcessorBoosterTypeEnum.LEVEL,
    image: <LevelBooster />,
    mobileImage: LevelBoosterMobile,
  },
  [RewardTypeEnum.TOKEN_BOOSTER]: {
    title: 'tokenBooster',
    rewardProcessorType: RewardProcessorBoosterTypeEnum.TOKEN,
    image: <TokenBooster />,
    mobileImage: TokenBoosterMobile,
  },
  [RewardTypeEnum.MISSION_BOOSTER]: {
    title: 'missionBooster',
    rewardProcessorType: RewardProcessorBoosterTypeEnum.MISSION,
    image: <MissionBooster />,
    mobileImage: MissionBoosterMobile,
  },
}
