import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { RewardStatusEnum } from '@gamanza-engage/mf-enums'
import {
  MissionHistoryThunks,
  MissionHistoryModels,
} from '@/state/mission-history'

export const claimMultipleRewardsExtraReducer = (
  builder: ActionReducerMapBuilder<MissionHistoryModels.MissionHistoryState>,
) => {
  builder.addCase(
    MissionHistoryThunks.claimMissionRewardsThunk.fulfilled,
    (state, { meta }) => {
      const { bundleStateId, missionStateId } = meta.arg

      const currentBundles = state.missionActiveBundles?.map((bundle) => {
        if (bundle._id === bundleStateId) {
          const updateMissions = bundle.missionIds.map((mission) => {
            if (mission.missionId === missionStateId) {
              return {
                ...mission,
                rewardClaimStatus: {
                  ...mission.rewardClaimStatus,
                  status: RewardStatusEnum.CLAIMED,
                },
              }
            } else {
              return mission
            }
          })

          return {
            ...bundle,
            missionIds: updateMissions,
          }
        } else {
          return bundle
        }
      })

      state.missionActiveBundles = currentBundles || state.missionActiveBundles
    },
  )
}
