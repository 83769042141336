import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  alpha,
  colors,
} from '@mui/material'

import { RootState } from '@/state'
import {
  ContentIconComponent,
  PaginationComponent,
  SkeletonLoader,
} from '@/components/common'
import {
  OrderStatusStyles,
  ImageDefault,
  JPDefaultBoosterImage,
} from '@/constants'

import { useBreakpoints } from '@/hooks'
import { getFormatNumber } from '@/utils'
import { useStyles, useOrderActions } from './'

export const RewardShopHistoryComponent = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const { orders, pagination, isLoading } = useSelector(
    ({ orders }: RootState) => orders,
  )

  const { playerLocale, client } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const { handlePrevPage, handleNextPage } = useOrderActions()

  const { isMobile } = useBreakpoints()

  return (
    <>
      {!isLoading && orders.length > 0 && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow className={classes.row}>
                <TableCell width={50} />
                <TableCell width={isMobile ? 90 : 200}>{t('status')}</TableCell>
                <TableCell>{isMobile ? t('qty') : t('quantity')}</TableCell>
                <TableCell>{t('name')}</TableCell>
                <TableCell>{t('purchasedDate')}</TableCell>
                <TableCell>{t('price')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => {
                const { icon, color } = OrderStatusStyles[order.status] || {
                  icon: <></>,
                  color: colors.common.black,
                }

                const translation =
                  order.item.translations?.find(
                    (item) =>
                      item.language.toUpperCase() ===
                      playerLocale.toUpperCase(),
                  ) || order.item.translations?.[0]

                return (
                  <TableRow className={classes.row} key={order._id}>
                    <TableCell>
                      <Box borderRadius={20}>
                        <img
                          alt={translation?.name || order.item.name}
                          src={
                            order.item.mobileImage ||
                            (client === 'JP'
                              ? JPDefaultBoosterImage
                              : ImageDefault)
                          }
                          className={classes.img}
                        />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Chip
                        icon={icon}
                        label={t(order.status)}
                        variant="outlined"
                        className={classes.chip}
                        sx={{
                          color: color,
                          backgroundColor: alpha(color, 0.2),
                          borderColor: color,
                        }}
                      />
                    </TableCell>
                    <TableCell>{order.quantity}</TableCell>
                    <TableCell>
                      {translation?.name || order.item.name}
                    </TableCell>
                    <TableCell>
                      {new Intl.DateTimeFormat(playerLocale, {
                        day: '2-digit',
                        month: isMobile ? '2-digit' : 'long',
                        year: 'numeric',
                      }).format(new Date(order.dateOfPurchase))}
                    </TableCell>
                    <TableCell>{`${getFormatNumber(
                      order.price.virtualCurrencyPrice,
                      playerLocale,
                    )} ${t('tokens')}`}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {orders.length === 0 && !isLoading && (
        <ContentIconComponent
          iconType="search"
          title={t('noPurchasesYet')}
          message={t('noProductsHaveBeenBought')}
          hideButtonContent
        />
      )}

      {isLoading && <SkeletonLoader element="table" />}

      {pagination.pages > 1 && (
        <Box mt={3}>
          <PaginationComponent
            pagination={pagination}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
          />
        </Box>
      )}
    </>
  )
}
