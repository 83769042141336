import { makeStyles } from 'tss-react/mui'
import { cardActionAreaClasses } from '@mui/material'
import { getBorderColorByMode } from '@/constants'

export const useStylesDense = makeStyles()((theme) => {
  return {
    card: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 8,
      borderRadius: 8,
      gap: 8,
      boxShadow: 'none',
      border: `1px solid ${getBorderColorByMode(theme)}`,
      [`& .${cardActionAreaClasses.root}`]: {
        height: 'auto',
      },
      backgroundColor: theme.palette.background.default,
    },
    cardArea: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 8,
    },
    cardMedia: {
      width: 45,
      height: 45,
      borderRadius: 6,
    },
    cardContent: {
      padding: '0 !important',
    },
    title: {
      fontWeight: 600,
      fontSize: 12,
      lineHeight: 1.2,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
    },
    description: {
      fontSize: '10px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
    },
  }
})
