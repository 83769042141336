import { ReactComponent as IconSidebarShop } from '@/assets/icons/icon-sidebar-shop.svg'
import { ReactComponent as IconSidebarHome } from '@/assets/icons/icon-sidebar-home.svg'
import { ReactComponent as IconSidebarMissions } from '@/assets/icons/icon-sidebar-missions.svg'

import { SidebarMenuItemType } from '@/enums'

export const getSidebarMenuOptions = (missionsEnable: boolean) => [
  /* TODO: This menu options will be use in the future
  [SidebarMenuItemType.PROFILE]: {
    id: SidebarMenuItemType.PROFILE,
    label: 'profile',
    icon: <IconSidebarProfile />,
  },
  [SidebarMenuItemType.TOURNAMENTS]: {
    id: SidebarMenuItemType.TOURNAMENTS,
    label: 'tournaments',
    icon: <IconSidebarTournaments />,
  }, */
  {
    id: SidebarMenuItemType.REWARD_SHOP,
    label: 'shop',
    icon: <IconSidebarShop />,
    isVisible: true,
  },
  {
    id: SidebarMenuItemType.HOME,
    label: 'home',
    icon: <IconSidebarHome />,
    isVisible: true,
  },

  {
    id: SidebarMenuItemType.MISSIONS,
    label: 'missions',
    icon: <IconSidebarMissions />,
    isVisible: missionsEnable,
  },
]
