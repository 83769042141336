import { makeStyles } from 'tss-react/mui'
import { typographyClasses } from '@mui/material'
import { getBorderColorByMode, SidebarColors } from '@/constants'

interface Props {
  menuBackground?: string
  menuActiveBackground?: string
}

export const useSidebarMenuStyles = makeStyles<Props>()((
  theme,
  { menuBackground, menuActiveBackground },
) => {
  const background = menuBackground || SidebarColors(theme).menuBackground

  const backgroundActive =
    menuActiveBackground || SidebarColors(theme).menuActiveBackground

  return {
    sidebarMenu: {
      position: 'absolute',
      bottom: 0,
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      width: '100%',
      background: theme.palette.background.paper,
      padding: 6,
      gap: 6,
      zIndex: 2,
    },

    sidebarMenuItem: {
      width: '100%',
      height: 58,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      background: background,
      borderRadius: 2,
      cursor: 'pointer',
      transition: 'width 1s',
      border: `1px solid ${getBorderColorByMode(theme, 0.08, 0.08)}`,

      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% / 3)',
      },

      '& .menuIcon': {
        maxHeight: 28,
        '& svg path': {
          fill: theme.palette.primary.main,
        },
      },

      [`& .${typographyClasses.body1}`]: {
        display: 'none',
      },

      '&.active': {
        background: backgroundActive,
        minWidth: 100,

        '& .menuIcon': {
          maxHeight: '100%',
          '& svg path': {
            fill: theme.palette.common.white,
          },
        },

        [`& .${typographyClasses.body1}`]: {
          display: 'block',
          fontSize: 10,
          fontWeight: 700,
          textAlign: 'center',
          textTransform: 'uppercase',
          marginTop: -7,
          color: theme.palette.common.white,
        },
      },
    },
  }
})
