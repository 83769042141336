import { useEffect } from 'react'
import Lottie from 'lottie-react'
import { Box } from '@mui/material'

import {
  MyBoosterModels,
  GeneralModels,
  useAppDispatch,
  MyBoosterActions,
} from '@/state'

type Props = {
  booster: MyBoosterModels.ActiveBoosterType
  index?: number
  newBoosterAnimationData: GeneralModels.LottieAnimationData
}

export function NewActiveBoosterItemComponent({
  booster,
  index,
  newBoosterAnimationData,
}: Props) {
  const dispatch = useAppDispatch()

  const changeIsNewBooster = (boosterId: string) => {
    dispatch(MyBoosterActions.updateIsNewBooster(boosterId))
  }

  useEffect(() => {
    setTimeout(() => {
      changeIsNewBooster(booster._id)
    }, 2300)
  }, []) // eslint-disable-line

  return (
    <Box
      sx={{
        width: 40,
        position: 'relative',
        order: index || 'inherit',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          width: 80,
          left: -20,
          top: -20,
          zIndex: 99,
        }}
      >
        <Lottie animationData={newBoosterAnimationData} loop={false} />
      </Box>
    </Box>
  )
}
