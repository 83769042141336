import { useEffect, useRef, useState } from 'react'

export const useNotificationInitial = () => {
  const [isVisible, setIsVisible] = useState(false)

  const containerRef = useRef(null)

  useEffect(() => {
    const timerIn = setTimeout(() => {
      setIsVisible(true)
    }, 250)

    const timerOut = setTimeout(() => {
      setIsVisible(false)
    }, 3000)

    return () => {
      clearTimeout(timerIn)
      clearTimeout(timerOut)
    }
  }, []) // eslint-disable-line

  return {
    isVisible,
    containerRef,
  }
}
