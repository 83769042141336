import {
  Box,
  ClickAwayListener,
  Popper,
  PopperPlacementType,
  ScopedCssBaseline,
} from '@mui/material'
import { usePopperStyles } from './style'

type Props = {
  open: boolean
  anchorEl: null | HTMLElement
  children: React.ReactNode
  placement?: PopperPlacementType
  disablePortal?: boolean
  arrowPosition?: string
  hidePopperItems: () => void
}

export function PopperComponent({
  open,
  anchorEl,
  children,
  placement,
  disablePortal = false,
  hidePopperItems,
  arrowPosition,
}: Props) {
  const { classes } = usePopperStyles()

  return (
    <Popper
      disablePortal={disablePortal}
      className={`${classes.popperWrapper} ${arrowPosition}`}
      open={open}
      placement={placement ?? 'bottom'}
      anchorEl={anchorEl}
      sx={{ zIndex: 10 }}
    >
      <ScopedCssBaseline
        enableColorScheme
        sx={{ backgroundColor: 'transparent' }}
      >
        <ClickAwayListener onClickAway={hidePopperItems}>
          <Box>{children}</Box>
        </ClickAwayListener>
      </ScopedCssBaseline>
    </Popper>
  )
}
