import { createAsyncThunk } from '@reduxjs/toolkit'
import Container from 'typedi'
import { Rest } from '@/utils/api.utils'

import { CatalogModels, WidgetEndpoints } from '@/state'
import { CatalogActionTypes } from '../constants'

const apiService: Rest = Container.get(Rest)

export const getGamesNamesThunk = createAsyncThunk(
  CatalogActionTypes.GET_GAMES_NAMES,
  async (payload: CatalogModels.CatalogListFilters) => {
    try {
      const response: CatalogModels.GetGameNamesResponse =
        await apiService.sendData({
          method: 'GET',
          endpoint: WidgetEndpoints.GET_CATALOG_GAMES,
          queries: payload,
        })

      return response
    } catch (error) {
      throw error
    }
  },
)
