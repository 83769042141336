import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { CatalogThunks, CatalogModels } from '@/state'

export const getGamesNamesExtraReducer = (
  builder: ActionReducerMapBuilder<CatalogModels.CatalogState>,
) => {
  builder.addCase(
    CatalogThunks.getGamesNamesThunk.fulfilled,
    (state, { payload }) => {
      state.games = payload?.docs || []
    },
  )
}
