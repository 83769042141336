import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Box, useTheme } from '@mui/material'
import { RootState } from '@/state'
import {
  ContentIconComponent,
  ErrorPageComponent,
  OrderResponseComponent,
  useShopLayout,
} from '@/components'

import { StandardMenuComponent, JPMenuComponent } from './'

type Props = {
  Div: HTMLElement
}

export function ShopLayoutComponent({ Div }: Props) {
  const { t } = useTranslation()

  const theme = useTheme()

  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const { gamificationOpt } = useSelector(({ player }: RootState) => player)

  const {
    getTabContent,
    getInitialRewardShopProducts,
    orderProduct,
    isError,
    isLoading,
    isNotRewards,
  } = useShopLayout()

  useEffect(() => {
    getInitialRewardShopProducts()
  }, []) // eslint-disable-line

  const isJPClient = client === 'JP'

  return (
    <Box
      bgcolor={isJPClient ? 'transparent' : theme.palette.background.default}
      sx={{ opacity: gamificationOpt?.enable ? 1 : 0.5 }}
    >
      <>
        {!isLoading && (
          <>
            {(!orderProduct || !isJPClient) && !isNotRewards && (
              <Box px={2}>
                {isJPClient ? (
                  <JPMenuComponent Div={Div} />
                ) : (
                  <StandardMenuComponent />
                )}
                {getTabContent()}
              </Box>
            )}
            {isError && isNotRewards && (
              <ErrorPageComponent
                handleTryAgain={getInitialRewardShopProducts}
              />
            )}
            {!isError && isNotRewards && (
              <ContentIconComponent
                iconType="search"
                title={t('noRewardsYet')}
                hideButtonContent
              />
            )}
            {orderProduct && isJPClient && <OrderResponseComponent />}
          </>
        )}
      </>
    </Box>
  )
}
