import { makeStyles } from 'tss-react/mui'

export const usePopperStyles = makeStyles()((theme) => {
  return {
    popperWrapper: {
      margin: '15px 0 !important',
      backgroundColor: theme.palette.background.paper,
      borderRadius: 12,
      overflow: 'visible',

      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        margin: '0 auto',
        width: 15,
        height: 15,
        backgroundColor: 'inherit',
        transform: 'translateY(-50%) rotate(45deg)',
        boxShadow: '-3px -3px 5px -2px rgba(0,0,0,0.1)',
      },

      '&.left:before': {
        left: 20,
        right: 'auto',
      },
      '&.right:before': {
        left: 'auto',
        right: 20,
      },

      '&[data-popper-placement*="top"]::before': {
        bottom: -15,
        top: 'auto',
        transform: 'translateY(-50%) rotate(-135deg)',
      },
    },
  }
})
