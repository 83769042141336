import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'

import { RewardShopTypeEnum } from '@gamanza-engage/mf-enums'
import { RootState } from '@/state'
import { CategoryComponent, useRewardShop } from '@/components/reward-shop'

import { ProductListComponent } from './product-list.component'
import { useStyles } from './style'

export function ProductCategoriesComponent() {
  const { boosterProducts, externalProducts, bonusProducts, xpProducts } =
    useRewardShop()

  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const { classes } = useStyles({ client })

  const categories = [
    {
      rewardType: RewardShopTypeEnum.EXTERNAL,
      products: externalProducts,
      viewCategory:
        (boosterProducts?.length === 0 || boosterProducts === null) &&
        (bonusProducts?.length === 0 || bonusProducts === null) &&
        (xpProducts?.length === 0 || xpProducts === null),
    },
    {
      rewardType: RewardShopTypeEnum.BOOSTER,
      products: boosterProducts,
      viewCategory:
        (externalProducts?.length === 0 || externalProducts === null) &&
        (bonusProducts?.length === 0 || bonusProducts === null) &&
        (xpProducts?.length === 0 || xpProducts === null),
    },
    {
      rewardType: RewardShopTypeEnum.BONUS,
      products: bonusProducts,
      viewCategory:
        (externalProducts?.length === 0 || externalProducts === null) &&
        (boosterProducts?.length === 0 || boosterProducts === null) &&
        (xpProducts?.length === 0 || xpProducts === null),
    },
    {
      rewardType: RewardShopTypeEnum.XP,
      products: xpProducts,
      viewCategory:
        (externalProducts?.length === 0 || externalProducts === null) &&
        (boosterProducts?.length === 0 || boosterProducts === null) &&
        (bonusProducts?.length === 0 || bonusProducts === null),
    },
  ]

  return (
    <Box className={classes.products}>
      {categories.map((item) => (
        <Fragment key={item.rewardType}>
          {item.products?.length > 0 && (
            <>
              {item.viewCategory ? (
                <CategoryComponent specificRewardType={item.rewardType} />
              ) : (
                <ProductListComponent
                  productList={item.products?.slice(0, 8)}
                  rewardType={item.rewardType}
                />
              )}
            </>
          )}
        </Fragment>
      ))}
    </Box>
  )
}
