import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { CatalogThunks, CatalogModels } from '@/state'

export const getProvidersNamesExtraReducer = (
  builder: ActionReducerMapBuilder<CatalogModels.CatalogState>,
) => {
  builder.addCase(
    CatalogThunks.getProvidersNamesThunk.fulfilled,
    (state, { payload }) => {
      state.providers = Object.values(payload).map((item) => item)
    },
  )
}
