import { Button, SxProps } from '@mui/material'
import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
} from '@mui/icons-material'
import { useNavigationArrowsStyles } from './style-arrows'

type Props = {
  showArrowLeft: boolean
  showArrowRight: boolean
  sxProps?: {
    left: SxProps
    right: SxProps
  }
  arrowType?: 'button' | 'arrow'
  handleNext: () => void
  handleBack: () => void
}

export const CarouselDirectionArrowsComponent = ({
  showArrowLeft,
  showArrowRight,
  sxProps,
  arrowType = 'arrow',
  handleNext,
  handleBack,
}: Props) => {
  const { classes } = useNavigationArrowsStyles()

  return (
    <>
      <Button
        className={`${arrowType === 'arrow' ? classes.arrowStyle : classes.buttonStyle} ${classes.baseStyle} left`}
        sx={{
          visibility: showArrowLeft ? 'visible' : 'hidden',
          ...(sxProps?.left || {
            '&.left': {
              left: 0,
            },
          }),
        }}
        onClick={handleBack}
      >
        <ArrowBackIosOutlined />
      </Button>

      <Button
        className={`${arrowType === 'arrow' ? classes.arrowStyle : classes.buttonStyle} ${classes.baseStyle} right`}
        sx={{
          visibility: showArrowRight ? 'visible' : 'hidden',
          ...(sxProps?.right || {
            '&.right': {
              right: 0,
            },
          }),
        }}
        onClick={handleNext}
      >
        <ArrowForwardIosOutlined />
      </Button>
    </>
  )
}
