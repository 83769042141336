import { useState } from 'react'
import { useSelector } from 'react-redux'
import { getData } from 'country-list'

import { useTranslation } from 'react-i18next'
import { RewardShopTypeEnum } from '@gamanza-engage/mf-enums'
import { Button, CircularProgress, Typography, useTheme } from '@mui/material'

import { ArrowForward, InfoOutlined } from '@mui/icons-material'
import { RootState, RewardShopModels } from '@/state'
import { DeliveryTypeEnum } from '@/enums'

import { useBreakpoints, useGlobalUi } from '@/hooks'
import { useRewardShopActions } from './'
import { REGEX_EMAIL, REGEX_EMAIL_LENGTH } from '@/constants'

type Props = {
  product: RewardShopModels.ProductType
}

export const useProductForm = ({ product }: Props) => {
  const { t } = useTranslation()

  const theme = useTheme()

  const { player, gamificationPlayer } = useSelector(
    ({ player }: RootState) => player,
  )

  const { playerId, playerLocale, client } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const { isLoadingOrder } = useSelector(
    ({ rewardShop }: RootState) => rewardShop,
  )

  const { isMobile } = useBreakpoints()

  const { handleCloseDialog } = useGlobalUi()

  const { orderSubmit } = useRewardShopActions()

  const countryCode = client === 'JP' ? 'CH' : player?.shippingAddress?.country

  const initialProduct = {
    quantity: 1,
    sendToEmail: !player?.shippingAddress?.city,
    country:
      getData()?.find((country) => country.code === countryCode)?.code || '',
    city: player?.shippingAddress?.city || '',
    street: player?.shippingAddress?.street || '',
    streetNumber: player?.shippingAddress?.streetNumber || '',
    postalCode: player?.shippingAddress?.postalCode || '',
    receives: player?.name || '',
    activateBooster: false,
  }

  const [productForm, setProductForm] =
    useState<RewardShopModels.ProductFormType>(initialProduct)

  const [editLocation, setEditLocation] = useState<boolean>(false)

  const handleOnChange = (
    field: string,
    newValue: string | number | boolean,
  ) => {
    setProductForm({
      ...productForm,
      [field]: newValue,
    })

    if (newValue === DeliveryTypeEnum.CASINO && editLocation) {
      handleEditLocation()
    }
  }

  const handleOnChangeMultiple = (
    fields: Partial<RewardShopModels.ProductFormType>,
  ) => {
    setProductForm({
      ...productForm,
      ...fields,
    })
  }

  const resetProductForm = () => {
    setProductForm(initialProduct)
  }

  const getDefaultActionProps = (
    formStep: number,
    setFormStep: (formStep: number) => void,
  ) => {
    return {
      actionText: formStep === 1 ? t('buyNow') : t('checkout'),
      showCancel: formStep === 2,
      endIcon: getEndIcon(formStep),
      isFullWidth: isMobile && formStep === 1,
      handleClick: () =>
        formStep === 1 && client !== 'JP' ? setFormStep(2) : handleOnSubmit(),
      handleClose: () => {
        handleCloseDialog()
        setFormStep(1)
        resetProductForm()
      },
    }
  }

  const handleOnSubmit = () => {
    orderSubmit({
      playerId: playerId,
      recipientName: productForm.receives || '-',
      playerLanguage: playerLocale,
      shippingAddress: {
        street: productForm.street,
        streetNumber: productForm.streetNumber,
        city: productForm.city,
        country: productForm.country,
        deliverInCasino: false,
        zipCode: productForm.postalCode,
      },
      dateOfPurchase: new Date().toISOString(),
      itemId: product._id,
      quantity: productForm.quantity,
      comment: {
        comment: `Reward purchased from widgets. ${productForm.sendToEmail ? 'Send to personal email.' : ''}`,
        createdBy: 'By Player',
      },
      activation:
        product?.reward?.type === RewardShopTypeEnum.BOOSTER
          ? productForm.activateBooster
          : true,
    })
  }

  const handleEditLocation = () => {
    setEditLocation(!editLocation)
  }

  // OPTIONS
  const quantityOptions = [1, 2, 3, 4, 5]

  const disableByTokens =
    product?.reward?.price?.virtualCurrencyPrice * productForm.quantity >
    gamificationPlayer.tokens

  const disableByFieldEmpty =
    product?.reward?.type === RewardShopTypeEnum.EXTERNAL &&
    (productForm.sendToEmail
      ? productForm?.receives?.toString().trim() === '' ||
        !(
          REGEX_EMAIL.test(productForm?.receives) &&
          REGEX_EMAIL_LENGTH.test(productForm?.receives)
        )
      : Object.values(productForm).some(
          (value) => value?.toString().trim() === '' || value === null,
        ))

  const disableByStock =
    product?.reward?.type === RewardShopTypeEnum.EXTERNAL &&
    product?.remainingItems < productForm?.quantity

  const disableByRank = product?.ranks?.length > 0

  const getDisableText = () => {
    if (disableByTokens) {
      return t('disabledSubmit')
    }

    if (disableByStock) {
      return t('disabledStock')
    }

    return disableByFieldEmpty
      ? t('requiredFieldMessage')
      : t('requiredRankMessage')
  }

  const getDisableMessage = () => {
    if (
      disableByTokens ||
      disableByFieldEmpty ||
      disableByRank ||
      disableByStock
    )
      return (
        <Typography
          color={theme.palette.text.secondary}
          sx={{
            marginTop: 1,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            fontSize: 12,
          }}
        >
          <InfoOutlined sx={{ fontSize: 16 }} />
          {getDisableText()}
        </Typography>
      )

    return
  }

  const getActionButtons = ({
    actionText,
    showCancel,
    endIcon,
    isFullWidth,
    handleClick,
    handleClose,
    formStep,
  }: {
    actionText: string
    showCancel: boolean
    endIcon: React.ReactNode
    handleClick: () => void
    handleClose: () => void
    isFullWidth: boolean
    formStep?: number
  }) => {
    return (
      <>
        {showCancel && (
          <Button
            variant="text"
            onClick={handleClose}
            sx={{
              width: 100,
              fontSize: 15,
              color: theme.palette.text.secondary,
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            {t('cancel').toUpperCase()}
          </Button>
        )}

        <Button
          variant="contained"
          fullWidth
          disabled={
            disableByTokens ||
            (formStep !== 1 && disableByFieldEmpty) ||
            disableByRank ||
            disableByStock ||
            isLoadingOrder
          }
          sx={{
            width: isFullWidth ? '100%' : 'auto',
            fontSize: 15,
          }}
          onClick={handleClick}
          endIcon={endIcon}
        >
          {actionText.toUpperCase()}
        </Button>
      </>
    )
  }

  const getEndIcon = (formStep: number) => {
    if (formStep === 1) {
      return <ArrowForward />
    }

    if (isLoadingOrder) {
      return (
        <CircularProgress
          size={25}
          sx={{ color: theme.palette.common.white }}
        />
      )
    }
  }

  return {
    disableByFieldEmpty,
    disableByStock,
    disableByRank,
    productForm,
    quantityOptions,
    editLocation,
    handleOnChange,
    handleOnChangeMultiple,
    handleOnSubmit,
    handleEditLocation,
    getDisableMessage,
    getActionButtons,
    getEndIcon,
    resetProductForm,
    getDefaultActionProps,
  }
}
