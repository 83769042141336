import { useCallback } from 'react'
import {
  MissionHistoryModels,
  MissionHistoryThunks,
  MyRewardsActions,
  MyRewardsModels,
  PlayerModels,
  PlayerThunks,
  useAppDispatch,
} from '@/state'

export const useRewardActions = () => {
  const dispatch = useAppDispatch()

  const claimMissionRewards = useCallback(
    (payload: MissionHistoryModels.ClaimMissionRewardsPayload) => {
      dispatch(MissionHistoryThunks.claimMissionRewardsThunk(payload))
    },
    [dispatch],
  )

  const claimPlayerRewards = useCallback(
    (payload: PlayerModels.ClaimPlayerRewardsPayload) => {
      dispatch(PlayerThunks.claimPlayerRewardsThunk(payload))
    },
    [dispatch],
  )

  const removeChest = useCallback(
    (payload: MyRewardsModels.RemoveRewardsType) => {
      dispatch(MyRewardsActions.removeChestAction(payload))
    },
    [dispatch],
  )

  return {
    claimMissionRewards,
    claimPlayerRewards,
    removeChest,
  }
}
