import { SxProps, Theme } from '@mui/material'

export const typographyH6PointsProps = (
  theme: Theme,
  props?: SxProps,
): SxProps<Theme> => {
  return {
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    ...(props || {
      fontWeight: 600,
      fontSize: 12,
      paddingTop: 0.2,
      color: theme.palette.text.secondary,
    }),
  }
}

export const typographyCaptionPointsProps = (
  valueProps?: SxProps,
): SxProps<Theme> => {
  return {
    ...(valueProps || {
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '1',
      paddingTop: 0.2,
      paddingBottom: 0.5,
    }),
  }
}
