import { Box, CircularProgress } from '@mui/material'
import { ActiveBoosterType } from '@/state/my-booster/models'
import { useHideBoostersItem } from '../hooks'

type Props = {
  Div: HTMLElement
  activeBoosters: ActiveBoosterType[]
}

export function HiddenPercentagesBoosterType({ Div, activeBoosters }: Props) {
  const { percentagesByBoosterType, DEFAULT_POSITION } = useHideBoostersItem({
    Div,
    activeBoosters,
  })

  let circularCompleted = 0

  return (
    <Box
      sx={{
        position: 'absolute',
        left: 0,
        top: 0,
      }}
    >
      {percentagesByBoosterType.map((boosterType, index) => {
        if (index > 0)
          circularCompleted += percentagesByBoosterType[index - 1].percentage

        const degree = (360 * circularCompleted) / 100

        return (
          <CircularProgress
            key={`circular-progress-${boosterType.color}`}
            variant="determinate"
            value={boosterType.percentage}
            size={42}
            thickness={4}
            sx={{
              position: 'absolute',
              left: 0,
              color: boosterType.color,
              transform:
                index > 0 &&
                `rotate(${degree - DEFAULT_POSITION}deg) !important`,
            }}
          />
        )
      })}
    </Box>
  )
}
