import { useSelector } from 'react-redux'
import { ReactComponent as IconToken } from '@/assets/icons/gamification/icon-token.svg'
import { ReactComponent as IconTokenJP } from '@/assets/icons/gamification/icon-token-jp.svg'

import { ReactComponent as IconRank } from '@/assets/icons/gamification/icon-rank.svg'
import { ReactComponent as IconRankJP } from '@/assets/icons/gamification/icon-rank-jp.svg'
import { ReactComponent as IconLevel } from '@/assets/icons/gamification/icon-level.svg'

import { ReactComponent as IconLevelJP } from '@/assets/icons/gamification/icon-level-jp.svg'
import { ReactComponent as IconXp } from '@/assets/icons/gamification/icon-xp.svg'
import { ReactComponent as IconXpJP } from '@/assets/icons/gamification/icon-xp-jp.svg'

import { RootState } from '@/state'
import { IconTypeEnum } from '@/enums'

type Props = {
  size?: number
  type: IconTypeEnum
}

export function IconComponent({ size, type }: Props) {
  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const dimension = size ?? 22

  const props = {
    height: dimension,
    width: dimension,
  }

  const getIconByType = () => {
    if (type === IconTypeEnum.LEVEL) {
      return client === 'JP' ? (
        <IconLevelJP {...props} />
      ) : (
        <IconLevel className="paintIcon" {...props} />
      )
    }
    if (type === IconTypeEnum.RANK) {
      return client === 'JP' ? (
        <IconRankJP {...props} />
      ) : (
        <IconRank className="paintIcon" {...props} />
      )
    }
    if (type === IconTypeEnum.TOKEN) {
      return client === 'JP' ? (
        <IconTokenJP {...props} />
      ) : (
        <IconToken {...props} />
      )
    }
    if (type === IconTypeEnum.XP) {
      return client === 'JP' ? (
        <IconXpJP {...props} />
      ) : (
        <IconXp className="paintIcon" {...props} />
      )
    }
  }

  return getIconByType()
}
