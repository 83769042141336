import { createAsyncThunk } from '@reduxjs/toolkit'
import Container from 'typedi'
import { Rest } from '@/utils/api.utils'

import {
  MyBoosterActions,
  MyBoosterModels,
  RootState,
  WidgetEndpoints,
} from '@/state'
import { MyBoosterActionTypes } from '../constants'

const apiService: Rest = Container.get(Rest)

export const getMissionActiveBoostersThunk = createAsyncThunk(
  MyBoosterActionTypes.GET_MISSION_ACTIVE_BOOSTERS,
  async (payload: MyBoosterModels.GetActiveMissionBoosterPayload, thunkAPI) => {
    try {
      const { myBooster } = thunkAPI.getState() as RootState
      if (!myBooster.isLoadingMissionBoosters) {
        thunkAPI.dispatch(MyBoosterActions.setIsLoadingMissionBoosters(true))
        const response: MyBoosterModels.GetActiveBoostersResponse =
          await apiService.sendData({
            method: 'GET',
            endpoint: `${WidgetEndpoints.MISSION_ACTIVE_BOOSTER}/${payload.playerId}`,
          })
        return response
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
