import { useTranslation } from 'react-i18next'
import { CheckCircleOutline, InfoOutlined, Search } from '@mui/icons-material'
import { Avatar, Box, Button, Typography, Grid, SxProps } from '@mui/material'

import { RewardShopActions, useAppDispatch } from '@/state'
import { MenuItemType } from '@/enums'
import { useStyles } from './style'

type Props = {
  title?: string
  message?: string
  messageSxProps?: SxProps
  iconType?: 'search' | 'success' | 'error'
  buttonContent?: React.ReactNode
  hideButtonContent?: boolean
  hideIcon?: boolean
  image?: React.ReactNode
}

export function ContentIconComponent({
  iconType,
  title,
  message,
  messageSxProps,
  buttonContent,
  hideButtonContent,
  hideIcon,
  image,
}: Props) {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const handleChangeMenuItem = (newValue: string) => {
    dispatch(RewardShopActions.setMenuSelected(newValue))
    dispatch(RewardShopActions.resetRewardShopPage())
  }

  const { classes } = useStyles()

  const getIconType = (): React.ReactNode => {
    switch (iconType) {
      case 'search':
        return <Search />

      case 'success':
        return <CheckCircleOutline />

      case 'error':
        return <InfoOutlined />
    }
  }

  return (
    <Box py={6} px={2} className={classes.content}>
      <Grid container>
        {!hideIcon && (
          <Grid item xs={12}>
            {image ? (
              image
            ) : (
              <Avatar className={`${classes.avatar} ${iconType}`}>
                {getIconType()}
              </Avatar>
            )}
          </Grid>
        )}

        {title && (
          <Grid item xs={12}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                marginTop: 4,
                textAlign: 'center',
                fontSize: '20px',
              }}
            >
              {title}
            </Typography>
          </Grid>
        )}
        {message && (
          <Grid item xs={12}>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 500,
                marginTop: 4,
                marginBottom: 2,
                textAlign: 'center',
                ...messageSxProps,
              }}
            >
              {message}
            </Typography>
          </Grid>
        )}
        {!hideButtonContent && (
          <Grid item xs={12}>
            <Box width={180} mt={2} mx="auto">
              {buttonContent ? (
                buttonContent
              ) : (
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => handleChangeMenuItem(MenuItemType.REWARD_SHOP)}
                  sx={{ width: '100%', padding: '10px 0' }}
                >
                  {t('goShopping')}
                </Button>
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}
