export enum MissionEventTypeEnum {
  COMPLETED_DEPOSIT = 'completed_deposit',
  COMPLETED_WITHDRAWAL = 'completed_withdrawal',
  WAGER = 'wager',
  WIN = 'win',
  REFER_A_FRIEND = 'refer_a_friend',
  LOGIN = 'login',
  ACTIVATE_ACCOUNT = 'activate_account',
  ACCOUNT_VERIFICATION = 'account_verification',
  SELF_ASSESSMENT_TEST = 'self_assessment_test',
  OPT_IN = 'opt_in',
  LEVEL_UP = 'level_up',
  SPORT_WIN = 'sport_win',
  SPORT_WAGER = 'sport_wager',
}
