import { Box, Typography } from '@mui/material'
import { DialogComponent } from '@/components/common'
import { UIDialogsEnum } from '@/enums'

type Props = {
  selectedBundleDescription: string
  selectedBundleName: string
  selectedBundleTermsAndConditions: string
}

export const MissionBundleInfoComponent = ({
  selectedBundleDescription,
  selectedBundleName,
  selectedBundleTermsAndConditions,
}: Props) => {
  return (
    <DialogComponent
      dialogId={UIDialogsEnum.MISSION_BUNDLE_INFO}
      title={selectedBundleName}
      titleSxProps={{
        justifyContent: 'left',
      }}
      headerBorder={false}
      overflow="auto"
      content={
        <Box>
          {selectedBundleDescription !== '' && (
            <Box mt={2}>
              <Typography
                variant="caption"
                dangerouslySetInnerHTML={{
                  __html: selectedBundleDescription,
                }}
                fontSize={14}
              />
            </Box>
          )}
          {selectedBundleTermsAndConditions !== '' && (
            <Box>
              <Typography
                variant="caption"
                dangerouslySetInnerHTML={{
                  __html: selectedBundleTermsAndConditions,
                }}
                fontSize={14}
              />
            </Box>
          )}
        </Box>
      }
      maxWidth={800}
    />
  )
}
