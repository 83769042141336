import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, SxProps, useTheme } from '@mui/material'

import { getAssetsUrl, getLottieAnimationData } from '@/utils'
import { RootState } from '@/state'
import { TokensLottieAnimationComponent } from './'

type Props = {
  Div: HTMLElement
  name?: string
  flexDirection?: 'row' | 'column'
  smallWinSxProps?: SxProps
  bigWinSxProps?: SxProps
  valueSxProps?: SxProps
  iconSize?: number
}

export function TokensProfileAnimationComponent({
  Div,
  name,
  flexDirection = 'row',
  smallWinSxProps,
  bigWinSxProps,
  valueSxProps,
  iconSize,
}: Props) {
  const { baseUrl, client } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const { gamificationOpt } = useSelector(({ player }: RootState) => player)

  const theme = useTheme()

  // Get Data Attributes
  const justifyContent = Div.getAttribute('data-justify-content')
    ? Div.getAttribute('data-justify-content')
    : client === 'JP'
      ? 'center'
      : 'flex-end'

  const textColor = Div.getAttribute('data-text-color')
    ? Div.getAttribute('data-text-color')
    : theme.palette.primary.main

  const [animationData, setAnimationData] = useState(null)

  // TODO: We would use this animation state in the future
  // const [bigWinAnimationData, setBigWinAnimationData] = useState(null)

  const getAnimationUrl = (animationPath: string) => {
    return getAssetsUrl(baseUrl, animationPath)
  }

  useEffect(() => {
    if (animationData === null) {
      getLottieAnimationData(
        getAnimationUrl(
          client === 'JP'
            ? 'assets/animations/small-big-win/jackpots/small-win.lottie'
            : 'assets/animations/small-big-win/standard/small-win.lottie',
        ),
        setAnimationData,
      )
    }

    // TODO: We would use this fetch animation in the future
    // if (bigWinAnimationData === null) {
    //   getLottieAnimationData(
    //     getAnimationUrl(
    //       client === 'JP'
    //         ? 'assets/animations/small-big-win/jackpots/big-win.lottie'
    //         : 'assets/animations/small-big-win/standard/big-win.lottie',
    //     ),
    //     setBigWinAnimationData,
    //   )
    // }
  }, []) // eslint-disable-line

  return (
    <Box>
      {gamificationOpt?.enable && (
        <Box
          display="inline-flex"
          alignItems="center"
          justifyContent={justifyContent}
          gap={flexDirection === 'row' ? 0.5 : 0}
          position="relative"
          flexDirection={flexDirection}
        >
          <TokensLottieAnimationComponent
            textColor={textColor}
            animationData={animationData}
            name={name}
            flexDirection={flexDirection}
            smallWinSxProps={smallWinSxProps}
            bigWinSxProps={bigWinSxProps}
            valueSxProps={valueSxProps}
            iconSize={iconSize}
            // TODO: We would use this animation in the future
            // bigWinAnimationData={bigWinAnimationData}
          />
        </Box>
      )}
    </Box>
  )
}
