import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, Tab, Tabs, SvgIcon, Typography, Icon } from '@mui/material'

import { grey } from '@mui/material/colors'
import { MenuListOptions } from '@/constants'
import { MenuItemType } from '@/enums'

import { useBreakpoints } from '@/hooks'
import { RootState } from '@/state'
import { useMenu } from '@/components/shop-layout/hooks'

import { useStyles } from './standard-style'
import { BreadcrumbsComponent } from './'

export function StandardMenuComponent() {
  const { t } = useTranslation()

  const { myBoostersCount } = useSelector(
    ({ myBooster }: RootState) => myBooster,
  )

  const { menuSelected, currentProduct, rewardType } = useSelector(
    ({ rewardShop }: RootState) => rewardShop,
  )

  const { images } = useSelector(({ assets }: RootState) => assets)

  const { gamificationOpt } = useSelector(({ player }: RootState) => player)

  const { isDesktop } = useBreakpoints()

  const { classes } = useStyles()

  const { handleChangeMenuItem, getLastBreadcrumbs } = useMenu()

  const handleChange = (newValue: string) => {
    handleChangeMenuItem(newValue)
  }

  const getIconSvg = (id: string): string | false => {
    switch (id) {
      case MenuItemType.REWARD_SHOP:
        return images?.menu_rewards_image || false

      case MenuItemType.BOOSTERS:
        return images?.menu_booster_image || false

      default:
        return false
    }
  }

  return (
    <>
      <Tabs
        value={menuSelected}
        onChange={(_, newValue) => handleChange(newValue)}
        sx={{
          borderBottom: `1px solid ${grey[200]}`,
        }}
      >
        {Object.values(MenuListOptions).map((item) => {
          const icon = getIconSvg(item.id)
          return (
            <Tab
              key={item.id}
              className={classes.tabContent}
              value={item.id}
              onClick={() => {
                if (item.id === MenuItemType.REWARD_SHOP) {
                  handleChange(item.id)
                }
              }}
              disabled={!gamificationOpt?.enable || false}
              label={
                <>
                  <Typography
                    className="tabLabel"
                    fontWeight={item.id === menuSelected ? 'bold' : 'normal'}
                  >
                    {t(`${item.label}`)}
                  </Typography>
                  {item.id === MenuItemType.BOOSTERS &&
                    myBoostersCount !== 0 && (
                      <Box className="boostersCount">
                        <Typography variant="caption" lineHeight="23px">
                          {myBoostersCount}
                        </Typography>
                      </Box>
                    )}
                </>
              }
              icon={
                isDesktop ? (
                  typeof icon === 'string' ? (
                    <Icon>
                      <img
                        src={icon}
                        alt={item.label}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain',
                        }}
                      />
                    </Icon>
                  ) : (
                    <SvgIcon component={item.icon} />
                  )
                ) : undefined
              }
            />
          )
        })}
      </Tabs>
      {menuSelected === MenuItemType.REWARD_SHOP &&
        (currentProduct || rewardType !== '') && (
          <BreadcrumbsComponent
            handleChangeMenuItem={handleChangeMenuItem}
            getLastBreadcrumbs={getLastBreadcrumbs}
          />
        )}
    </>
  )
}
