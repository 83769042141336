import { Box, Button, buttonClasses, Typography } from '@mui/material'
import { ArrowForward } from '@mui/icons-material'
import { NotificationSnackbarComponent } from '@/components/common'

import { useBreakpoints, useGlobalUi } from '@/hooks'
import { ModalProgressContentComponent } from './'
import { useStyles } from './styles'

import { useNotificationAnimations, useNotifications } from '../hooks'

export const NotificationsComponent = () => {
  const { modal, handleOpenModal } = useGlobalUi()

  const { classes } = useStyles({})

  const { isMobile } = useBreakpoints()

  const { NotificationData } = useNotifications()

  const { initialAnimationData, finalAnimationData } =
    useNotificationAnimations()

  return (
    <>
      {!modal.open && (
        <NotificationSnackbarComponent>
          <Box className={classes.notificationContainer}>
            <Box>
              <Typography fontWeight={700} lineHeight={1.2}>
                {NotificationData[modal.type]?.title}
              </Typography>
              <Typography lineHeight={1.2} sx={{ paddingTop: 0.5 }}>
                {NotificationData[modal.type]?.description}
              </Typography>
            </Box>
            <Button
              variant="contained"
              onClick={handleOpenModal}
              sx={{
                minWidth: 'auto',
                [`.${buttonClasses.endIcon}`]: {
                  ml: isMobile ? 0 : 1,
                },
              }}
              endIcon={<ArrowForward />}
            >
              {!isMobile && NotificationData[modal.type]?.button}
            </Button>
          </Box>
        </NotificationSnackbarComponent>
      )}

      {modal.open && (
        <ModalProgressContentComponent
          initialAnimationData={initialAnimationData}
          finalAnimationData={finalAnimationData}
        />
      )}
    </>
  )
}
