import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, Button, Typography } from '@mui/material'

import { RootState } from '@/state'
import { useBreakpoints } from '@/hooks'
import {
  ActiveBoosterItemComponent,
  HiddenPercentagesBoosterType,
  PopperBoosterComponent,
} from './'

import { useStyles } from './style'
import { useActiveBoosters } from '../hooks'

type Props = {
  Div: HTMLElement
  closedVersion?: boolean
  isSidebarVersion?: boolean
}

export function ActiveBoosterComponent({
  Div,
  closedVersion = false,
  isSidebarVersion = false,
}: Props) {
  const { t } = useTranslation()

  const {
    showPopperItems,
    handleShowPopperItems,
    handleHidePopperItems,
    handleCurrentBooster,
    currentBooster,
    mainBoosters,
    secondaryBoosters,
    anchorEl,
    hideActiveBoosters,
  } = useActiveBoosters({ closedVersion, isSidebarVersion })

  const { gamificationOpt } = useSelector(({ player }: RootState) => player)

  const { classes: classesStyle } = useStyles({ closedVersion })

  const { isMobile } = useBreakpoints()

  return (
    <>
      {gamificationOpt.enable && mainBoosters?.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            position: 'relative',
            overflow: 'initial',
            zIndex: 2,
          }}
        >
          <Box className={classesStyle.boostersContainer}>
            {mainBoosters?.map((booster, index) => {
              const activeIndex =
                mainBoosters?.length + secondaryBoosters.length - (index + 1)
              return (
                <ActiveBoosterItemComponent
                  Div={Div}
                  index={activeIndex}
                  booster={booster}
                  key={`active-booster-${booster._id}`}
                  handleCurrentBooster={handleCurrentBooster}
                  currentBooster={currentBooster}
                  closedVersion={closedVersion}
                />
              )
            })}
            {!closedVersion && !!secondaryBoosters.length && (
              <Button
                className={classesStyle.showAllItems}
                onClick={(event) => {
                  !closedVersion && handleShowPopperItems(event)
                }}
              >
                <Typography>{`${secondaryBoosters.length}+`}</Typography>
                <HiddenPercentagesBoosterType
                  activeBoosters={secondaryBoosters}
                  Div={Div}
                />
              </Button>
            )}
          </Box>
          <PopperBoosterComponent
            Div={Div}
            popperBoosters={secondaryBoosters}
            open={showPopperItems}
            hidePopperItems={handleHidePopperItems}
            anchorEl={anchorEl}
          />
        </Box>
      )}

      {isSidebarVersion &&
        hideActiveBoosters &&
        mainBoosters &&
        (!isMobile || (isMobile && !closedVersion)) && (
          <Typography variant="caption" className={classesStyle.noDataText}>
            {t('noActiveBoosters')}
          </Typography>
        )}
    </>
  )
}
