import { useSelector } from 'react-redux'
import { Box, Typography, typographyClasses, useTheme } from '@mui/material'
import { IconTypeEnum } from '@/enums'

import { getFormatNumber } from '@/utils'
import { RootState } from '@/state'
import { IconComponent } from '../icon'

type PointCardProps = {
  tokensAmount: number
}

export function PlayerTokensComponent({ tokensAmount }: PointCardProps) {
  const theme = useTheme()

  const { playerLocale, client } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  return (
    <Box
      display="flex"
      alignItems="center"
      position="absolute"
      bottom={10}
      sx={{
        [`& .${typographyClasses.body2}`]: {
          fontWeight: 700,
          ...(client === 'JP'
            ? {
                color: theme.palette.text.primary,
                fontStyle: 'italic',
                fontSize: 20,
                pl: 1,
              }
            : {
                color: theme.palette.primary.main,
                fontSize: 16,
                pl: 1,
              }),
        },
      }}
    >
      <IconComponent type={IconTypeEnum.TOKEN} size={25} />
      <Typography variant="body2">
        {getFormatNumber(tokensAmount, playerLocale)}
      </Typography>
    </Box>
  )
}
