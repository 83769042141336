import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RewardTypeEnum } from '@gamanza-engage/mf-enums'

import { ActiveBoosterType, BoosterDataType } from '@/state/my-booster/models'
import { boostersInfo } from '@/constants'

type Props = {
  timeStart: string
  timeEnd: string
  Div: HTMLElement
  booster: ActiveBoosterType
}

export const useActiveBoostersItem = ({
  timeStart,
  timeEnd,
  Div,
  booster,
}: Props) => {
  const [animationBoosterFinished, setAnimationBoosterFinished] =
    useState(false)

  const [currentBreakAnimation, setCurrentBreakAnimation] = useState(0)

  const { t } = useTranslation()

  const boosterColors = {
    [RewardTypeEnum.LEVEL_BOOSTER]: {
      baseColor: Div.getAttribute('data-level-base') || '',
      background: Div.getAttribute('data-level-background') || '',
      textColor: Div.getAttribute('data-level-text') || '',
    },
    [RewardTypeEnum.TOKEN_BOOSTER]: {
      baseColor: Div.getAttribute('data-token-base') || '',
      background: Div.getAttribute('data-token-background') || '',
      textColor: Div.getAttribute('data-token-text') || '',
    },
    [RewardTypeEnum.MISSION_BOOSTER]: {
      baseColor: Div.getAttribute('data-mission-base') || '',
      background: Div.getAttribute('data-mission-background') || '',
      textColor: Div.getAttribute('data-mission-text') || '',
    },
  }

  const [progress, setProgress] = useState(0)
  const [timeRemaining, setTimeRemaining] = useState(null)
  const [boosterData, setBoosterData] = useState<BoosterDataType>({
    title: '',
    colors: null,
    image: null,
  })

  const percentageProgress = progress * 100

  useEffect(() => {
    const setCurrentAnimation = (percent: number) => {
      const currentAnimation = 9 - Math.floor(percent)

      setCurrentBreakAnimation(currentAnimation)
    }

    if (percentageProgress < 10 && percentageProgress > 0) {
      setCurrentAnimation(percentageProgress)
    }
  }, [percentageProgress])

  const getBoosterPercentageColor = () => {
    if (percentageProgress < 10) {
      return 'alert'
    }
    if (percentageProgress < 25) {
      return 'warning'
    }
    return booster.boosterType
  }

  useEffect(() => {
    setBoosterData({
      title: t(boostersInfo[booster.boosterType]?.title),
      colors: boosterColors[RewardTypeEnum.LEVEL_BOOSTER],
      image: boostersInfo[booster.boosterType]?.image,
    })
  }, []) // eslint-disable-line

  useEffect(() => {
    const startTime = new Date(timeStart).getTime()
    const endTime = new Date(timeEnd).getTime()

    const totalTime = endTime - startTime
    const timerInterval = setInterval(() => {
      const now = new Date().getTime()
      const remaining = Math.max(endTime - now, 0)
      const currentProgress = Math.min((totalTime - remaining) / totalTime, 1)
      setProgress(1 - currentProgress)

      const days = Math.floor(remaining / (1000 * 60 * 60 * 24))
      const hours = Math.floor(
        (remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      )
      const minutes = Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60))

      const seconds = Math.floor((remaining % (1000 * 60)) / 1000)

      const setTimeRemainingValidation = () => {
        if (days > 0) return setTimeRemaining(`${days}d ${hours}h`)

        if (hours > 0)
          return setTimeRemaining(
            `${hours}h ${minutes >= 1 ? `${minutes}m` : ''}`,
          )

        setTimeRemaining(
          `${minutes >= 1 ? `${minutes}m` : ''} ${`${seconds}s`}`,
        )
      }

      setTimeRemainingValidation()

      if (remaining < 1250) {
        setAnimationBoosterFinished(true)
      }

      if (remaining === 0) {
        clearInterval(timerInterval)
      }
    }, 1000)

    return () => clearInterval(timerInterval)
  }, []) // eslint-disable-line

  return {
    progress,
    percentageProgress,
    timeRemaining,
    boosterData,
    animationBoosterFinished,
    currentBreakAnimation,
    getBoosterPercentageColor,
  }
}
