import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { MyBoosterThunks, RootState, useAppDispatch } from '@/state'

import { ActiveBoosterType } from '@/state/my-booster/models'
import { useBreakpoints } from '@/hooks'

type Props = {
  closedVersion?: boolean
  isSidebarVersion?: boolean
}

export const useActiveBoosters = ({
  closedVersion,
  isSidebarVersion,
}: Props) => {
  const dispatch = useAppDispatch()

  const [showPopperItems, setShowPopperItems] = useState(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [currentBooster, setCurrentBooster] = useState('')

  const { activeBoosters, hideActiveBoosters } = useSelector(
    ({ myBooster }: RootState) => myBooster,
  )

  const { playerId } = useSelector(({ initConfig }: RootState) => initConfig)

  const { missionsEnable } = useSelector(
    ({ globalSettings }: RootState) => globalSettings,
  )

  const [mainBoosters, setMainBoosters] = useState<ActiveBoosterType[]>()
  const [secondaryBoosters, setSecondaryBoosters] = useState<
    ActiveBoosterType[]
  >([])

  const { isCustomXsMobile, isMobile } = useBreakpoints()

  const amountOfItems = isCustomXsMobile || (isMobile && closedVersion) ? 1 : 2

  const sortByTimeStartDesc = (a: ActiveBoosterType, b: ActiveBoosterType) => {
    return new Date(b.timeStart).getTime() - new Date(a.timeStart).getTime()
  }

  const sortByTimeEndAsc = (a: ActiveBoosterType, b: ActiveBoosterType) => {
    return new Date(a.timeEnd).getTime() - new Date(b.timeEnd).getTime()
  }

  useEffect(() => {
    if (activeBoosters.length > 0) {
      const recentActivateBoosters: ActiveBoosterType[] = activeBoosters
        .filter((booster) => booster.isRecentBooster)
        .sort(sortByTimeStartDesc)

      const upcomingActiveBoosters: ActiveBoosterType[] = activeBoosters
        .filter((booster) => !booster.isRecentBooster)
        .sort(sortByTimeEndAsc)

      const mainBoostersList: ActiveBoosterType[] = []
      const secondaryBoostersList: ActiveBoosterType[] = []

      if (recentActivateBoosters.length >= amountOfItems) {
        mainBoostersList.push(...recentActivateBoosters.slice(0, amountOfItems))
        secondaryBoostersList.push(
          ...recentActivateBoosters.slice(amountOfItems),
          ...upcomingActiveBoosters,
        )
      } else {
        mainBoostersList.push(...recentActivateBoosters)
        mainBoostersList.push(
          ...upcomingActiveBoosters.slice(
            0,
            amountOfItems - recentActivateBoosters.length,
          ),
        )

        secondaryBoostersList.push(
          ...upcomingActiveBoosters.slice(
            amountOfItems - recentActivateBoosters.length,
          ),
        )
      }

      setMainBoosters(mainBoostersList)
      setSecondaryBoosters(secondaryBoostersList)
    } else {
      setMainBoosters([])
    }
  }, [activeBoosters, closedVersion]) // eslint-disable-line

  // TODO: When the clients only use the active boosters from sidebar widgets we can remove this useEffect
  useEffect(() => {
    if (activeBoosters.length === 0 && !isSidebarVersion) {
      dispatch(MyBoosterThunks.getLevelActiveBoostersThunk())
      dispatch(MyBoosterThunks.getTokenActiveBoostersThunk())
    }

    if (missionsEnable) {
      dispatch(
        MyBoosterThunks.getMissionActiveBoostersThunk({
          playerId: playerId,
        }),
      )
    }
  }, [missionsEnable, isSidebarVersion]) // eslint-disable-line

  const handleShowPopperItems = (event?: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
    setShowPopperItems(!showPopperItems)
  }

  const handleHidePopperItems = () => {
    setAnchorEl(null)
    setShowPopperItems(false)
  }

  const handleCurrentBooster = (currentId: string) => {
    setCurrentBooster(currentId === currentBooster ? '' : currentId)
  }

  return {
    showPopperItems,
    handleShowPopperItems,
    handleHidePopperItems,
    handleCurrentBooster,
    currentBooster,
    mainBoosters,
    secondaryBoosters,
    anchorEl,
    hideActiveBoosters,
  }
}
