import { createAsyncThunk } from '@reduxjs/toolkit'
import Container from 'typedi'
import { Rest } from '@/utils/api.utils'

import { PlayerModels, WidgetEndpoints } from '@/state'
import { PlayerActionTypes } from '../constants'

const apiService: Rest = Container.get(Rest)

export const getTokenDetailsThunk = createAsyncThunk(
  PlayerActionTypes.GET_TOKEN_DETAILS,
  async (_) => {
    try {
      const response: PlayerModels.GetTokenDetailsResponse =
        await apiService.sendData({
          method: 'GET',
          endpoint: WidgetEndpoints.TOKENS,
        })

      /* TODO: This code could be used later
      const success = response?.statusCode === HttpStatusCode.OK

      if (!success) {
        thunkApi.dispatch(
          globalUiActions.setSnackbar({
            message: response.message,
            open: true,
            severity: SnackbarSeverityEnum.ERROR,
          }),
        )
        thunkApi.dispatch(PlayerActions.resetGamificationData())
      } */

      return response
    } catch (error) {
      throw error
    }
  },
)
