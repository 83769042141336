import { useSelector } from 'react-redux'
import { Box, Typography, LinearProgress, SxProps } from '@mui/material'
import { RootState } from '@/state'

import { useProgressBarStyles } from './style'

type Props = {
  progressValue: number
  icon?: React.ReactNode
  balanceText?: string
  balanceIcon?: React.ReactNode
  progressColor?: string
  iconSxProps?: SxProps
  heightProgress?: number
  amountLinearBars?: number
}

export function ProgressBarComponent({
  progressValue,
  icon,
  balanceText,
  balanceIcon,
  progressColor,
  iconSxProps,
  heightProgress,
  amountLinearBars,
}: Props) {
  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const { classes } = useProgressBarStyles({
    progressColor,
    heightProgress,
    amountLinearBars,
  })

  const linearBars = Array.from(
    new Array(amountLinearBars || 3),
    (_, index) => (
      <Box className={`linearBar linearBar_${index}`} key={`border_${index}`} />
    ),
  )

  return (
    <Box className={classes.progressWrapper}>
      <Box className={classes.progressContainer}>
        <Box className="linearBars">{linearBars}</Box>
        <LinearProgress variant="determinate" value={progressValue} />
        {!!balanceText && (
          <Box className="balance">
            {!!balanceIcon && balanceIcon}
            <Typography
              variant="caption"
              fontStyle={client === 'JP' ? 'italic' : 'normal'}
            >
              {balanceText}
            </Typography>
          </Box>
        )}
        {!!icon && (
          <Box
            zIndex={1}
            position="absolute"
            right={-10}
            display="flex"
            sx={{ ...iconSxProps }}
          >
            {icon}
          </Box>
        )}
      </Box>
    </Box>
  )
}
