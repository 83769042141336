import { ScopedCssBaseline } from '@mui/material'
import {
  DialogChestAnimationComponent,
  NotificationsComponent,
  OnSiteNotificationsComponent,
  SnackbarComponent,
} from './components'
import './init'

export function GlobalUIComponents() {
  return (
    <ScopedCssBaseline
      enableColorScheme
      sx={{ backgroundColor: 'transparent' }}
    >
      <SnackbarComponent />
      <NotificationsComponent />
      <OnSiteNotificationsComponent />
      <DialogChestAnimationComponent />
    </ScopedCssBaseline>
  )
}
