import { ChestTypeEnum } from '@/enums'

export const ChestAnimationsByType = {
  [ChestTypeEnum.MISSION]: {
    APPEARS: 'assets/animations/chest/mission-chest-appears.gif',
    JUMPING: 'assets/animations/chest/mission-chest-jumping.gif',
    OPENING: 'assets/animations/chest/mission-chest-opening.gif',
    CARD: 'assets/images/mission-reward-card.png',
  },
  [ChestTypeEnum.LEVEL]: {
    APPEARS: 'assets/animations/chest/level-chest-appears.gif',
    JUMPING: 'assets/animations/chest/level-chest-jumping.gif',
    OPENING: 'assets/animations/chest/level-chest-opening.gif',
    CARD: 'assets/images/level-reward-card.png',
  },
  [ChestTypeEnum.RANK]: {
    APPEARS: 'assets/animations/chest/rank-chest-appears.gif',
    JUMPING: 'assets/animations/chest/rank-chest-jumping.gif',
    OPENING: 'assets/animations/chest/rank-chest-opening.gif',
    CARD: 'assets/images/rank-reward-card.png',
  },
}
