import { useEffect, useRef, useState } from 'react'
import { useBreakpoints } from '@/hooks'
import { RewardShopModels } from '@/state'

import { ImageDefault } from '@/constants'

type Props = {
  product: RewardShopModels.ProductType
}

export const useProductImages = ({ product }: Props) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null)

  const [currentPreviewIndex, setCurrentPreviewIndex] = useState(0)

  const { isMobile, isTablet } = useBreakpoints()

  const isMobileOrTablet = isMobile || isTablet

  const productImages = product?.images?.length
    ? product?.images?.map((image) =>
        isMobileOrTablet ? image.smallImage : image.largeImage,
      )
    : [
        isMobileOrTablet
          ? product?.mobileImage || ImageDefault
          : product?.desktopImage || ImageDefault,
      ]

  useEffect(() => {
    setCurrentPreviewIndex(0)
  }, [JSON.stringify(productImages)]) // eslint-disable-line

  let scrollTimeout: NodeJS.Timeout | null = null

  const handleScroll = () => {
    if (scrollTimeout) clearTimeout(scrollTimeout)

    scrollTimeout = setTimeout(() => {
      if (scrollContainerRef.current) {
        const container = scrollContainerRef.current
        const scrollPosition = container.scrollLeft
        const imageWidth = container.clientWidth
        const index = Math.round(scrollPosition / imageWidth)

        const progress = scrollPosition % imageWidth
        const halfway = imageWidth / 2

        if (progress > halfway) {
          setCurrentPreviewIndex(index + 1)
        } else {
          setCurrentPreviewIndex(index)
        }
      }
    }, 200)
  }

  const handleArrowClick = (direction: 'left' | 'right') => {
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current
      const newIndex =
        direction === 'left'
          ? Math.max(0, currentPreviewIndex - 1)
          : Math.min(productImages.length - 1, currentPreviewIndex + 1)

      setCurrentPreviewIndex(newIndex)
      container.scrollTo({
        left: container.clientWidth * newIndex,
        behavior: 'smooth',
      })
    }
  }

  const handleBulletClick = (index: number) => {
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current
      const newPosition = container.clientWidth * index

      container.scrollTo({
        left: newPosition,
        behavior: 'smooth',
      })

      setCurrentPreviewIndex(index)
    }
  }

  const extractFileName = (url: string): string => {
    if (!url) {
      return ''
    }

    const path = url.split('?')[0]
    const fileNameWithExtension = path.substring(path.lastIndexOf('/') + 1)
    const decodedFileName = decodeURIComponent(fileNameWithExtension)
    return decodedFileName.replace(/-/g, ' ')
  }

  return {
    scrollContainerRef,
    isMobileOrTablet,
    isMobile,
    currentPreviewIndex,
    productImages,
    setCurrentPreviewIndex,
    handleBulletClick,
    handleArrowClick,
    handleScroll,
    extractFileName,
  }
}
