import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { MyBoosterThunks, RootState, useAppDispatch } from '@/state'

export const useSidebarActiveBoosters = () => {
  const dispatch = useAppDispatch()

  const { activeBoosters } = useSelector(
    ({ myBooster }: RootState) => myBooster,
  )

  const { playerId } = useSelector(({ initConfig }: RootState) => initConfig)

  const { missionsEnable } = useSelector(
    ({ globalSettings }: RootState) => globalSettings,
  )

  useEffect(() => {
    if (activeBoosters.length === 0) {
      dispatch(MyBoosterThunks.getLevelActiveBoostersThunk())
      dispatch(MyBoosterThunks.getTokenActiveBoostersThunk())
    }

    if (missionsEnable) {
      dispatch(
        MyBoosterThunks.getMissionActiveBoostersThunk({
          playerId: playerId,
        }),
      )
    }
  }, [missionsEnable]) // eslint-disable-line

  return {
    activeBoosters,
  }
}
