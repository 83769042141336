import { InfoOutlined } from '@mui/icons-material'
import { Box, darken, Typography, useTheme } from '@mui/material'

type Props = {
  message: string
  minHeight?: string | number
}

export function NoContentSidebarComponent({ message, minHeight }: Props) {
  const theme = useTheme()

  return (
    <Box
      alignItems="center"
      bgcolor={darken(theme.palette.background.paper, 0.05)}
      borderRadius={1}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      minHeight={minHeight || 170}
      sx={{ opacity: 0.7 }}
    >
      <InfoOutlined sx={{ fontSize: 32 }} />

      <Typography
        variant="caption"
        textAlign="center"
        width="80%"
        margin="0 auto"
      >
        {message}
      </Typography>
    </Box>
  )
}
