import { InfoOutlined } from '@mui/icons-material'
import { Box, Button, Typography, Grid, SxProps } from '@mui/material'
import { useStyles } from './style'

type Props = {
  title?: string
  message?: string
  messageSxProps?: SxProps
  buttonText?: string
  buttonAction?: () => void
}

export function NoContentIconComponent({
  title,
  message,
  messageSxProps,
  buttonText,
  buttonAction,
}: Props) {
  const { classes } = useStyles()

  return (
    <Box className={classes.noContent}>
      <Grid container>
        <Grid item xs={12}>
          <Box className="icon">
            <InfoOutlined />
          </Box>
        </Grid>
        {!!title && (
          <Grid item xs={12}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                marginTop: 2,
                textAlign: 'center',
                fontSize: 14,
              }}
            >
              {title}
            </Typography>
          </Grid>
        )}
        {!!message && (
          <Grid item xs={12}>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 400,
                marginTop: 1,
                marginBottom: 1,
                textAlign: 'center',
                fontSize: 12,
                ...messageSxProps,
              }}
            >
              {message}
            </Typography>
          </Grid>
        )}
        {!!buttonText && (
          <Grid item xs={12}>
            <Box width="max-content" mt={2} mx="auto">
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  buttonAction()
                }}
              >
                {buttonText}
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}
