import { Box, useTheme } from '@mui/material'

type Props = {
  bulletsCount: number
  currentBullet: number
  handleScroll: (bulletId: number, left?: boolean) => void
}

export const SliderBulletsComponent = ({
  bulletsCount,
  currentBullet,
  handleScroll,
}: Props) => {
  const theme = useTheme()

  const bullets = Array.from(new Array(bulletsCount), (_, index) => (
    <Box
      className={index === currentBullet ? 'active' : ''}
      key={`bullet_${index}`}
      onClick={() => {
        handleScroll(index, index < currentBullet)
      }}
      width={12}
      height={12}
      bgcolor={theme.palette.background.default}
      borderRadius="50%"
      sx={{
        cursor: 'pointer',
        '&.active': {
          backgroundColor: theme.palette.primary.main,
        },
      }}
    />
  ))

  return (
    <Box
      display="flex"
      justifyContent="center"
      gap={2}
      position="absolute"
      bottom={10}
    >
      {bullets}
    </Box>
  )
}
