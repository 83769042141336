import { makeStyles } from 'tss-react/mui'
import { chipClasses, typographyClasses } from '@mui/material'
import { VariantsComponentsEnum } from '@/enums'

type Props = {
  variant?: VariantsComponentsEnum
  borderRadius?: number
}

export const useMissionBundleItemStyles = makeStyles<Props>()((
  theme,
  { variant, borderRadius },
) => {
  const getVariantHeight = () => {
    if (variant === VariantsComponentsEnum.SMALL) {
      return 177
    }

    if (variant === VariantsComponentsEnum.MEDIUM) {
      return 211
    }

    return 260
  }

  return {
    missionBundleItemWrapper: {
      display: 'block',
      height: getVariantHeight(),
      width: '100%',
      borderRadius: borderRadius || 8,
      position: 'relative',

      '&.noBorderBottom': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,

        '& .bundleImage': {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
      },

      '& .bundleImage': {
        height: getVariantHeight(),
        width: '100%',
        objectFit: 'cover',
        borderRadius: borderRadius || 8,
      },

      '& .bundleChip': {
        [`& .${chipClasses.root}`]: {
          borderRadius: 6,
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
          fontWeight: 600,
          fontSize: variant === VariantsComponentsEnum.SMALL ? 10 : 12,
          height: variant === VariantsComponentsEnum.SMALL ? 19 : 25,
        },

        [`& .${chipClasses.label}`]: {
          paddingLeft: 8,
          paddingRight: 8,
        },

        [`& .${chipClasses.icon}`]: {
          fontSize: variant === VariantsComponentsEnum.SMALL ? 13 : 20,
          marginTop: -1,
          color: theme.palette.text.primary,
        },

        [theme.breakpoints.down('xs')]: {
          [`& .${chipClasses.root}`]: {
            fontSize: 10,
            height: 19,
          },

          [`& .${chipClasses.label}`]: {
            paddingLeft: 5,
            paddingRight: 5,
          },

          [`& .${chipClasses.icon}`]: {
            fontSize: 13,
          },
        },
      },

      '& .bundleTimeframeType': {
        position: 'absolute',
        top: variant === VariantsComponentsEnum.SMALL ? 4 : 7,
        left: variant === VariantsComponentsEnum.SMALL ? 7 : 10,

        [theme.breakpoints.down('xs')]: {
          top: 4,
          left: 7,
        },
      },

      '& .bundleTimeRemaining': {
        position: 'absolute',
        top: variant === VariantsComponentsEnum.SMALL ? 4 : 7,
        right: variant === VariantsComponentsEnum.SMALL ? 7 : 10,

        [theme.breakpoints.down('xs')]: {
          top: 4,
          right: 7,
        },
      },

      '& .bundleItemFooter': {
        position: 'absolute',
        bottom: 0,
        width: '100%',
      },

      '& .bundleClaimChest': {
        padding: variant === VariantsComponentsEnum.LARGE ? 12 : 8,
        width: '100%',

        [theme.breakpoints.down('xs')]: {
          padding: 8,
        },
      },

      '& .bundleProgressAndMissions': {
        borderRadius: 8,
        width: '100%',
        padding: '8px 12px',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        alignItems: 'center',
        gap: 16,

        '& .progress': {
          width: '100%',
          [`& .${typographyClasses.body2}`]: {
            fontSize: variant === VariantsComponentsEnum.SMALL ? 12 : 14,
            fontWeight: 600,
            paddingBottom: variant === VariantsComponentsEnum.SMALL ? 3 : 7,
            color: theme.palette.text.secondary,

            [theme.breakpoints.down('xs')]: {
              fontSize: 12,
            },
          },
        },

        '& .seeMissions': {
          color: theme.palette.primary.main,
          borderRadius: 6,
          display: 'flex',
          alignItems: 'center',
          minWidth: 'auto',
          gap: 8,
          width: variant === VariantsComponentsEnum.LARGE ? 'auto' : 36,
          height: 36,

          [theme.breakpoints.down('xs')]: {
            width: 36,
          },

          [`& .${typographyClasses.caption}`]: {
            textTransform: 'uppercase',
            fontSize: 14,
            fontWeight: 600,
            textWrap: 'nowrap',
            display:
              variant === VariantsComponentsEnum.SMALL ? 'none' : 'block',

            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
          },

          '& svg': {
            fontSize: 15,
          },
        },
      },
    },
  }
})
