import {
  Tooltip,
  TooltipProps,
  alpha,
  styled,
  tooltipClasses,
} from '@mui/material'

type TooltipCustomProps = {
  maxwidth?: number
  width?: number
}

export const HtmlTooltip = styled(
  ({ className, ...props }: TooltipProps & TooltipCustomProps) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
  ),
)(({ maxwidth, width }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxwidth: maxwidth || 250,
    width: width || 'auto',
  },
}))

export const tooltipTheme = {
  tooltip: ({ theme }) => ({
    padding: 8,
    borderRadius: 8,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    border: `1px solid ${alpha(theme.palette.text.primary, 0.05)}`,
  }),
  arrow: ({ theme }) => ({
    color: theme.palette.background.default,
    '&::before': {
      backgroundColor: theme.palette.background.default,
      border: `1px solid ${alpha(theme.palette.text.primary, 0.05)}`,
    },
  }),
}
