import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  useTheme,
} from '@mui/material'
import { AccessTime, InfoOutlined } from '@mui/icons-material'

import {
  MissionTabsEnum,
  SidebarMenuItemType,
  UIDialogsEnum,
  VariantsComponentsEnum,
} from '@/enums'
import {
  HeaderNavigationComponent,
  MissionBundleItemComponent,
  NoContentIconComponent,
} from '@/components/common'
import { useBreakpoints, useGlobalUi } from '@/hooks'

import { useMissionStyles } from '../style'
import {
  ActiveBundleStandardComponent,
  MissionBundlesStandardComponent,
  MissionBundleInfoComponent,
} from './'
import { useMissionsStandard } from '../hooks'

type Props = {
  isMissionWidget?: boolean
  handleSetFullContent?: (full: boolean) => void
  handleChangeMenuItem?: (sidebarMenuItem: string) => void
}

export const MissionStandardComponent = ({
  isMissionWidget,
  handleSetFullContent,
  handleChangeMenuItem,
}: Props) => {
  const { t } = useTranslation()

  const theme = useTheme()

  const { isMobile } = useBreakpoints()

  const { classes } = useMissionStyles()

  const {
    contentRef,
    missionActiveBundles,
    missionExpiredBundles,
    missionBundles,
    selectedBundle,
    missionTab,
    isLoading,
    selectedBundleName,
    selectedBundleDescription,
    selectedBundleTermsAndConditions,
    handleSetSelectedBundle,
    setSelectedBundle,
    setMissionTab,
  } = useMissionsStandard()

  const { handleOpenDialog } = useGlobalUi()

  return (
    <>
      <Box className={classes.missionWrapper} mt={selectedBundle ? '20px' : 0}>
        {selectedBundle ? (
          <>
            <Box
              bgcolor={theme.palette.primary.main}
              color={theme.palette.common.white}
              padding="10px 10px 25px"
            >
              <HeaderNavigationComponent
                title={selectedBundleName}
                goBack={() => {
                  setSelectedBundle(null)
                  !!handleSetFullContent && handleSetFullContent(false)
                }}
                interaction={
                  selectedBundleDescription !== '' ||
                  selectedBundleTermsAndConditions !== '' ? (
                    <IconButton
                      onClick={() =>
                        handleOpenDialog({
                          id: UIDialogsEnum.MISSION_BUNDLE_INFO,
                        })
                      }
                      sx={{
                        color: theme.palette.common.white,
                      }}
                    >
                      <InfoOutlined sx={{ fontSize: 20 }} />
                    </IconButton>
                  ) : undefined
                }
              />
            </Box>
            <Box mt={-2}>
              <MissionBundleItemComponent
                missionBundle={selectedBundle}
                variant={VariantsComponentsEnum.SMALL}
                showEndedMissions={missionTab === MissionTabsEnum.HISTORY}
                borderRadius={16}
                noBorderBottom
              />
            </Box>
          </>
        ) : (
          <Box px={!isMissionWidget && 2} pt={2}>
            <HeaderNavigationComponent
              title={
                missionTab === MissionTabsEnum.MISSIONS
                  ? t('missions')
                  : t('missionsHistory')
              }
              titleSxProps={{ textTransform: 'uppercase' }}
              goBack={
                (!isMissionWidget || missionTab === MissionTabsEnum.HISTORY) &&
                (() => {
                  missionTab === MissionTabsEnum.MISSIONS
                    ? handleChangeMenuItem(SidebarMenuItemType.HOME)
                    : setMissionTab(MissionTabsEnum.MISSIONS)
                })
              }
              interaction={
                <>
                  {missionTab === MissionTabsEnum.MISSIONS && (
                    <Button
                      startIcon={<AccessTime />}
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        setMissionTab(MissionTabsEnum.HISTORY)
                      }}
                      className={classes.historyButton}
                    >
                      {isMissionWidget && !isMobile
                        ? t('missionHistory')
                        : t('history')}
                    </Button>
                  )}
                  {missionTab === MissionTabsEnum.HISTORY &&
                    missionExpiredBundles?.length > 0 && (
                      <Chip
                        label={t('lastNumberDays', { number: 30 })}
                        icon={<AccessTime sx={{ fontSize: 16 }} />}
                        sx={{
                          background: 'none',
                          fontWeight: 600,
                          '& .GamanzaChip-label': {
                            paddingRight: 0,
                          },
                        }}
                      />
                    )}
                </>
              }
            />
          </Box>
        )}
        <Box
          className={classes.missionContent}
          padding={!isMissionWidget && 2}
          paddingY={isMissionWidget && 2}
          ref={contentRef}
        >
          <>
            {isLoading ||
            (missionTab === MissionTabsEnum.MISSIONS &&
              missionActiveBundles === null) ||
            (missionTab === MissionTabsEnum.HISTORY &&
              missionExpiredBundles === null) ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  minHeight: 300,
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                {(missionTab === MissionTabsEnum.MISSIONS &&
                  missionActiveBundles?.length > 0) ||
                (missionTab === MissionTabsEnum.HISTORY &&
                  missionExpiredBundles?.length > 0) ? (
                  <>
                    {selectedBundle ? (
                      <ActiveBundleStandardComponent
                        missionBundle={selectedBundle}
                        componentRef={contentRef}
                        showEndedMissions={
                          missionTab === MissionTabsEnum.HISTORY
                        }
                      />
                    ) : (
                      <MissionBundlesStandardComponent
                        missionBundles={missionBundles}
                        showEndedMissions={
                          missionTab === MissionTabsEnum.HISTORY
                        }
                        isMissionWidget={isMissionWidget}
                        handleSetFullContent={handleSetFullContent}
                        handleSetSelectedBundle={handleSetSelectedBundle}
                      />
                    )}
                  </>
                ) : (
                  <Box minHeight={400} display="flex" alignItems="center">
                    <NoContentIconComponent
                      title={t('noMissions')}
                      message={t('noMissionsMessage')}
                      buttonAction={() => {
                        handleChangeMenuItem(SidebarMenuItemType.HOME)
                      }}
                      buttonText={!isMissionWidget && t('goHome')}
                    />
                  </Box>
                )}
              </>
            )}
          </>
        </Box>
      </Box>
      <MissionBundleInfoComponent
        selectedBundleDescription={selectedBundleDescription}
        selectedBundleName={selectedBundleName}
        selectedBundleTermsAndConditions={selectedBundleTermsAndConditions}
      />
    </>
  )
}
