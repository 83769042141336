import { useSelector } from 'react-redux'
import { RootState } from '@/state'
import { MissionCustomComponent, MissionStandardComponent } from './'

export const MissionsComponent = () => {
  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  return (
    <>
      {client === 'JP' ? (
        <MissionCustomComponent isMissionWidget />
      ) : (
        <MissionStandardComponent isMissionWidget />
      )}
    </>
  )
}
