import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  MyBoosterActions,
  MyBoosterModels,
  MyBoosterThunks,
  RootState,
  useAppDispatch,
} from '@/state'

import { RewardProcessorBoosterTypeEnum } from '@/enums'
import { boostersInfo, initialBoosterFilters } from '@/constants'

export const useMyBoostersActions = () => {
  const dispatch = useAppDispatch()

  const { playerId } = useSelector(({ initConfig }: RootState) => initConfig)

  const { gamificationPlayer } = useSelector(({ player }: RootState) => player)

  const { myBoosters, pagination } = useSelector(
    ({ myBooster }: RootState) => myBooster,
  )

  const filters = {
    ...initialBoosterFilters,
    playerId: playerId,
  }

  const handlePrevPage = () => {
    const prevPage = pagination.page - 1
    dispatch(MyBoosterActions.setPage(prevPage))
    dispatch(
      MyBoosterThunks.getAllMyBoostersThunk({
        ...filters,
        limit: pagination.limit,
        page: prevPage,
      }),
    )
  }

  const handleNextPage = () => {
    const nextPage = pagination.page + 1
    dispatch(MyBoosterActions.setPage(nextPage))
    dispatch(
      MyBoosterThunks.getAllMyBoostersThunk({
        ...filters,
        limit: pagination.limit,
        page: nextPage,
      }),
    )
  }

  const handleChangePage = (newPage: number) => {
    dispatch(MyBoosterActions.setPage(newPage))
    dispatch(
      MyBoosterThunks.getAllMyBoostersThunk({
        ...filters,
        limit: pagination.limit,
        page: newPage,
      }),
    )
  }

  const getAllMyBoosters = useCallback(
    (filters: MyBoosterModels.BoosterFiltersType) => {
      dispatch(MyBoosterThunks.getAllMyBoostersThunk(filters))
    },
    [dispatch],
  )

  const handleActivateBooster = useCallback(
    async (orderId: string) => {
      const filteredBooster = myBoosters.find(
        (booster) => booster.id === orderId,
      )

      if (filteredBooster) {
        const boosterType =
          boostersInfo[filteredBooster.earnedReward.type].rewardProcessorType
        dispatch(
          MyBoosterThunks.activateBoosterThunk({
            boosterType: boosterType,
            missionId:
              boosterType === RewardProcessorBoosterTypeEnum.MISSION
                ? filteredBooster.id
                : undefined,
            levelId: gamificationPlayer.level.id || undefined,
            id: filteredBooster.id,
          }),
        )
      }
    },
    [dispatch, myBoosters], // eslint-disable-line
  )

  return {
    getAllMyBoosters,
    handlePrevPage,
    handleNextPage,
    handleActivateBooster,
    handleChangePage,
  }
}
