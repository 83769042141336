import { makeStyles } from 'tss-react/mui'
import {
  alpha,
  typographyClasses,
  formControlLabelClasses,
  buttonBaseClasses,
  inputBaseClasses,
  textFieldClasses,
  gridClasses,
  checkboxClasses,
  circularProgressClasses,
  filledInputClasses,
  outlinedInputClasses,
} from '@mui/material'

interface Props {
  client: string
}

export const useStyles = makeStyles<Props>()((theme, { client }) => {
  const isJPClient = client === 'JP'

  const mainColor = theme.palette.primary.main

  const primaryText = theme.palette.text.primary

  const secondaryText = theme.palette.text.secondary

  return {
    tokensBoxContainer: {
      display: 'flex',
      flexDirection: 'column',
      background: isJPClient ? 'transparent' : alpha(mainColor, 0.2),
      borderRadius: 8,
      maxWidth: 180,
      alignItems: 'center',
    },
    tokensBoxWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: isJPClient ? '100%' : 'auto',
      padding: isJPClient ? 0 : 8,
    },
    tokensBox: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      gap: 6,

      '&.compact': {
        padding: 0,
        margin: 0,
      },

      [`& .${typographyClasses.body2}`]: {
        color: isJPClient ? primaryText : mainColor,
        fontSize: 20,
        fontWeight: 700,
      },
    },

    title: {
      paddingBottom: 8,
      fontSize: 24,
      lineHeight: 1,

      [theme.breakpoints.down('sm')]: {
        paddingBottom: 10,
        fontSize: 20,
      },
      ...(isJPClient && {
        fontWeight: 700,
      }),
    },

    description: isJPClient
      ? {
          color: primaryText,
          fontSize: 18,
        }
      : {
          overflow: 'hidden',
          position: 'relative',
          mb: 1,
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: 20,
            pointerEvents: 'none',
          },
        },

    remainingTokens: {
      width: '100%',
      fontWeight: 700,
      ...(isJPClient
        ? {
            color: primaryText,
          }
        : {
            color: mainColor,
          }),
    },

    loader: {
      display: 'flex',
      justifyContent: 'center',
      minHeight: 400,
      alignItems: 'center',
    },

    form: {
      display: 'flex',
      flexDirection: 'column',

      [`& .${textFieldClasses.root}`]: {
        marginTop: 0,
      },

      [`& .${filledInputClasses.root}`]: {
        background: isJPClient ? theme.palette.common.white : 'none !important',
        border: `1px solid ${alpha(secondaryText, 0.2)}`,
        borderRadius: isJPClient ? 6 : 8,

        '&.Mui-focused, &.Mui-error, &:hover': {
          borderColor: mainColor,
        },

        '&::after, &::before': {
          display: 'none',
        },

        '& svg': {
          color: secondaryText,
        },
      },

      [`& .${outlinedInputClasses.root}`]: {
        background: theme.palette.common.white,
        borderRadius: 4,

        '&.Mui-focused, &.Mui-error, &:hover fieldset': {
          borderColor: mainColor,
          borderWidth: 1,
        },
      },

      [`& .${inputBaseClasses.input}`]: isJPClient
        ? {
            color: primaryText,
          }
        : { color: secondaryText },

      '& .quantity': {
        [theme.breakpoints.down('md')]: {
          width: 105,
          marginBottom: 0,
          marginTop: 20,
        },
      },

      [`& .quantity .${inputBaseClasses.input}`]: {
        fontSize: 14,
        textAlign: 'center',
        padding: '4px 0px !important',
      },

      '& fieldset': {
        borderRadius: isJPClient ? 6 : 16,
      },

      '& .grayCaption': {
        color: secondaryText,
      },

      '& .checkBox': {
        [`& .${checkboxClasses.root}`]: {
          transform: 'scale(1.2)',
          padding: '4px 9px',
        },

        [`&.${formControlLabelClasses.root}`]: {
          marginRight: 6,
          color: secondaryText,
          letterSpacing: 0.75,
        },
      },

      [`& .${gridClasses['grid-xs-12']}`]: {
        width: '100%',
      },

      [`& .${buttonBaseClasses.root}[type=submit]`]: {
        width: 250,
        height: 56,
        padding: '10px 0px',
        marginTop: 8,
        color: theme.palette.common.white,
        backgroundColor: mainColor,
        borderRadius: 12,

        [`&.${buttonBaseClasses.disabled}`]: {
          backgroundColor: alpha(mainColor, 0.6),
        },

        [`& .${circularProgressClasses.root}`]: {
          color: theme.palette.common.white,
          width: '25px !important',
          height: '25px !important',
          marginLeft: 10,
        },

        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },
    },

    radioGroup: {
      border: `1px solid ${alpha(secondaryText, 0.2)}`,
      padding: '8px 16px',
      marginBottom: 16,
      borderRadius: isJPClient ? 6 : 16,
      backgroundColor: isJPClient ? '#F0F0F0' : 'transparent',

      [`& .${formControlLabelClasses.label}`]: {
        color: isJPClient ? primaryText : secondaryText,

        [`& .${typographyClasses.root}`]: {
          fontSize: 14,
        },
      },
    },

    locationLabel: {
      marginBottom: 4,
    },

    editButton: {
      position: 'absolute',
      top: 10,
      right: 10,
      color: alpha(secondaryText, 0.5),

      [`& .${typographyClasses.body1}`]: {
        color: mainColor,
      },
    },
  }
})
