import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { RootState } from '@/state'

import { DialogMissionComponent } from '@/components/common'
import { useBreakpoints } from '@/hooks'
import {
  SidebarHeaderComponent,
  SidebarMenuComponent,
  SidebarContentComponent,
  useGlobalStyles,
} from './'

import { useSidebarActions } from '../hooks'

type Props = {
  Div: HTMLElement
}

export const SidebarComponent = ({ Div }: Props) => {
  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const contentBackground = Div.getAttribute('data-content-background') || ''
  const height = Div.getAttribute('data-height') || 'auto'

  Div.style['height'] = '100%'

  const {
    openSidebar,
    sidebarMenuItem,
    fullContent,
    handleOpenSidebar,
    handleChangeMenuItem,
    handleSetFullContent,
  } = useSidebarActions()

  const { classes } = useGlobalStyles({
    openSidebar,
    contentBackground,
  })

  const { isMobile } = useBreakpoints()

  const mobileHeight = openSidebar ? 'calc(100vh - 100px)' : 'auto'

  return (
    <Box height={isMobile ? mobileHeight : height}>
      <Box className={classes.sidebarWrapper}>
        <Box display={fullContent ? 'none' : 'block'}>
          <SidebarHeaderComponent
            Div={Div}
            sidebarClient={client}
            sidebarMenuItem={sidebarMenuItem}
            handleOpenSidebar={handleOpenSidebar}
            openSidebar={openSidebar}
          />
        </Box>
        <SidebarContentComponent
          Div={Div}
          sidebarClient={client}
          contentBackground={contentBackground}
          sidebarMenuItem={sidebarMenuItem}
          openSidebar={openSidebar}
          handleChangeMenuItem={handleChangeMenuItem}
          handleSetFullContent={handleSetFullContent}
        />
        {openSidebar && (
          <SidebarMenuComponent
            Div={Div}
            sidebarMenuItem={sidebarMenuItem}
            handleChangeMenuItem={handleChangeMenuItem}
          />
        )}
        <DialogMissionComponent />
      </Box>
    </Box>
  )
}
