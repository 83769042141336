import { RewardTypeEnum } from '@gamanza-engage/mf-enums'

export const ActiveBoostersColors = {
  [RewardTypeEnum.LEVEL_BOOSTER]: {
    baseColor: '#875EFF',
  },
  [RewardTypeEnum.MISSION_BOOSTER]: {
    baseColor: '#2FB496',
  },
  [RewardTypeEnum.TOKEN_BOOSTER]: {
    baseColor: '#FF962E',
  },
  alert: {
    baseColor: '#DC2D2D',
    dark: {
      background: '#4D2B2A',
      textColor: '#fa7f7f',
    },
    light: {
      background: '#FFD2D2',
      textColor: '#DC2D2D',
    },
  },
  warning: {
    baseColor: '#FFAB00',
    dark: {
      background: '#554422',
      textColor: '#FFAB00',
    },
    light: {
      background: '#FFF7D2',
      textColor: '#FFAB00',
    },
  },
}
