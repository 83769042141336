import { filledInputClasses, inputBaseClasses } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

type Props = {
  client: string
}

export const useCounterStyles = makeStyles<Props>()((theme, { client }) => {
  const isJPClient = client === 'JP'

  return {
    counterWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },
    iconButton: {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 8,
      padding: 1.25,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      height: 40,
      width: 40,
    },
    input: {
      width: isJPClient ? 90 : 50,
      marginTop: 0,
      marginBottom: 0,
      [`& .${inputBaseClasses.input}`]: {
        height: 40,
        textAlign: isJPClient ? 'left' : 'center',
        padding: isJPClient ? '15px 10px 0px' : 0,
        margin: 0,

        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        '&[type=number]': {
          MozAppearance: 'textfield',
        },
      },
      [`& .${filledInputClasses.root}`]: {
        borderRadius: 8,
        padding: 0,
        height: isJPClient ? 60 : 40,
        '&:hover:before': {
          borderBottom: 'none !important',
        },
      },
      [`& .${filledInputClasses.root}:before`]: {
        border: 'none',
      },
    },
    inputLabel: {
      fontSize: '12px',
    },
  }
})
