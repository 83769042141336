import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MyRewardsModels } from '@/state/my-rewards'
import { ChestTypeEnum, RewardTriggerTypeEnum } from '@/enums'

import { getRewardsByTriggerExtraReducer } from './'

const initialState: MyRewardsModels.MyRewardsState = {
  isLoading: false,
  myRewardsByTrigger: {},
}

const getTriggerType = (chestType: string) => {
  switch (chestType) {
    case ChestTypeEnum.LEVEL:
      return RewardTriggerTypeEnum.LEVEL
    case ChestTypeEnum.RANK:
      return RewardTriggerTypeEnum.RANK
    case ChestTypeEnum.MISSION:
      return RewardTriggerTypeEnum.MISSION
  }
}

export const myRewardsSlice = createSlice({
  name: '@my-rewards',
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.isLoading = false
    },
    addChestAction(
      state,
      action: PayloadAction<MyRewardsModels.RewardsByGroupType>,
    ) {
      const { groupId, trigger } = action.payload

      const newMyRewardsByTrigger = { ...state.myRewardsByTrigger }

      if (!newMyRewardsByTrigger[trigger]) {
        newMyRewardsByTrigger[trigger] = {}
      }

      if (!newMyRewardsByTrigger[trigger][groupId]) {
        newMyRewardsByTrigger[trigger][groupId] = action.payload
      }

      state.myRewardsByTrigger = newMyRewardsByTrigger
    },
    removeChestAction(
      state,
      action: PayloadAction<MyRewardsModels.RemoveRewardsType>,
    ) {
      const { groupId, chestType } = action.payload

      const trigger = getTriggerType(chestType)

      const newMyRewardsByTrigger = state.myRewardsByTrigger
      for (const key in newMyRewardsByTrigger[trigger]) {
        if (newMyRewardsByTrigger[trigger][key].groupId === groupId) {
          delete newMyRewardsByTrigger[trigger][key]
        }
      }

      state.myRewardsByTrigger = newMyRewardsByTrigger
    },
  },
  extraReducers: (builder) => {
    getRewardsByTriggerExtraReducer(builder)
  },
})

export const MyRewardsActions = myRewardsSlice.actions
