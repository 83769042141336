import { useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'
import {
  MissionStatusEnum,
  OrientationComponentsEnum,
  VariantsComponentsEnum,
} from '@/enums'

import { MissionCommonModels, RootState } from '@/state'
import { MissionItemStandardComponent } from '../mission-item'

type Props = {
  mission: MissionCommonModels.MissionType
  index: number
  timelineType: OrientationComponentsEnum
  handleClick: (id: string) => void
}

export const TimelineMissionStepComponent = ({
  mission,
  index,
  timelineType,
  handleClick,
}: Props) => {
  const { baseUrl } = useSelector(({ initConfig }: RootState) => initConfig)

  const { gamificationPlayer } = useSelector(({ player }: RootState) => player)

  const showMissionChest =
    mission?.status === MissionStatusEnum.ACTIVE &&
    mission?.rewardCategories?.findIndex(
      (category) =>
        category.playerCategoryId === gamificationPlayer.category.id,
    ) !== -1

  return (
    <Box className="step">
      <Box className="stepNumber">
        <img
          src={`${baseUrl}assets/icons/common/icon-timeline-bullet.svg`}
          alt="Timeline Bullet"
        />
        <Typography className="number">{index + 1}</Typography>
      </Box>
      <MissionItemStandardComponent
        showArrow
        status={mission.status}
        state={mission.state}
        endDate={mission.endDate}
        orientation={
          timelineType === OrientationComponentsEnum.VERTICAL
            ? OrientationComponentsEnum.HORIZONTAL
            : OrientationComponentsEnum.VERTICAL
        }
        variant={VariantsComponentsEnum.LARGE}
        handleClick={() => {
          handleClick(mission._id)
        }}
      />
      {showMissionChest && (
        <Box className="missionChestIcon">
          <img
            src={`${baseUrl}assets/icons/missions/icon-mission-chest-closed.png`}
            alt="Mission Chest"
          />
        </Box>
      )}
    </Box>
  )
}
