export enum WidgetTypeEnum {
  AVATAR = 'avatar',
  POINTS = 'points',
  REWARD_SHOP = 'reward-shop',
  TOKENS = 'tokens',
  ACTIVE_BOOSTERS = 'active-boosters',
  GAMIFICATION_MECHANICS = 'gamification-mechanics',
  MY_BOOSTERS = 'my-boosters',
  SIDEBAR = 'sidebar',
  BADGE_LEVEL = 'badge-level',
  MISSIONS = 'missions',
}
