import { useSelector } from 'react-redux'
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material'
import { RootState } from '@/state'

import { ImageDefault } from '@/constants'
import { CardItemContentType } from '@/state/models'
import { useStylesDense } from './style-dense'

type Props = {
  item: CardItemContentType
  cardDescriptionComponent?: React.ReactNode
  cardActionsComponent?: React.ReactNode
  onClick?: (param: any) => void
}

export const CardDenseItemComponent = ({
  item,
  cardDescriptionComponent,
  cardActionsComponent,
  onClick,
}: Props) => {
  const { title, image } = item

  const { gamificationOpt } = useSelector(({ player }: RootState) => player)

  const { classes } = useStylesDense()

  const CardArea = (
    <Box className={classes.cardArea}>
      <Box className="imageDesktop">
        <CardMedia
          component="img"
          image={image || ImageDefault}
          className={classes.cardMedia}
        />
      </Box>
      <CardContent className={classes.cardContent}>
        <Box position="relative">
          <Typography className={classes.title}>{title}</Typography>
        </Box>
        <Box className={classes.description}>{cardDescriptionComponent}</Box>
      </CardContent>
    </Box>
  )

  return (
    <Card className={classes.card}>
      {onClick && gamificationOpt.enable ? (
        <CardActionArea onClick={onClick}>{CardArea}</CardActionArea>
      ) : (
        CardArea
      )}

      {cardActionsComponent && (
        <CardActions sx={{ padding: 0 }}>{cardActionsComponent}</CardActions>
      )}
    </Card>
  )
}
