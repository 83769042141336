import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HttpStatusCode, RewardTypeEnum } from '@gamanza-engage/mf-enums'
import { GeneralModels } from '@/state'

import {
  getRankDetailsExtraReducer,
  getTokenDetailsExtraReducer,
  PlayerModels,
  getGamificationOptStatusExtraReducer,
  setGamificationOptStatusExtraReducer,
} from '../'

const initialState: PlayerModels.PlayerState = {
  player: null,
  gamificationPlayer: {
    category: {
      id: '',
      name: '',
    },
    level: {
      id: '',
      levelNumber: 0,
      progress: 0,
    },
    nextLevel: {
      id: '',
      levelNumber: 0,
    },
    rank: {
      id: '',
      name: '',
      imageUrl: '',
      translations: [],
      progress: 0,
    },
    nextRank: {
      id: '',
      name: '',
      translations: [],
    },
    pointsUntilNextLevel: 0,
    pointsUntilNextRank: 0,
    xpBalance: 0,
    tokens: 0,
    oldTokens: 0,
    previousData: {},
    rewards: [],
    statusCode: null,
  },
  gamificationOpt: {
    enable: true,
  },
  countries: [],
  currency: '',
  isLoading: false,
  earnedRewards: [],
}

export const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    setPlayerData(
      state,
      action: PayloadAction<PlayerModels.PlayerTypePayload>,
    ) {
      const { countries, currency, ...player } = action.payload
      state.player = player
      state.countries = countries || []
      state.currency = currency
      state.gamificationPlayer.oldTokens = state.gamificationPlayer.tokens
    },
    resetGamificationData(state) {
      state.gamificationPlayer = initialState.gamificationPlayer
    },
    setWalletBalance(state, action: PayloadAction<number>) {
      state.gamificationPlayer.tokens = action.payload
      state.gamificationPlayer.statusCode = HttpStatusCode.OK
    },
    updateWalletTokens(state) {
      state.gamificationPlayer.oldTokens = state.gamificationPlayer.tokens
    },
    setGamificationDetails(
      state,
      action: PayloadAction<PlayerModels.SocketGamificationDetailsType>,
    ) {
      state.gamificationPlayer = {
        ...state.gamificationPlayer,
        rank: {
          id: action.payload.rank.id,
          name: action.payload.rank.name,
          imageUrl: action.payload.rank.image,
          translations: action.payload.rank.translations,
          progress: action.payload.rank.progress,
        },
        nextRank: {
          id: action.payload?.nextRank?._id,
          name: action.payload?.nextRank?.name,
          translations: action.payload?.nextRank?.translations,
        },
        level: {
          id: action.payload.level.id,
          levelNumber: action.payload.level.number,
          progress: action.payload.level.progress,
        },
        nextLevel: {
          id: action.payload?.nextLevel?._id,
          levelNumber: action.payload?.nextLevel?.levelNumber,
        },
        pointsUntilNextLevel:
          action.payload.pointsUntilNextLevel > 0
            ? action.payload.pointsUntilNextLevel
            : 0,
        pointsUntilNextRank: action.payload.pointsUntilNextRank,
        xpBalance: action.payload.xpBalance,
        rewards: action.payload?.rewards || [],
      }
    },
    updateGamificationPreviousData(state) {
      state.gamificationPlayer = {
        ...state.gamificationPlayer,
        previousData: {
          previousXpBalance: state.gamificationPlayer.xpBalance,
          previousLevelNumber: state.gamificationPlayer.level.levelNumber,
          previousRankName: state.gamificationPlayer.rank.name,
          previousRankTranslations: state.gamificationPlayer.rank.translations,
          previousTokens: state.gamificationPlayer.tokens,
        },
      }
    },
    setGamificationOptStatus(state, action: PayloadAction<boolean>) {
      state.gamificationOpt.enable = action.payload
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setEarnedRewards(state, action: PayloadAction<PlayerModels.RewardType[]>) {
      // Convert Player Reward Type to General Reward Type
      const rewards = action.payload.map((reward: PlayerModels.RewardType) => {
        const newReward: GeneralModels.RewardCategoryType = {
          rewardType: reward.type,
          translations: reward.translations,
          amount: reward.amount,
          bonusId: reward.bonusOfferId,
          timeFrame: reward.timeFrame,
          boosterRate: reward.boosterRate,
          tokens: reward.type === RewardTypeEnum.TOKENS && reward.amount,
        }
        return newReward
      })

      state.earnedRewards = rewards
    },
  },
  extraReducers: (builder) => {
    getRankDetailsExtraReducer(builder)
    getTokenDetailsExtraReducer(builder)
    getGamificationOptStatusExtraReducer(builder)
    setGamificationOptStatusExtraReducer(builder)
  },
})

export const PlayerActions = playerSlice.actions
