import { useEffect } from 'react'
import { Snackbar, Alert, useTheme, alpha } from '@mui/material'
import { useGlobalUi } from '@/hooks'

export function SnackbarComponent() {
  const { snackbar, handleCloseSnackbar } = useGlobalUi()

  const { message, open, severity } = snackbar

  const theme = useTheme()

  useEffect(() => {
    return () => {
      handleCloseSnackbar()
    }
  }, []) // eslint-disable-line

  const onClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    handleCloseSnackbar()
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open && !!message}
      autoHideDuration={3500}
      onClose={onClose}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        variant="filled"
        sx={{
          color: theme.palette.common.white,
          border: `1px solid ${alpha(theme.palette.common.black, 0.15)}`,
          boxShadow: `2px 2px 2px ${alpha(theme.palette.common.black, 0.025)}`,
          fontWeight: 700,
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}
