import { makeStyles } from 'tss-react/mui'
import { getBorderColorByMode } from '@/constants'

interface Props {
  expanded?: boolean
}

export const useMissionItemStyles = makeStyles<Props>()((
  theme,
  { expanded },
) => {
  return {
    missionDenseWrapper: {
      borderRadius: 8,
      width: expanded ? 88 : 52,
      height: expanded ? 98 : 44,
      padding: expanded ? '4px 8px 8px' : '0',
      background: expanded ? theme.palette.background.default : 'none',
      border: expanded ? `1px solid ${getBorderColorByMode(theme)}` : 'none',
      boxShadow: expanded ? `0px 8px 16px -14px rgba(0, 0, 0, 0.10)` : 'none',
      cursor: 'pointer',
      position: 'relative',

      [theme.breakpoints.down('sm')]: {
        width: expanded ? 84 : 52,
        padding: expanded ? '4px 4px 8px' : '2px 0px 8px',
        marginRight: expanded ? 0 : -10,
      },
    },

    // Dense - Sidebar Open Version
    missionItemDense: {
      '& .missionContentVertical': {
        '& .missionTexts': {
          height: 20,
        },

        '& .missionTimeRemaining': {
          bottom: 20,
        },
      },
    },

    // Full Dense - Sidebar Close Version
    missionItemFullDense: {
      '& .missionContentVertical .missionProgress .icon': {
        top: 4,
        '& svg, & img': {
          width: 50,
          height: 'auto',
        },
      },

      '& .missionContentVertical .missionTimeRemaining': {
        bottom: -5,
        left: 'auto',
        right: 0,

        [theme.breakpoints.down('sm')]: {
          bottom: -15,
        },
      },
    },
  }
})
