import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { useTranslation } from 'react-i18next'
import { RewardShopTypeEnum } from '@gamanza-engage/mf-enums'
import { Box, Grid, useTheme } from '@mui/material'

import {
  ProductListTypeEnum,
  SidebarMenuItemType,
  UIDialogsEnum,
} from '@/enums'
import {
  RewardShopModels,
  RewardShopThunks,
  RootState,
  useAppDispatch,
} from '@/state'
import {
  CardItemComponent,
  NoContentIconComponent,
  PaginationDenseComponent,
  PlayerTokensComponent,
  SidebarRewardShopProductSkeletonComponent,
} from '@/components'

import { ImageDefault } from '@/constants'
import { useGlobalUi } from '@/hooks'

type Props = {
  category: RewardShopTypeEnum | 'all'
  handleChangeMenuItem?: (sidebarMenuItem: string) => void
}

export const SidebarRewardShopCategoryComponent = ({
  category,
  handleChangeMenuItem,
}: Props) => {
  const { t } = useTranslation()

  const theme = useTheme()

  const dispatch = useAppDispatch()

  const { handleOpenDialog } = useGlobalUi()

  const { playerLocale } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const {
    isLoadingSidebarProducts,
    sidebarProducts,
    paginationSidebarProducts,
  } = useSelector(({ rewardShop }: RootState) => rewardShop)

  const limit = 8

  const getPayload = (page?: number) => {
    const payload: RewardShopModels.GetAllProductsPayload = {
      limit,
      page: page || 1,
      listType: ProductListTypeEnum.SIDEBAR_SHOP,
    }

    if (category !== 'all') {
      payload.rewardType = category
    }

    return payload
  }

  useEffect(() => {
    dispatch(RewardShopThunks.getAllProductsThunk(getPayload()))
  }, [category]) // eslint-disable-line

  const handleChangePage = (value: number) => {
    dispatch(RewardShopThunks.getAllProductsThunk(getPayload(value)))
  }

  const handleGoHome = () => {
    handleChangeMenuItem(SidebarMenuItemType.HOME)
  }

  return (
    <>
      {isLoadingSidebarProducts ? (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 1,
            justifyContent: 'center',
          }}
        >
          {[...Array(limit)].map(() => (
            <Box key={uuidv4()} sx={{ flex: '0 1 calc(50% - 4px)' }}>
              <SidebarRewardShopProductSkeletonComponent />
            </Box>
          ))}
        </Box>
      ) : (
        <>
          {sidebarProducts?.length > 0 ? (
            <Grid container spacing={1}>
              {sidebarProducts.map((product) => {
                const translation =
                  product.translations?.find(
                    (item) =>
                      item.language.toUpperCase() ===
                      playerLocale.toUpperCase(),
                  ) || product.translations?.[0]

                return (
                  <Grid
                    key={product._id}
                    item
                    xs={6}
                    sx={{
                      [theme.breakpoints.down(400)]: {
                        width: '50%',
                        flexBasis: '50%',
                        maxWidth: '50%',
                      },
                    }}
                  >
                    <CardItemComponent
                      key={product._id}
                      item={{
                        id: product._id,
                        title: translation?.name || product.name,
                        description:
                          translation?.description || product.description,
                        image:
                          product?.images?.length > 0
                            ? product.images[0].smallImage
                            : product?.mobileImage || ImageDefault,
                        imageHover:
                          product?.images?.length > 1 &&
                          product.images[1].smallImage,
                        ranks: product.ranks?.map((item) => ({
                          name:
                            item?.translations?.find(
                              (trans) =>
                                trans.language.toUpperCase() ===
                                playerLocale.toUpperCase(),
                            )?.name ||
                            item?.translations?.[0]?.name ||
                            item?.name,
                          id: item._id,
                        })),
                        tags: product.tags,
                      }}
                      imageHeight={100}
                      cardContentComponent={
                        <PlayerTokensComponent
                          tokensAmount={
                            product.reward.price.virtualCurrencyPrice
                          }
                        />
                      }
                      onClick={() =>
                        handleOpenDialog({
                          id: UIDialogsEnum.SIDEBAR_SHOP_PRODUCT,
                          object: product,
                        })
                      }
                    />
                  </Grid>
                )
              })}
            </Grid>
          ) : (
            <NoContentIconComponent
              title={t('noProducts')}
              message={t('noProductsMessage')}
              buttonText={t('goHome')}
              buttonAction={handleGoHome}
            />
          )}
          {paginationSidebarProducts?.pages > 1 && (
            <PaginationDenseComponent
              handleChangePage={handleChangePage}
              pagination={paginationSidebarProducts}
            />
          )}
        </>
      )}
    </>
  )
}
