import { makeStyles } from 'tss-react/mui'
import { SidebarColors } from '@/constants'
import { alpha } from '@mui/material'

interface Props {
  openSidebar: boolean
  contentBackground?: string
}

export const useGlobalStyles = makeStyles<Props>()((
  theme,
  { openSidebar, contentBackground },
) => {
  const background =
    contentBackground || SidebarColors(theme).contentBackgroundColor

  return {
    sidebarWrapper: {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      height: '100%',
      minHeight: 400,
      width: openSidebar ? 330 : 90,
      transition: 'width 0.3s ease',
      position: 'relative',
      overflow: 'hidden',
      paddingBottom: openSidebar ? 74 : 'auto',
      display: 'flex',
      flexDirection: 'column',
      background,
      border: `1px solid ${alpha(theme.palette.text.primary, 0.1)}`,

      [theme.breakpoints.down('sm')]: {
        borderTopLeftRadius: openSidebar ? 0 : 16,
        borderTopRightRadius: openSidebar ? 0 : 16,
        width: '100%',
        transition: 'height 0.3s ease',
        height: openSidebar ? '100%' : 80,
        minHeight: 80,
        paddingBottom: openSidebar ? 74 : 0,
      },
    },
  }
})
