import { createSlice } from '@reduxjs/toolkit'
import { CatalogModels } from '@/state/catalog'
import {
  getCategoriesNamesExtraReducer,
  getGamesNamesExtraReducer,
  getProvidersNamesExtraReducer,
} from './'

const initialState: CatalogModels.CatalogState = {
  categories: [],
  games: [],
  providers: [],
}

export const catalogSlice = createSlice({
  name: '@catalog',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getCategoriesNamesExtraReducer(builder)
    getProvidersNamesExtraReducer(builder)
    getGamesNamesExtraReducer(builder)
  },
})

export const CatalogActions = catalogSlice.actions
