import React from 'react'
import { Box, IconButton, SxProps, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'

type Props = {
  title?: string
  titleSxProps?: SxProps
  interaction?: React.ReactNode
  goBack?: () => void
}

export function HeaderNavigationComponent({
  interaction,
  title,
  titleSxProps,
  goBack,
}: Props) {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center" gap={0.5}>
        {!!goBack && (
          <IconButton
            sx={{ fontSize: 16, color: 'inherit' }}
            size="small"
            onClick={goBack}
          >
            <ArrowBack fontSize="inherit" />
          </IconButton>
        )}
        {!!title && (
          <Typography fontSize={16} fontWeight={700} sx={titleSxProps}>
            {title}
          </Typography>
        )}
      </Box>
      <Box display="flex" alignItems="center">
        {!!interaction && interaction}
      </Box>
    </Box>
  )
}
