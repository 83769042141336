import { useSelector } from 'react-redux'
import {
  Box,
  Breakpoint,
  Button,
  Dialog,
  SxProps,
  Typography,
} from '@mui/material'
import { Close } from '@mui/icons-material'

import { useGlobalUi } from '@/hooks'
import { UIDialogsEnum } from '@/enums'
import { RootState } from '@/state'

import { useDialogStyles } from './style'

type Props = {
  dialogId: UIDialogsEnum
  title?: string
  content: React.ReactNode
  maxWidth?: Breakpoint | number
  titleSxProps?: SxProps
  contentSxProps?: SxProps
  headerBorder?: boolean
  overflow?: string
  disableScrollLock?: boolean
}

export const DialogComponent = ({
  dialogId,
  title,
  content,
  maxWidth,
  titleSxProps = {
    justifyContent: 'center',
  },
  contentSxProps = {
    overflow: 'auto',
  },
  headerBorder = true,
  overflow = 'hidden',
  disableScrollLock = true,
}: Props) => {
  const { dialog, handleWaitCloseDialog } = useGlobalUi()

  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const { classes, cx } = useDialogStyles({ maxWidth, overflow, headerBorder })

  return (
    <Dialog
      fullWidth
      open={dialog.open && dialog.id === dialogId}
      onClose={handleWaitCloseDialog}
      className={classes.dialog}
      disableScrollLock={disableScrollLock}
    >
      <Box className={classes.dialogWrapper}>
        <Box
          className={cx({
            [classes.dialogHeader]: true,
            [classes.dialogHeaderBorder]: headerBorder,
          })}
        >
          {!!title && (
            <Typography
              fontStyle={client === 'JP' ? 'italic' : 'normal'}
              sx={titleSxProps}
            >
              {title}
            </Typography>
          )}
        </Box>
        <Button
          className={classes.buttonCloseDialog}
          onClick={handleWaitCloseDialog}
        >
          <Close />
        </Button>
        <Box className={classes.dialogContent} sx={contentSxProps}>
          {content}
        </Box>
      </Box>
    </Dialog>
  )
}
