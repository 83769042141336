import { makeStyles } from 'tss-react/mui'
import { typographyClasses } from '@mui/material'
import { getBorderColorByMode } from '@/constants'

export const useDialogProgressStyles = makeStyles()((theme) => {
  return {
    dialogMissionProgress: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 8,
      padding: '12px 16px',

      '& .missionProgressTitle': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: 15,
        marginBottom: 15,
        borderBottom: `1px solid ${getBorderColorByMode(theme, 0.15, 0.15)}`,

        [`& .${typographyClasses.body2}`]: {
          fontSize: 12,
          color: theme.palette.common.white,
          textTransform: 'uppercase',
          fontWeight: 600,
        },

        '& .remainingTime': {
          display: 'flex',
          justifyContent: 'right',
          alignItems: 'center',
          gap: 5,

          '& svg': {
            fontSize: 15,
            color: theme.palette.common.white,
          },
        },
      },
    },

    // Rewards
    dialogMissionRewards: {
      display: 'flex',
      gap: 10,
      alignItems: 'center',

      [`& .${typographyClasses.body1}`]: {
        fontSize: 12,
      },

      '& .bagRewards': {
        padding: 0,
        minWidth: 'auto',
        position: 'relative',
        cursor: 'pointer',
        width: 40,

        '& .iconBagRewards': {
          height: 40,
          '& svg': {
            width: 40,
            height: 40,
          },
        },

        '& .iconBagInfo': {
          position: 'absolute',
          top: 0,
          right: -5,

          '& svg': {
            width: 20,
            height: '100%',
          },
        },
      },
    },
  }
})
