import { createAsyncThunk } from '@reduxjs/toolkit'
import Container from 'typedi'
import { HttpStatusCode } from '@gamanza-engage/mf-enums'

import { Rest } from '@/utils/api.utils'
import { WidgetEndpoints } from '@/state/endpoints'
import { MissionHistoryActionTypes } from '../constants'

import { MissionHistoryActions, MissionHistoryModels } from '../'

const apiService: Rest = Container.get(Rest)

export const claimMissionRewardsThunk = createAsyncThunk(
  MissionHistoryActionTypes.CLAIM_MISSION_REWARDS,
  async (
    payload: MissionHistoryModels.ClaimMissionRewardsPayload,
    thunkAPI,
  ) => {
    try {
      const { bundleStateId, missionStateId } = payload

      const queryParams = `?metadata.bundleStateId=${bundleStateId}&metadata.missionStateId=${missionStateId}`

      const response: MissionHistoryModels.ClaimMissionRewardsResponse =
        await apiService.sendData({
          method: 'PATCH',
          endpoint: `${WidgetEndpoints.CLAIM_MULTIPLE_REWARDS}${queryParams}`,
        })

      if (response.statusCode === HttpStatusCode.OK) {
        thunkAPI.dispatch(
          MissionHistoryActions.updateBundleClaimMissionReward(payload),
        )
      }

      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
