import { MutableRefObject } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Box, Slide, Typography, useTheme } from '@mui/material'
import {
  ChestTypeEnum,
  NotificationTypeEnum,
  VariantsComponentsEnum,
} from '@/enums'
import { GeneralModels, RootState } from '@/state'

import { useGlobalUi, usePlayerData } from '@/hooks'
import { ClaimChestItemComponent } from '@/components/common'
import { ModalCountersComponent, ModalRewardComponent } from '../'

import { useStyles } from '../styles'

type Props = {
  containerRef: MutableRefObject<any>
  isVisibleRewards: boolean
  level: number
  tokens: number
  rankName: string
  xpBalance: number
}

export const ModalPointsComponent = ({
  containerRef,
  isVisibleRewards,
  level,
  rankName,
  tokens,
  xpBalance,
}: Props) => {
  const { t } = useTranslation()

  const { modal } = useGlobalUi()

  const { rewardClaimEnable } = useSelector(
    ({ globalSettings }: RootState) => globalSettings,
  )

  const { gamificationPlayer, earnedRewards } = useSelector(
    ({ player }: RootState) => player,
  )

  const { playerRankName } = usePlayerData()

  const { classes } = useStyles({ snackbarType: modal.type })

  const theme = useTheme()

  return (
    <>
      <Box mt={2}>
        <Typography
          className={classes.congratulationsSubtitle}
          textAlign="center"
        >
          {t(
            earnedRewards?.length > 0
              ? 'congratulationsRewards'
              : 'congratulationsWithoutRewards',
            {
              type:
                modal.type === NotificationTypeEnum.RANK_UP
                  ? playerRankName
                  : `${t('level')} ${gamificationPlayer.level.levelNumber}`,
            },
          )}
        </Typography>
      </Box>
      <ModalCountersComponent
        level={level}
        rankName={rankName}
        tokens={tokens}
        xpBalance={xpBalance}
      />
      {earnedRewards?.length > 0 && (
        <Box className={classes.rewards}>
          <Slide
            direction="up"
            in={isVisibleRewards}
            container={containerRef.current}
            timeout={2000}
          >
            <Box width="100%">
              {rewardClaimEnable ? (
                <ClaimChestItemComponent
                  rewards={earnedRewards}
                  variant={VariantsComponentsEnum.LARGE}
                  chestType={
                    modal.type === NotificationTypeEnum.LEVEL_UP
                      ? ChestTypeEnum.LEVEL
                      : ChestTypeEnum.RANK
                  }
                  title={
                    modal.type === NotificationTypeEnum.LEVEL_UP
                      ? t('levelReward')
                      : t('rankReward')
                  }
                  buttonColor={theme.palette.primary.main}
                  rankId={gamificationPlayer.rank.id}
                  levelId={gamificationPlayer.level.id}
                />
              ) : (
                <Box className={classes.rewardList}>
                  {earnedRewards.map(
                    (reward: GeneralModels.RewardCategoryType) => (
                      <ModalRewardComponent reward={reward} key={reward.id} />
                    ),
                  )}
                </Box>
              )}
            </Box>
          </Slide>
        </Box>
      )}
    </>
  )
}
