import { useTranslation } from 'react-i18next'
import { Box, Button, Typography, useTheme } from '@mui/material'
import { PlayArrowOutlined } from '@mui/icons-material'

import {
  SkeletonLoader,
  CardDenseItemComponent,
  NoContentSidebarComponent,
  PaginationDenseComponent,
} from '@/components/common'
import { useMyBoosters } from '@/components/my-boosters'
import { JPDefaultBoosterImage } from '@/constants'

import { useBreakpoints } from '@/hooks'

export function MyBoostersDenseComponent() {
  const { t } = useTranslation()

  const {
    myBoosterFiltered,
    pagination,
    isLoading,
    client,
    gamificationOpt,
    handleChangePage,
    handleOpenConfirmBooster,
  } = useMyBoosters({ dense: true })

  const theme = useTheme()

  const { isXsMobile } = useBreakpoints()

  return (
    <Box>
      <Box
        height={366}
        overflow="auto"
        my={1}
        sx={{ opacity: gamificationOpt?.enable ? 1 : 0.5 }}
      >
        {isLoading && <SkeletonLoader element="table" rows={6} />}
        {!isLoading && !myBoosterFiltered.length && (
          <NoContentSidebarComponent
            message={t('noBoostersAvailable')}
            minHeight="100%"
          />
        )}
        {!isLoading && !!myBoosterFiltered.length && (
          <Box display="flex" flexDirection="column" gap={0.5}>
            {myBoosterFiltered.map((item) => (
              <CardDenseItemComponent
                key={item.id}
                item={
                  client === 'JP'
                    ? {
                        ...item,
                        image: item.image || JPDefaultBoosterImage,
                      }
                    : item
                }
                cardDescriptionComponent={
                  <Box
                    display="flex"
                    gap={0.5}
                    color={theme.palette.text.primary}
                  >
                    <Typography fontWeight={500} fontSize={10}>
                      {`${t('duration')}:`}
                    </Typography>
                    <Typography
                      fontWeight={300}
                      fontSize={10}
                      textTransform="lowercase"
                    >
                      {item.description}
                    </Typography>
                  </Box>
                }
                cardActionsComponent={
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    disabled={!gamificationOpt?.enable || false}
                    onClick={() => handleOpenConfirmBooster(item.id)}
                    sx={{
                      borderRadius: 1,
                      gap: 0.5,
                      minWidth: 'auto',
                      color: theme.palette.common.white,
                    }}
                    size="medium"
                  >
                    {!isXsMobile && (
                      <Typography
                        variant="caption"
                        textTransform="uppercase"
                        lineHeight="1px"
                      >
                        {t('activate')}
                      </Typography>
                    )}
                    <PlayArrowOutlined sx={{ fontSize: 20 }} />
                  </Button>
                }
              />
            ))}
          </Box>
        )}
      </Box>

      {pagination.pages > 1 && (
        <PaginationDenseComponent
          handleChangePage={handleChangePage}
          pagination={pagination}
        />
      )}
    </Box>
  )
}
