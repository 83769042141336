import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { RewardShopTypeEnum } from '@gamanza-engage/mf-enums'
import { Box } from '@mui/material'
import { useStyles } from '@/components/reward-shop/components/product/style'

import { RootState } from '@/state'
import { RewardShopViewTypeEnum, UIDialogsEnum } from '@/enums'
import { useGlobalUi } from '@/hooks'

import { getPIDWindowHash } from '@/utils'
import {
  CategoryComponent,
  ProductDetailsComponent,
  ProductCategoriesComponent,
  useRewardShop,
  useProductForm,
  ProductSummaryComponent,
  OrderResponseComponent,
  useRewardShopActions,
} from './'
import { useShopLayout } from '../shop-layout'

import { DialogColumnsComponent } from '../common'

export function RewardShopComponent() {
  const { t } = useTranslation()

  const { rewardShopPage, handleOpenProductDetail } = useRewardShop()

  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const { currentProduct } = useSelector(
    ({ rewardShop }: RootState) => rewardShop,
  )

  const {
    productForm,
    editLocation,
    getDefaultActionProps,
    handleEditLocation,
    handleOnChange,
    handleOnChangeMultiple,
    handleOnSubmit,
    getDisableMessage,
    getActionButtons,
    resetProductForm,
  } = useProductForm({
    product: currentProduct,
  })

  const { resetOrderProduct } = useRewardShopActions()

  const { classes: classesForm } = useStyles({ client })

  const [formStep, setFormStep] = useState(1)

  const { handleOpenDialog } = useGlobalUi()

  const { orderProduct } = useShopLayout()

  const actionProps = getDefaultActionProps(formStep, setFormStep)

  const getDialogContent = () => {
    if (orderProduct)
      return <OrderResponseComponent handleClose={() => setFormStep(1)} />

    if (
      formStep === 2 &&
      currentProduct?.reward?.type !== RewardShopTypeEnum.EXTERNAL
    )
      return

    const actionButtons = getActionButtons(actionProps)

    return (
      <form className={classesForm.form}>
        <ProductDetailsComponent
          product={currentProduct}
          formStep={formStep}
          productForm={productForm}
          editLocation={editLocation}
          handleEditLocation={handleEditLocation}
          handleOnChange={handleOnChange}
          handleOnChangeMultiple={handleOnChangeMultiple}
          getDisableMessage={getDisableMessage}
          actionButtons={actionButtons}
        />
      </form>
    )
  }

  const getDialogSummary = () => {
    if (orderProduct) return

    const actionButtons = getActionButtons(actionProps)

    return (
      formStep === 2 && (
        <form className={classesForm.form}>
          <ProductSummaryComponent
            product={currentProduct}
            productForm={productForm}
            actionButtons={actionButtons}
            handleOnChange={handleOnChange}
            getDisableMessage={getDisableMessage}
          />
        </form>
      )
    )
  }

  const getModalResolution = () => {
    return (formStep === 2 &&
      currentProduct?.reward?.type !== RewardShopTypeEnum.EXTERNAL) ||
      orderProduct
      ? 500
      : 900
  }

  const getProductLayout = () => {
    if (rewardShopPage === RewardShopViewTypeEnum.PRODUCTS) {
      return <ProductCategoriesComponent />
    }

    if (rewardShopPage === RewardShopViewTypeEnum.CATEGORY) {
      return <CategoryComponent />
    }

    return (
      <ProductDetailsComponent
        product={currentProduct}
        formStep={formStep}
        productForm={productForm}
        isRewardShop
        editLocation={editLocation}
        handleEditLocation={handleEditLocation}
        actionButtons={getActionButtons({
          ...actionProps,
          formStep,
          handleClick: () => {
            if (formStep === 1 && client !== 'JP') {
              setFormStep(2)
              handleOpenDialog({
                id: UIDialogsEnum.REWARD_SHOP_PRODUCT,
                object: currentProduct,
              })
              return
            }
            handleOnSubmit()
          },
        })}
        handleOnChange={handleOnChange}
        handleOnChangeMultiple={handleOnChangeMultiple}
        getDisableMessage={getDisableMessage}
      />
    )
  }

  useEffect(() => {
    const windowHash = getPIDWindowHash()
    if (windowHash !== '') {
      handleOpenProductDetail(windowHash)
    }
  }, []) // eslint-disable-line

  return (
    <>
      <Box py={2}>{getProductLayout()}</Box>

      {formStep !== 1 && client !== 'JP' && (
        <DialogColumnsComponent
          dialogId={UIDialogsEnum.REWARD_SHOP_PRODUCT}
          title={!orderProduct && t('shippingInformation')}
          maxWidth={getModalResolution()}
          content={getDialogContent()}
          summary={getDialogSummary()}
          handleClose={() => {
            setFormStep(1)
            resetOrderProduct()
            resetProductForm()
          }}
        />
      )}
    </>
  )
}
