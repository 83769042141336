import Lottie from 'lottie-react'
import { Box, SxProps, Typography, colors, useTheme } from '@mui/material'
import { IconComponent } from '@/components/common'

import { getFormatNumber } from '@/utils'
import { LottieAnimationData } from '@/state/models'
import { typographyH6PointsProps } from '@/constants'

import { IconTypeEnum } from '@/enums'
import { useTokensLottieAnimations } from '../../hooks'
import {
  TokensProfileBigAnimationsComponent,
  TokensProfileSmallAnimationsComponent,
} from './'

type Props = {
  textColor: string
  animationData: LottieAnimationData
  bigWinAnimationData?: LottieAnimationData
  name?: string
  flexDirection?: 'row' | 'column'
  smallWinSxProps?: SxProps
  bigWinSxProps?: SxProps
  valueSxProps?: SxProps
  iconSize?: number
}

export function TokensLottieAnimationComponent({
  textColor,
  animationData,
  bigWinAnimationData,
  name,
  flexDirection,
  smallWinSxProps,
  bigWinSxProps,
  valueSxProps,
  iconSize,
}: Props) {
  const {
    bigBoxRef,
    bigCircleOneRef,
    bigCircleTwoRef,
    boxRef,
    circleRef,
    numberRef,
    opacityCircleRef,
    playerLocale,
    smallLottieRef,
    oldTokens,
    bigLottieRef,
    showAnimation,
    bigWin,
  } = useTokensLottieAnimations()

  const theme = useTheme()

  return (
    <>
      <Box
        ref={boxRef}
        position="absolute"
        top={-4}
        left={-25}
        width="calc(100% + 35px)"
        height="calc(100% + 8px)"
        borderRadius={10}
        bgcolor={colors.amber[500]}
        sx={{ opacity: 0 }}
        display={showAnimation && flexDirection === 'row' ? 'inline' : 'none'}
        zIndex={10}
      />

      <TokensProfileBigAnimationsComponent
        bigBoxRef={bigBoxRef}
        bigCircleOneRef={bigCircleOneRef}
        bigCircleTwoRef={bigCircleTwoRef}
        opacityCircleRef={opacityCircleRef}
        display={bigWin ? 'inline' : 'none'}
      />

      {flexDirection === 'row' && (
        <TokensProfileSmallAnimationsComponent
          circleRef={circleRef}
          display={!bigWin ? 'inline' : 'none'}
        />
      )}

      <Box
        position="relative"
        display="flex"
        zIndex={20}
        justifyContent="center"
        marginBottom={flexDirection === 'column' ? 0.25 : 0}
      >
        <Box
          display={bigWin && showAnimation ? 'inline' : 'none'}
          sx={
            bigWinSxProps || {
              width: 62,
              position: 'absolute',
              left: -21,
              top: -15,
            }
          }
        >
          <Lottie
            animationData={bigWinAnimationData}
            autoplay={false}
            loop={false}
            lottieRef={bigLottieRef}
          />
        </Box>

        <Box
          display={!bigWin && showAnimation ? 'inline' : 'none'}
          sx={
            smallWinSxProps || {
              width: 36,
              position: 'absolute',
              top: -10,
            }
          }
        >
          <Lottie
            animationData={animationData}
            autoplay={false}
            loop={false}
            lottieRef={smallLottieRef}
          />
        </Box>

        <IconComponent type={IconTypeEnum.TOKEN} size={iconSize | 19} />
      </Box>
      {!!name && (
        <Typography
          variant="h6"
          sx={{
            ...typographyH6PointsProps(theme),
            marginTop: flexDirection === 'column' ? 0.25 : 0,
          }}
        >
          {name}
        </Typography>
      )}
      <Typography
        variant="caption"
        component="p"
        sx={
          valueSxProps || {
            fontWeight: 900,
            fontSize: 14,
            color: textColor,
            lineHeight: 1,
          }
        }
        ref={numberRef}
        zIndex={flexDirection === 'column' ? 0 : 20}
      >
        {getFormatNumber(oldTokens, playerLocale)}
      </Typography>
    </>
  )
}
