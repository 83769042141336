import { makeStyles } from 'tss-react/mui'
import { typographyClasses } from '@mui/material'
import { getBorderColorByMode } from '@/constants'

export const useDialogObjectivesStyles = makeStyles()((theme) => {
  return {
    dialogObjetivesWrapper: {
      marginTop: 10,
    },

    dialogObjectivesContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: 13,
      maxHeight: 260,
      height: '100%',
      overflow: 'auto',
      marginTop: 15,
      paddingBottom: 10,

      [`& .${typographyClasses.body1}`]: {
        fontWeight: 900,
        fontSize: 14,
        color: theme.palette.text.primary,
        textTransform: 'uppercase',
      },
    },

    dialogObjetive: {
      backgroundColor: theme.palette.background.default,
      borderRadius: 8,
      padding: '8px 16px',
      display: 'flex',
      gap: 20,
      border: `1px solid ${getBorderColorByMode(theme)}`,
      alignItems: 'center',

      '& .objectiveDescription': {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      },

      [`& .${typographyClasses.body2}`]: {
        fontSize: 12,
        fontWeight: 700,
        color: theme.palette.text.primary,
        paddingBottom: 5,
      },
    },
  }
})
