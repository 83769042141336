import { makeStyles } from 'tss-react/mui'
import { Breakpoint, dialogClasses, typographyClasses } from '@mui/material'
import { getBorderColorByMode } from '@/constants'

type Props = {
  overflow: string
  headerBorder: boolean
  maxWidth?: Breakpoint | number
}

export const useDialogStyles = makeStyles<Props>()((
  theme,
  { maxWidth, headerBorder, overflow },
) => {
  const maxWidthValue = () => {
    if (maxWidth && typeof maxWidth === 'number') {
      return maxWidth
    }

    if (maxWidth) {
      return 'auto'
    } else {
      return 370
    }
  }

  return {
    dialog: {
      [`& .${dialogClasses.paper}`]: {
        borderRadius: 13,
        overflow,
        backgroundColor: theme.palette.background.paper,
        maxWidth: maxWidthValue(),
      },
    },
    dialogWrapper: {
      height: '100%',
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      position: 'relative',
    },
    dialogWrapperColumn: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      gap: 2,
      position: 'relative',
      overflow: 'auto',

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    dialogColumn: {
      padding: 20,
      flex: 1,
      height: '100%',
    },
    dialogHeader: {
      position: 'relative',

      [`& .${typographyClasses.body1}`]: {
        width: '100%',
        fontSize: 18,
        fontWeight: 700,
        paddingBottom: 15,
      },
    },

    buttonCloseDialog: {
      position: 'absolute',
      top: 18,
      right: 0,
      padding: 4,
      color: theme.palette.text.primary,

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },

    dialogHeaderBorder: {
      borderBottom: `1px solid ${getBorderColorByMode(theme, 0.1)}`,
    },

    dialogContent: {
      width: '100%',
      maxHeight: '100%',
      margin: headerBorder ? '20px auto 0' : '0px auto',
    },
  }
})
