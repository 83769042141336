import { ReactComponent as IconGoing } from '@/assets/icons/missions/icon-mission-going.svg'
import { MissionStatusEnum, VariantsComponentsEnum } from '@/enums'

export const MissionItem = {
  [MissionStatusEnum.COMPLETED]: {
    id: MissionStatusEnum.COMPLETED,
    label: 'missionStatusType.completed',
    icon: 'assets/icons/missions/icon-mission-completed.svg',
    extraIcon: 'assets/icons/common/icon-check-green.svg',
  },
  [MissionStatusEnum.ACTIVE]: {
    id: MissionStatusEnum.ACTIVE,
    label: 'missionStatusType.going',
    icon: <IconGoing />,
  },
  [MissionStatusEnum.PENDING]: {
    id: MissionStatusEnum.PENDING,
    label: 'missionStatusType.locked',
    icon: 'assets/icons/missions/icon-mission-locked.svg',
    extraIcon: 'assets/icons/common/icon-lock.svg',
  },
  [MissionStatusEnum.ENDED]: {
    id: MissionStatusEnum.ENDED,
    label: 'missionStatusType.incomplete',
    icon: <IconGoing />,
    extraIcon: 'assets/icons/common/icon-lock.svg',
  },
}

export const MissionItemSize = {
  [VariantsComponentsEnum.LARGE]: {
    size: 82,
    circularSize: 76,
  },
  [VariantsComponentsEnum.SMALL]: {
    size: 56,
    circularSize: 50,
  },
}
