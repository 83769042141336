import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'

import { MissionItem } from '@/constants'
import {
  MissionStatusEnum,
  MissionTimeTypeEnum,
  OrientationComponentsEnum,
  VariantsComponentsEnum,
} from '@/enums'
import { MissionCommonModels, RootState } from '@/state'

import { useMissionItemStyles } from './style'
import { MissionItemInProgressComponent } from './mission-item-in-progress.component'

type Props = {
  status: MissionStatusEnum
  endDate?: string
  timeType?: MissionTimeTypeEnum
  state?: MissionCommonModels.MissionProgressType
  expanded?: boolean
  orientation?: OrientationComponentsEnum
  variant?: VariantsComponentsEnum
  showExtraIcon?: boolean
  showArrow?: boolean
  handleClick?: () => void
}

export const MissionItemComponent = ({
  status,
  endDate,
  timeType,
  state,
  expanded = true,
  orientation = OrientationComponentsEnum.VERTICAL,
  variant = VariantsComponentsEnum.LARGE,
  showExtraIcon = false,
  showArrow = false,
  handleClick,
}: Props) => {
  const { t } = useTranslation()

  const { client, baseUrl } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const { classes, cx } = useMissionItemStyles({
    sidebarClient: client,
    orientation,
    variant,
    status: status as MissionStatusEnum,
  })

  return (
    <>
      <Box
        className={`${classes.missionContent} ${cx({
          [classes.missionContentVertical]:
            orientation === OrientationComponentsEnum.VERTICAL,
          missionContentVertical:
            orientation === OrientationComponentsEnum.VERTICAL,
          [classes.missionContentHorizontal]:
            orientation === OrientationComponentsEnum.HORIZONTAL,
          missionContentHorizontal:
            orientation === OrientationComponentsEnum.HORIZONTAL,
        })}`}
        onClick={() => handleClick && handleClick()}
      >
        {status === MissionStatusEnum.ACTIVE ||
        status === MissionStatusEnum.ENDED ? (
          <MissionItemInProgressComponent
            status={status}
            endDate={endDate}
            timeType={timeType}
            state={state}
            expanded={expanded}
            orientation={orientation}
            variant={variant}
            showArrow={showArrow}
            showExtraIcon={showExtraIcon}
            baseUrl={baseUrl}
          />
        ) : (
          <>
            <Box className="missionContentIcon">
              <Box className="icon">
                <img
                  src={`${baseUrl}${MissionItem[status].icon}`}
                  alt={MissionItem[status].label}
                />
              </Box>
            </Box>
            {expanded && (
              <Box className="missionTexts">
                <Typography
                  className={`missionStatus ${variant.toLowerCase()}`}
                >
                  {t(`${MissionItem[status].label}`)}
                </Typography>
              </Box>
            )}
            {showExtraIcon && (
              <Box className="missionExtraIcon">
                <img
                  src={`${baseUrl}${MissionItem[status].extraIcon}`}
                  alt={`extra icon ${MissionItem[status].label}`}
                />
              </Box>
            )}
            {showArrow && <Box className="missionArrow" />}
          </>
        )}
      </Box>
    </>
  )
}
