import { useSelector } from 'react-redux'
import { Box, Typography, useTheme } from '@mui/material'
import { RootState } from '@/state'

import { IconComponent } from '@/components/common'
import { IconTypeEnum, WidgetTypeEnum } from '@/enums'
import { useAnimationBadgeActions } from '../../hooks/use-badge-animation.hook'

import { useStyles } from './style'

type Props = {
  Div: HTMLElement
  removeAnimation?: boolean
  dataSize?: number
}

export function BadgeLevelComponent({
  Div,
  removeAnimation = false,
  dataSize,
}: Props) {
  const { gamificationPlayer } = useSelector(({ player }: RootState) => player)

  const theme = useTheme()

  const getDataSize = () => {
    const type = Div.getAttribute('data-type')
    const size = Div.getAttribute('data-size')

    if (!!size) {
      if (type !== WidgetTypeEnum.BADGE_LEVEL && !isNaN(Number(size))) {
        return Number(size)
      }
      return size === 'small' ? 50 : 110
    }
    return dataSize || 110
  }

  const { numberAnimationRef, wrapperAnimationRef, iconLeftRef, iconRightRef } =
    useAnimationBadgeActions()

  const largeSize = getDataSize() > 70

  const iconSize = largeSize ? 21 : 15

  const boxSize = largeSize ? 60 : 48

  const { classes } = useStyles({
    iconSize,
    boxSize,
  })

  return (
    <Box position="relative" width={boxSize} height={boxSize / 2}>
      <Box
        width="100%"
        height="100%"
        bgcolor={theme.palette.primary.main}
        borderRadius={4}
        ref={!removeAnimation ? wrapperAnimationRef : undefined}
        position="absolute"
        top={0}
        bottom={0}
        right={0}
        left="50%"
        sx={{ transform: 'translateX(-50%)' }}
        zIndex={1}
      />
      <Box
        className={classes.levelContent}
        ref={!removeAnimation ? numberAnimationRef : undefined}
      >
        <Box position="relative" className={classes.iconWrap}>
          <Box
            className={classes.icon}
            ref={!removeAnimation ? iconLeftRef : undefined}
          >
            <IconComponent type={IconTypeEnum.LEVEL} size={iconSize} />
          </Box>
          <Box className={classes.icon}>
            <IconComponent type={IconTypeEnum.LEVEL} size={iconSize} />
          </Box>
          <Box
            className={classes.icon}
            ref={!removeAnimation ? iconRightRef : undefined}
          >
            <IconComponent type={IconTypeEnum.LEVEL} size={iconSize} />
          </Box>
        </Box>

        <Box width={iconSize} height={iconSize} position="relative">
          <Typography
            variant="body2"
            fontWeight={700}
            fontSize={largeSize ? 15 : 12}
            color={
              theme.palette.mode === 'dark'
                ? theme.palette.text.primary
                : theme.palette.primary.main
            }
          >
            {gamificationPlayer.level.levelNumber}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
