import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Button, ButtonProps, Typography } from '@mui/material'

import { ContentIconComponent, useRewardShop } from '@/components'
import { RootState } from '@/state'

type Props = {
  handleClose?: () => void
}

export const OrderResponseComponent = ({ handleClose }: Props) => {
  const { t } = useTranslation()

  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const {
    orderProduct,
    gamificationPlayer,
    handleContinueShopping,
    handleTryAgain,
  } = useRewardShop()

  const buttonProps: ButtonProps = {
    color: 'primary',
    variant: 'contained',
    sx: { width: '100%', padding: '6px 0' },
  }

  return (
    <ContentIconComponent
      iconType={orderProduct.message ? 'error' : 'success'}
      title={orderProduct.message ? t('error') : t('success')}
      message={
        orderProduct.message ? orderProduct.message : t('paymentMessageSuccess')
      }
      buttonContent={
        orderProduct.message ? (
          <Button {...buttonProps} onClick={handleTryAgain}>
            {t('tryAgain')}
          </Button>
        ) : (
          <>
            <Button
              {...buttonProps}
              onClick={() => {
                handleContinueShopping()
                handleClose && handleClose()
              }}
            >
              {t('continueShopping')}
            </Button>

            {client === 'JP' && (
              <Box
                sx={{
                  textAlign: 'center',
                  color: 'primary.main',
                  marginTop: 6,
                }}
              >
                <Typography variant="body2">{t('remainingTokens')}</Typography>
                <Typography fontWeight={700} sx={{ paddingTop: 1 }}>
                  {gamificationPlayer.tokens}
                </Typography>
              </Box>
            )}
          </>
        )
      }
    />
  )
}
