import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { MissionCommonModels, RootState } from '@/state'

import { MissionTabsEnum } from '@/enums'
import { useMissionsActions } from './use-missions-actions.hook'

export const useMissionsStandard = () => {
  const contentRef = useRef(null)

  const {
    missionActiveBundles,
    missionExpiredBundles,
    isLoading,
    reloadBundles,
  } = useSelector(({ missionHistory }: RootState) => missionHistory)

  const { playerLocale } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const { getActiveBundles, getExpiredBundles } = useMissionsActions()

  const [missionTab, setMissionTab] = useState(MissionTabsEnum.MISSIONS)

  const [missionBundles, setMissionBundles] = useState([])

  const [selectedBundle, setSelectedBundle] =
    useState<MissionCommonModels.MissionHistoryBundleDetailsType>(null)

  const handleSetSelectedBundle = (
    bundle: MissionCommonModels.MissionHistoryBundleDetailsType,
  ) => {
    setSelectedBundle(bundle)
  }

  const refreshSelectedBundle = (
    bundles: MissionCommonModels.MissionHistoryBundleDetailsType[],
  ) => {
    if (selectedBundle) {
      const newSelectecBundle = bundles.find(
        (bundle) => bundle._id === selectedBundle._id,
      )
      setSelectedBundle(newSelectecBundle || selectedBundle)
    }
  }

  useEffect(() => {
    if (missionTab === MissionTabsEnum.MISSIONS) {
      if (missionActiveBundles?.length === 0 || missionActiveBundles === null) {
        getActiveBundles()
      } else {
        setMissionBundles(missionActiveBundles)
      }
    } else {
      if (
        missionExpiredBundles?.length === 0 ||
        missionExpiredBundles === null ||
        reloadBundles
      ) {
        getExpiredBundles()
      } else {
        setMissionBundles(missionExpiredBundles)
      }
    }
  }, [missionTab]) // eslint-disable-line

  useEffect(() => {
    if (
      missionTab === MissionTabsEnum.MISSIONS &&
      missionActiveBundles?.length > 0
    ) {
      setMissionBundles(missionActiveBundles || [])
      refreshSelectedBundle(missionActiveBundles)
    } else {
      if (
        missionTab === MissionTabsEnum.HISTORY &&
        missionExpiredBundles?.length > 0
      ) {
        setMissionBundles(missionExpiredBundles || [])
        refreshSelectedBundle(missionExpiredBundles)
      }
    }
  }, [missionActiveBundles, missionExpiredBundles]) // eslint-disable-line

  const selectedBundleName =
    selectedBundle?.externalDetails?.find(
      (item) => item.language.toUpperCase() === playerLocale.toUpperCase(),
    )?.name || selectedBundle?.internalDetails.name

  const selectedBundleDescription =
    selectedBundle?.externalDetails?.find(
      (item) => item.language.toUpperCase() === playerLocale.toUpperCase(),
    )?.description || ''

  const selectedBundleTermsAndConditions =
    selectedBundle?.externalDetails?.find(
      (item) => item.language.toUpperCase() === playerLocale.toUpperCase(),
    )?.termsConditions || ''

  return {
    contentRef,
    missionActiveBundles,
    missionExpiredBundles,
    missionBundles,
    selectedBundle,
    missionTab,
    isLoading,
    selectedBundleName,
    selectedBundleDescription,
    selectedBundleTermsAndConditions,
    handleSetSelectedBundle,
    setSelectedBundle,
    setMissionTab,
  }
}
