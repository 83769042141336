import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RewardShopTypeEnum } from '@gamanza-engage/mf-enums'

import { Box, Typography } from '@mui/material'
import { RewardShopModels, RootState } from '@/state'
import { ProductFormType } from '@/state/reward-shop/models'

import { LocationFormComponent } from './location-form.component'
import { useStyles } from '../style'

type Props = {
  productForm: ProductFormType
  product: RewardShopModels.ProductType
  handleOnChange: (field: string, newValue: string | number | boolean) => void
}

export function ProductFormAddressComponent({
  productForm,
  product,
  handleOnChange,
}: Props) {
  const { t } = useTranslation()

  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const { classes } = useStyles({ client })

  if (client !== 'JP') {
    return (
      <LocationFormComponent
        handleOnChange={handleOnChange}
        productForm={productForm}
      />
    )
  }

  return (
    <Box display="flex" flexDirection="column">
      {product?.reward?.type === RewardShopTypeEnum.EXTERNAL && (
        <Box className={classes.radioGroup}>
          <Typography
            variant="caption"
            className="grayCaption"
            sx={{
              marginTop: 2,
            }}
          >
            {t('selectDelivery')}
          </Typography>

          <LocationFormComponent
            handleOnChange={handleOnChange}
            productForm={productForm}
          />
        </Box>
      )}
    </Box>
  )
}
