import { createAsyncThunk } from '@reduxjs/toolkit'
import Container from 'typedi'
import { Rest } from '@/utils/api.utils'

import { WidgetEndpoints } from '@/state/endpoints'
import { PlayerActionTypes } from '../constants'
import { PlayerModels } from '../'

const apiService: Rest = Container.get(Rest)

export const claimPlayerRewardsThunk = createAsyncThunk(
  PlayerActionTypes.CLAIM_PLAYER_REWARDS,
  async (payload: PlayerModels.ClaimPlayerRewardsPayload, thunkAPI) => {
    try {
      const { levelId, rankId, groupId } = payload

      const queryParams = groupId
        ? `?group.groupId=${groupId}`
        : `?metadata.rankId=${rankId}&metadata.levelId=${levelId}`

      const response: PlayerModels.ClaimPlayerRewardsResponse =
        await apiService.sendData({
          method: 'PATCH',
          endpoint: `${WidgetEndpoints.CLAIM_MULTIPLE_REWARDS}${queryParams}`,
        })

      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
