import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { MyBoosterThunks, MyBoosterModels } from '@/state'

export const activateBoostersExtraReducer = (
  builder: ActionReducerMapBuilder<MyBoosterModels.MyBoosterState>,
) => {
  builder.addCase(
    MyBoosterThunks.activateBoosterThunk.pending,
    (state, metadata) => {
      state.activatingBooster = metadata.meta.arg.id
    },
  )
  builder.addCase(MyBoosterThunks.activateBoosterThunk.fulfilled, (state) => {
    state.activatingBooster = ''
  })
  builder.addCase(MyBoosterThunks.activateBoosterThunk.rejected, (state) => {
    state.activatingBooster = ''
  })
}
