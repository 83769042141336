import { createAsyncThunk } from '@reduxjs/toolkit'
import Container from 'typedi'
import { Rest } from '@/utils/api.utils'

import {
  MyBoosterActions,
  MyBoosterModels,
  RootState,
  WidgetEndpoints,
} from '@/state'
import { MyBoosterActionTypes } from '../constants'

const apiService: Rest = Container.get(Rest)

export const getTokenActiveBoostersThunk = createAsyncThunk(
  MyBoosterActionTypes.GET_TOKEN_ACTIVE_BOOSTERS,
  async (_, thunkAPI) => {
    try {
      const { myBooster } = thunkAPI.getState() as RootState
      if (!myBooster.isLoadingTokenBoosters) {
        thunkAPI.dispatch(MyBoosterActions.setIsLoadingTokenBoosters(true))
        const response: MyBoosterModels.GetActiveBoostersResponse =
          await apiService.sendData({
            method: 'GET',
            endpoint: WidgetEndpoints.TOKEN_ACTIVE_BOOSTER,
          })

        return response
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
