import { makeStyles } from 'tss-react/mui'
import { SidebarMenuItemType } from '@/enums'
import { SidebarColors } from '@/constants'

type Props = {
  openSidebar: boolean
  headerBackground?: string
  sidebarMenuItem?: string
}

export const useHeaderStyles = makeStyles<Props>()((
  theme,
  { openSidebar, headerBackground, sidebarMenuItem },
) => {
  const background =
    headerBackground || SidebarColors(theme).headerBackgroundColor

  const getPadding = (isMobile: boolean) => {
    const closedPaddingBottomMobile = isMobile ? 40 : 50
    const closedPaddingBottom = openSidebar ? 88 : closedPaddingBottomMobile
    return {
      paddingTop: isMobile ? 10 : 16,
      paddingBottom:
        sidebarMenuItem === SidebarMenuItemType.HOME ? closedPaddingBottom : 40,
    }
  }

  return {
    sidebarHeader: {
      ...getPadding(false),
      paddingLeft: openSidebar ? 16 : 20,
      paddingRight: openSidebar ? 16 : 20,
      background,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',

      [theme.breakpoints.down('sm')]: {
        background: openSidebar ? background : theme.palette.background.default,
        borderTopLeftRadius: openSidebar ? 0 : 16,
        borderTopRightRadius: openSidebar ? 0 : 16,
        ...getPadding(true),
      },
    },
  }
})
