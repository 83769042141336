import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RewardShopTypeEnum } from '@gamanza-engage/mf-enums'

import { Box, Divider, lighten, Typography, useTheme } from '@mui/material'
import {
  CounterInputComponent,
  IconComponent,
  useProductImages,
} from '@/components/common'
import { RewardShopModels, RootState } from '@/state'

import { getFormatNumber } from '@/utils'
import { IconTypeEnum } from '@/enums'
import { useBreakpoints } from '@/hooks'

import { useStyles } from '../style'

type Props = {
  product: RewardShopModels.ProductType
  productForm: RewardShopModels.ProductFormType
  actionButtons: React.ReactNode
  handleOnChange: (field: string, newValue: string | number | boolean) => void
  getDisableMessage: () => React.ReactNode
}

export function ProductSummaryComponent({
  product,
  productForm,
  actionButtons,
  handleOnChange,
  getDisableMessage,
}: Props) {
  const { t } = useTranslation()

  const theme = useTheme()

  const { gamificationPlayer } = useSelector(({ player }: RootState) => player)

  const { playerLocale, client } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const { classes } = useStyles({ client })

  const { isTablet, isMobile } = useBreakpoints()

  const { extractFileName } = useProductImages({ product })

  const translation =
    product?.translations?.find(
      (item) => item.language.toUpperCase() === playerLocale.toUpperCase(),
    ) || product?.translations?.[0]

  const boxProps = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 1.5,
    bgcolor:
      theme.palette.mode === 'dark'
        ? lighten(theme.palette.background.default, 0.1)
        : theme.palette.background.default,
    borderRadius: 2,
    color: theme.palette.text.secondary,
  }

  const typographyProps = {
    color: theme.palette.text.secondary,
    fontSize: 12,
  }

  const getTotalSummary = () => {
    return (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="caption" color={theme.palette.text.secondary}>
          {`${t('remainingTokens')} ${getFormatNumber(
            gamificationPlayer.tokens -
              productForm.quantity *
                product?.reward?.price?.virtualCurrencyPrice,
            playerLocale,
          )}`}
        </Typography>

        <Box display="flex">
          <Box className={classes.tokensBox}>
            <Typography color={theme.palette.text.secondary} fontSize={18}>
              {t('total')}:
            </Typography>
            <IconComponent type={IconTypeEnum.TOKEN} />
            <Typography variant="body2">
              {getFormatNumber(
                productForm.quantity *
                  product?.reward?.price?.virtualCurrencyPrice,
                playerLocale,
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      display="flex"
      width="100%"
      flexDirection="column"
      gap={isTablet || isMobile ? 2 : 30}
    >
      <Box display="flex" flexDirection="column" gap={1}>
        <Box {...boxProps}>
          <Typography {...typographyProps} flexGrow={1}>
            {t('item')}
          </Typography>
          <Typography {...typographyProps} textAlign="center" width={80}>
            {t('quantity')}
          </Typography>
          <Typography {...typographyProps} textAlign="center" width={80}>
            {t('price')}
          </Typography>
        </Box>
        <Box {...boxProps}>
          <Box flexGrow={1} display="flex" gap={1} alignItems="center">
            {product?.images?.length > 0 && (
              <img
                src={product.images[0].smallImage}
                alt={extractFileName(product.images[0].largeImage)}
                style={{
                  width: 45,
                }}
              />
            )}
            <Typography {...typographyProps} fontSize={14} fontWeight={700}>
              {translation?.name || product?.name}
            </Typography>
          </Box>
          <Box width={80} display="flex" justifyContent="center">
            <CounterInputComponent
              value={productForm.quantity}
              fieldName="quantity"
              handleOnChange={handleOnChange}
              disableControls
            />
          </Box>

          <Typography
            {...typographyProps}
            textAlign="center"
            fontSize={14}
            width={80}
          >
            {getFormatNumber(
              product?.reward?.price?.virtualCurrencyPrice,
              playerLocale,
            )}
          </Typography>
        </Box>
      </Box>

      <Box width="100%">
        <Divider sx={{ marginBottom: 1 }} />

        {product?.reward?.type !== RewardShopTypeEnum.EXTERNAL ||
        isTablet ||
        isMobile ? (
          <>
            {getTotalSummary()}

            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gap={2}
              mt={2}
            >
              {actionButtons}
            </Box>

            {getDisableMessage()}
          </>
        ) : (
          getTotalSummary()
        )}
      </Box>
    </Box>
  )
}
