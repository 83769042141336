import { CardGiftcard, ShoppingBasket, History } from '@mui/icons-material'
import { MenuItemType } from '../enums'

export const MenuListOptions = {
  [MenuItemType.REWARD_SHOP]: {
    id: MenuItemType.REWARD_SHOP,
    label: 'rewardShop',
    icon: ShoppingBasket,
  },
  [MenuItemType.BOOSTERS]: {
    id: MenuItemType.BOOSTERS,
    label: 'myBoosters',
    icon: CardGiftcard,
  },
  [MenuItemType.HISTORY]: {
    id: MenuItemType.HISTORY,
    label: 'shopHistory',
    icon: History,
  },
}

export const WindowHashID = '#PID_'
