import PoppinsBold from './poppins/poppins-bold.ttf'
import PoppinsSemiBold from './poppins/poppins-semibold.ttf'
import PoppinsMedium from './poppins/poppins-medium.ttf'

import PoppinsRegular from './poppins/poppins-regular.ttf'

export const fontFallbacks = (assetsRoute: string) => {
  // TODO: In the future we need to validate the client and return the right font
  return [
    {
      fontFamily: 'Poppins',
      fontWeight: 400,
      fontStyle: 'normal',
      src: `url(${assetsRoute}/casino-ui-widgets${PoppinsRegular}) format('truetype')`,
    },
    {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontStyle: 'normal',
      src: `url(${assetsRoute}/casino-ui-widgets${PoppinsMedium}) format('truetype')`,
    },
    {
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontStyle: 'normal',
      src: `url(${assetsRoute}/casino-ui-widgets${PoppinsSemiBold}) format('truetype')`,
    },
    {
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontStyle: 'normal',
      src: `url(${assetsRoute}/casino-ui-widgets${PoppinsBold}) format('truetype')`,
    },
  ]
}
