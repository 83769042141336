import { useTranslation } from 'react-i18next'
import { TimeFrameEnum } from '@gamanza-engage/mf-enums'
import { Box, Chip, Typography } from '@mui/material'

import { MissionCommonModels } from '@/state'
import { MissionItem } from '@/constants'
import { formatDateInUTC } from '@/utils'

import { useMissionItemStyles } from './style'
import { MissionItemDenseComponent } from '../dense'

type Props = {
  mission: MissionCommonModels.MissionsByBundleType
  bundleSettings: MissionCommonModels.BundleSettingsType
  handleClick?: () => void
}

export const MissionItemHistoryComponent = ({
  mission,
  bundleSettings,
  handleClick,
}: Props) => {
  const { t } = useTranslation()

  const { classes } = useMissionItemStyles({
    status: mission.status,
  })

  const timeFrameType = bundleSettings.timeFrame.timeFrameType

  const isRangeOrWeekly =
    (timeFrameType === TimeFrameEnum.RANGE ||
      timeFrameType === TimeFrameEnum.WEEKLY) &&
    bundleSettings.timeFrame.startDate &&
    bundleSettings.timeFrame.endDate

  const isMonthlyOrDaily =
    (timeFrameType === TimeFrameEnum.MONTHLY ||
      timeFrameType === TimeFrameEnum.DAILY) &&
    bundleSettings.timeFrame.date

  const isLifetime = timeFrameType === TimeFrameEnum.LIFETIME && mission.endDate

  return (
    <Box className={classes.missionItemHistory} onClick={() => handleClick()}>
      <Box className="missionItemHistoryIcon">
        <MissionItemDenseComponent
          status={mission.status}
          endDate={mission.endDate}
          state={mission.state}
          timeType={mission.validityTimeState.timeType}
          handleClick={handleClick}
          expanded={false}
        />
      </Box>
      <Box className="missionItemHistoryText">
        <Typography variant="body2" fontWeight={600}>
          {t(`${MissionItem[mission.status].label}`)}
        </Typography>
        <Box className="missionChip">
          <Chip
            label={t(`timeframeType.${timeFrameType}`)}
            variant="filled"
            size="small"
          />
        </Box>
        {isRangeOrWeekly && (
          <Box display="flex">
            <Typography variant="body2" component="p" fontWeight={400}>
              {`${formatDateInUTC(bundleSettings.timeFrame.startDate, 'dd MMM')} - ${formatDateInUTC(
                bundleSettings.timeFrame.endDate,
                'dd MMM',
              )}`}
            </Typography>
          </Box>
        )}
        {isMonthlyOrDaily && (
          <Typography variant="body2" component="p" fontWeight={400}>
            {formatDateInUTC(
              bundleSettings.timeFrame.date,
              timeFrameType === TimeFrameEnum.DAILY ? 'dd MMM' : 'MMMM',
            )}
          </Typography>
        )}
        {isLifetime && (
          <Typography variant="body2" component="p" fontWeight={400}>
            {formatDateInUTC(mission.endDate, 'dd MMM')}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
