import { makeStyles } from 'tss-react/mui'
import { dialogClasses } from '@mui/material'

export const useDialogOnSiteStyles = makeStyles()((theme) => {
  return {
    dialog: {
      borderRadius: 20,
      [`& .${dialogClasses.paper}`]: {
        borderRadius: 16,
        maxWidth: 600,
        width: '100%',
        [theme.breakpoints.down(768)]: {
          maxWidth: 444,
        },
      },
    },
    onsiteDialogContainer: {
      position: 'relative',
      width: '100%',
    },
    htmlContent: {
      display: 'flex',
      justifyContent: 'center',
    },
    buttonClose: {
      position: 'absolute',
      right: 6,
      top: 6,
    },
  }
})
