import { Box } from '@mui/material'
import {
  MissionStatusEnum,
  MissionTimeTypeEnum,
  OrientationComponentsEnum,
  VariantsComponentsEnum,
} from '@/enums'
import { MissionCommonModels } from '@/state'

import { useMissionItemStyles } from './style'
import { MissionItemComponent } from '../common/mission-item.component'

type Props = {
  status: MissionStatusEnum
  endDate?: string
  timeType?: MissionTimeTypeEnum
  state?: MissionCommonModels.MissionProgressType
  expanded?: boolean
  handleClick?: () => void
}

export const MissionItemDenseComponent = ({
  status,
  endDate,
  timeType,
  state,
  expanded,
  handleClick,
}: Props) => {
  const { classes, cx } = useMissionItemStyles({
    expanded,
  })

  return (
    <Box
      className={`${classes.missionDenseWrapper} ${cx({
        [classes.missionItemFullDense]: !expanded,
        missionItemFullDense: !expanded,
        [classes.missionItemDense]: expanded,
      })}`}
      onClick={() => handleClick && handleClick()}
    >
      <MissionItemComponent
        status={status}
        endDate={endDate}
        timeType={timeType}
        state={state}
        expanded={expanded}
        handleClick={handleClick}
        orientation={OrientationComponentsEnum.VERTICAL}
        variant={VariantsComponentsEnum.SMALL}
      />
    </Box>
  )
}
