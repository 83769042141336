import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Lottie from 'lottie-react'

import { Box, IconButton, Slide, Typography, useTheme } from '@mui/material'
import { Close } from '@mui/icons-material'
import { NotificationTypeEnum } from '@/enums'

import { GeneralModels, RootState } from '@/state'
import { useGlobalUi, usePlayerData } from '@/hooks'
import { ModalMissionComponent, ModalPointsComponent } from '../'

import { useNotificationFinal } from '../../hooks'
import { useStyles } from '../styles'

type Props = {
  finalAnimationData: GeneralModels.LottieAnimationData
}

export const ModalStatusFinalComponent = ({ finalAnimationData }: Props) => {
  const { t } = useTranslation()

  const { modal, handleCloseModal } = useGlobalUi()

  const { gamificationPlayer } = useSelector(({ player }: RootState) => player)

  const { containerRef, isVisibleRewards, level, tokens, rankName, xpBalance } =
    useNotificationFinal()

  const { playerRankName } = usePlayerData()

  const { classes } = useStyles({ snackbarType: modal.type })

  const theme = useTheme()

  return (
    <>
      <Box className={classes.finalContainerTop}>
        <Slide
          direction="down"
          in={true}
          container={containerRef.current}
          timeout={750}
        >
          <Box className={classes.finalHeader}>
            <IconButton onClick={handleCloseModal}>
              <Close />
            </IconButton>
          </Box>
        </Slide>
        <Box className={classes.finalAnimation}>
          <Lottie animationData={finalAnimationData} loop />
          <Box>
            {modal.type === NotificationTypeEnum.LEVEL_UP && (
              <Typography
                className={`${classes.finalTitle} ${classes.finalLevelAnimation}`}
                sx={{
                  bottom: 100,
                  [theme.breakpoints.down('sm')]: {
                    bottom: 80,
                  },
                }}
              >
                {gamificationPlayer.level.levelNumber}
              </Typography>
            )}
            {modal.type === NotificationTypeEnum.RANK_UP && (
              <Typography
                className={classes.finalTitle}
                sx={{
                  bottom: 40,
                  [theme.breakpoints.down('sm')]: {
                    bottom: 30,
                  },
                }}
              >
                {playerRankName}
              </Typography>
            )}
            {modal.type === NotificationTypeEnum.MISSION && (
              <Typography
                className={classes.finalTitle}
                variant="body2"
                sx={{
                  bottom: 70,
                  [theme.breakpoints.down('sm')]: {
                    bottom: 40,
                  },
                }}
              >
                {t('missionCompleted')}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <Box className={classes.finalContainerBottom}>
        {modal?.type === NotificationTypeEnum.MISSION ? (
          <ModalMissionComponent
            containerRef={containerRef}
            isVisibleRewards={isVisibleRewards}
          />
        ) : (
          <ModalPointsComponent
            containerRef={containerRef}
            isVisibleRewards={isVisibleRewards}
            level={level}
            rankName={rankName}
            tokens={tokens}
            xpBalance={xpBalance}
          />
        )}
      </Box>
    </>
  )
}
