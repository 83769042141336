import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, useTheme } from '@mui/material'

import { RootState } from '@/state'
import { useGlobalUi } from '@/hooks'
import { IconComponent, PointCardComponent } from '@/components/common'

import { IconTypeEnum, NotificationTypeEnum } from '@/enums'
import { useStyles } from '../styles'
import { ModalCounterComponent } from './modal-counter.component'

type Props = {
  level: number
  tokens: number
  rankName: string
  xpBalance: number
}

export const ModalCountersComponent = ({
  level,
  tokens,
  rankName,
  xpBalance,
}: Props) => {
  const { t } = useTranslation()

  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const { classes } = useStyles({})

  const { modal } = useGlobalUi()

  const theme = useTheme()

  const iconSize = 40

  const titleProps = {
    paddingTop: 0.5,
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.text.secondary,
  }

  const valueProps = {
    fontWeight: 700,
    lineHeight: 1,
    color: theme.palette.text.primary,
    ...(client === 'JP'
      ? {
          fontStyle: 'italic',
          fontSize: 16,
        }
      : { fontSize: 14 }),
  }

  const isRankUp = modal.type === NotificationTypeEnum.RANK_UP

  const modalsList = [
    {
      value: xpBalance,
      name: t('xpPoints'),
      type: IconTypeEnum.XP,
      isVisible: true,
      appears: 1000,
    },
    {
      value: rankName,
      name: t('rank'),
      type: IconTypeEnum.RANK,
      isVisible: isRankUp,
      appears: 1500,
    },
    {
      value: level,
      name: t('level'),
      type: IconTypeEnum.LEVEL,
      isVisible: !isRankUp,
      appears: 1500,
    },
    {
      value: tokens,
      name: t('tokens'),
      type: IconTypeEnum.TOKEN,
      isVisible: true,
      appears: 2000,
    },
  ]

  return (
    <Box className={classes.counters}>
      {modalsList
        .filter((item) => item.isVisible)
        .map(({ type, name, value, appears }) => (
          <ModalCounterComponent
            children={
              <PointCardComponent
                icon={<IconComponent type={type} size={iconSize} />}
                name={name}
                titleProps={titleProps}
                value={value}
                valueProps={valueProps}
              />
            }
            appears={appears}
          />
        ))}
    </Box>
  )
}
