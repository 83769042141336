import { makeStyles } from 'tss-react/mui'
import { alpha } from '@mui/material'

interface Props {
  iconSize: number
  boxSize: number
}

export const useStyles = makeStyles<Props>()((theme, { iconSize, boxSize }) => {
  return {
    levelContent: {
      display: 'flex',
      gap: 4,
      alignItems: 'center',
      justifyContent: 'space-around',
      padding: '2px 4px',
      border: `1px solid ${alpha(theme.palette.text.primary, 0.1)}`,
      borderRadius: 8,
      backgroundColor: theme.palette.background.default,
      width: boxSize,
      height: boxSize / 2,
      '& img': {
        width: iconSize,
      },

      position: 'relative',
      zIndex: 2,
    },
    iconWrap: {
      width: iconSize,
      height: iconSize,
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      width: iconSize,
      height: iconSize,
    },
    levelNumber: {
      zIndex: 10,
      position: 'absolute',
      top: '53%',
      left: '49%',
      transform: 'translate(-53%, -49%)',
      color: theme.palette.primary.main,
    },
    levelImage: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  }
})
