import { Box, useTheme } from '@mui/material'
import { AvatarComponent } from '@/components/profile'
import { useBreakpoints } from '@/hooks'

import { SidebarColors } from '@/constants'

type Props = {
  Div: HTMLElement
  openSidebar: boolean
  handleOpenSidebar?: () => void
}

export function SidebarAvatarComponent({
  Div,
  openSidebar,
  handleOpenSidebar,
}: Props) {
  const { isMobile } = useBreakpoints()

  const theme = useTheme()

  Div.setAttribute('data-show-level', 'true')
  Div.setAttribute('data-size', '50')
  Div.setAttribute('data-progress-type', 'level')

  const progressColor =
    Div.getAttribute('data-progress-color') ||
    SidebarColors(theme).progressColor

  return (
    <Box onClick={() => handleOpenSidebar && handleOpenSidebar()}>
      <AvatarComponent
        Div={Div}
        removeAnimation={!isMobile}
        removeProgressBar={openSidebar}
        progressColor={isMobile ? theme.palette.primary.main : progressColor}
      />
    </Box>
  )
}
