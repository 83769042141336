import { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { NotificationTypeEnum } from '@/enums'

import { useGlobalUi } from '@/hooks'

export const useAnimationBadgeActions = () => {
  const { notificationSnackbar, modal } = useGlobalUi()

  const numberAnimationRef = useRef(null)
  const wrapperAnimationRef = useRef(null)
  const iconLeftRef = useRef(null)
  const iconRightRef = useRef(null)

  const playAnimation = () => {
    const tl = gsap.timeline()

    // background block changes width

    tl.to(wrapperAnimationRef.current, {
      width: '200%',
      duration: 0.5,
      ease: 'power4.out',
    })

    tl.to(wrapperAnimationRef.current, {
      width: '100%',
      duration: 0.5,
      ease: 'power4.out',
    })

    // icon goes left

    tl.fromTo(
      iconLeftRef.current,
      {
        scale: 1,
        opacity: 1,
        x: '-150%',
        duration: 0.5,
        ease: 'power4.out',
      },
      {
        x: 0,
        opacity: 0,
        duration: 0.5,
        ease: 'power4.out',
      },
      0.5,
    )

    // icon goes right

    tl.fromTo(
      iconRightRef.current,
      {
        scale: 1,
        opacity: 1,
        x: '280%',
        duration: 0.5,
        ease: 'power4.out',
      },
      {
        opacity: 0,
        x: 0,
        duration: 0.5,
        ease: 'power4.out',
      },
      0.5,
    )

    // white block goes up and down

    tl.to(
      numberAnimationRef.current,
      {
        y: -10,
        duration: 0.25,
        ease: 'power1.out',
      },
      0.7,
    )

    tl.to(numberAnimationRef.current, {
      y: 10,
      duration: 0.25,
      ease: 'power4.in',
    })

    tl.to(numberAnimationRef.current, {
      y: 0,
      duration: 0.25,
      ease: 'elastic.out',
    })

    // icons get scaled up and dissappear

    tl.fromTo(
      iconRightRef.current,
      {
        scale: 1,
        opacity: 1,
        ease: 'power4.out',
      },
      {
        scale: 5,
        opacity: 0,
        duration: 1.5,
        ease: 'power4.out',
      },
      1.3,
    )

    tl.fromTo(
      iconLeftRef.current,
      {
        scale: 1,
        opacity: 1,
        ease: 'power4.out',
      },
      {
        scale: 5,
        opacity: 0,
        duration: 1,
        ease: 'power4.out',
      },
      1.6,
    )
  }

  useEffect(() => {
    if (
      notificationSnackbar.open &&
      modal.type === NotificationTypeEnum.LEVEL_UP
    ) {
      playAnimation()
    }
  }, [notificationSnackbar, modal.type]) // eslint-disable-line

  return {
    numberAnimationRef,
    wrapperAnimationRef,
    iconLeftRef,
    iconRightRef,
  }
}
