import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Breakpoint,
  Button,
  darken,
  Dialog,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material'

import { Close } from '@mui/icons-material'
import { useGlobalUi } from '@/hooks'
import { UIDialogsEnum } from '@/enums'

import { RootState } from '@/state'
import { useDialogStyles } from './style'

type Props = {
  dialogId: UIDialogsEnum
  title: string
  content: React.ReactNode
  summary: React.ReactNode
  maxWidth?: Breakpoint | number
  titleSxProps?: SxProps
  headerBorder?: boolean
  overflow?: string
  handleClose: () => void
}

export const DialogColumnsComponent = ({
  dialogId,
  title,
  content,
  summary,
  maxWidth,
  titleSxProps = {
    justifyContent: 'center',
  },
  headerBorder = true,
  overflow = 'hidden',
  handleClose,
}: Props) => {
  const { t } = useTranslation()

  const { dialog, handleCloseDialog } = useGlobalUi()

  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const { classes, cx } = useDialogStyles({ maxWidth, overflow, headerBorder })

  const theme = useTheme()

  return (
    <Dialog
      fullWidth
      open={dialog.open && dialog.id === dialogId}
      onClose={() => {
        handleCloseDialog()
        handleClose()
      }}
      className={classes.dialog}
      disableScrollLock
    >
      <Box className={classes.dialogWrapperColumn}>
        {!!content && (
          <Box
            className={classes.dialogColumn}
            bgcolor={
              theme.palette.mode === 'dark'
                ? theme.palette.background.paper
                : theme.palette.background.default
            }
          >
            {!!title && (
              <Box
                className={cx({
                  [classes.dialogHeader]: true,
                  [classes.dialogHeaderBorder]: headerBorder,
                })}
              >
                <Typography
                  fontStyle={client === 'JP' ? 'italic' : 'normal'}
                  textTransform="initial"
                  sx={titleSxProps}
                >
                  {title}
                </Typography>
              </Box>
            )}
            <Box className={classes.dialogContent}>{content}</Box>
          </Box>
        )}

        {!!summary && (
          <Box
            className={classes.dialogColumn}
            bgcolor={
              theme.palette.mode === 'dark'
                ? theme.palette.background.default
                : darken(theme.palette.background.paper, 0.05)
            }
          >
            <Box
              className={cx({
                [classes.dialogHeader]: true,
                [classes.dialogHeaderBorder]: headerBorder,
              })}
            >
              <Typography
                fontStyle={client === 'JP' ? 'italic' : 'normal'}
                sx={titleSxProps}
              >
                {t('summary')}
              </Typography>
            </Box>
            <Box className={classes.dialogContent}>{summary}</Box>
          </Box>
        )}

        <Button
          className={classes.buttonCloseDialog}
          onClick={() => {
            handleCloseDialog()
            handleClose()
          }}
        >
          <Close />
        </Button>
      </Box>
    </Dialog>
  )
}
